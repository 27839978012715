import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
	Dialog,
	DialogType,
	DialogFooter,
	PrimaryButton,
	DefaultButton,
	mergeStyleSets,
	DetailsList,
	DetailsRow,
	DetailsRowFields,
	MarqueeSelection,
	SelectionMode,
	Selection,
	CheckboxVisibility
} from '@fluentui/react';

const cs = mergeStyleSets({
	container: {
		'& > *:not(:first-child)': {
			maxWidth: '80%',
			minWidth: '50%'
		},
		'& div[role="checkbox"]': {
			opacity: 1
		}
	},
	footer: {
		display: 'flex',
		width: '100%',
		alignSelf: 'flex-end',
		'& > *:not(:last-child)': {
			marginRight: '16px'
		}
	}
});

const dialogContentProps = {
	type: DialogType.normal,
	title: 'Available Rules',
	closeButtonAriaLabel: 'Close'
};

class RulesPickerModal extends Component {
	constructor(props) {
		super(props);

		this.onSubmit = this.onSubmit.bind(this);
		this.renderRowFields = this.renderRowFields.bind(this);
		this.renderRow = this.renderRow.bind(this);

		this.selection = new Selection({
			getKey: r => r && r.id
		});

		this.selection.setItems(props.rules);

		props.initialRules.forEach(s => {
			this.selection.setKeySelected(s.toString(), true, false);
		});
	}

	render() {
		const {open, onClose, rules, columns} = this.props;

		return (
			<Dialog
				hidden={!open}
				onDismiss={onClose}
				dialogContentProps={dialogContentProps}
				modalProps={{
					className: cs.container
				}}
			>
				<MarqueeSelection selection={this.selection}>
					<DetailsList
						items={rules}
						columns={columns}
						getKey={r => r && r.id}
						isHeaderVisible
						selection={this.selection}
						selectionMode={SelectionMode.multiple}
						checkboxVisibility={CheckboxVisibility.always}
						selectionPreservedOnEmptyClick
						onRenderRow={this.renderRow}
					/>
				</MarqueeSelection>
				<DialogFooter>
					<div className={cs.footer}>
						<PrimaryButton onClick={this.onSubmit} text="Submit rules" />
						<DefaultButton onClick={onClose} text="Cancel" />
					</div>
				</DialogFooter>
			</Dialog>
		);
	}

	renderRow(rowProps) {
		return (
			<DetailsRow {...rowProps} rowFieldsAs={this.renderRowFields}/>
		);
	}

	renderRowFields(fieldProps) {
		return (
			<span
				data-selection-disabled
				onClick={() => {
					this.selection.toggleKeySelected(fieldProps.item.id);
				}}
			>
				<DetailsRowFields {...fieldProps} />
			</span>
		);
	}

	onSubmit() {
		const {onSubmit, onClose, initialRules} = this.props;
		const ruleIds = this.selection.getSelection().map(r => r.id);
		const sortedruleIds = ruleIds.sort((a, b) => initialRules.indexOf(b) - initialRules.indexOf(a));

		onSubmit(sortedruleIds);
		onClose();
	}
}

RulesPickerModal.propTypes = {
	api: PropTypes.func,
	initialRules: PropTypes.array,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	rules: PropTypes.array,
	columns: PropTypes.array
};

export default RulesPickerModal;
