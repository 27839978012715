import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {
	PrimaryButton, DefaultButton, IconButton, FontIcon, SearchBox, Dropdown, ColumnActionsMode, DetailsList, DetailsListLayoutMode, SelectionMode, mergeStyleSets
} from '@fluentui/react';

const classNames = mergeStyleSets({
	fileIconHeaderIcon: {
		padding: 0,
		fontSize: '16px'
	},
	fileIconHeaderIconAlt: {
		padding: 0,
		fontSize: '16px',
		color: '#0078D4'
	},
	fileIconCell: {
		textAlign: 'center',
		selectors: {
			'&:before': {
				content: '.',
				display: 'inline-block',
				verticalAlign: 'middle',
				height: '100%',
				width: '0px',
				visibility: 'hidden'
			}
		}
	},
	fileIconImg: {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px'
	},
	controlWrapper: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	exampleToggle: {
		display: 'inline-block',
		marginBottom: '10px',
		marginRight: '30px'
	},
	selectionDetails: {
		marginBottom: '20px'
	},
	body: {
		flex: 3,
		maxWidth: '85%',
		padding: '2em 2em 3em',
		boxSizing: 'border-box',
		background: '#f3f2f1'
	},
	container: {
		padding: 32,
		background: '#fff',
		boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
		borderRadius: 2,
		maxWidth: 1102,
		boxSizing: 'border-box'
	},
	searchBox: {
		flex: '1 0 0'
	},
	buttonWrapper: {
		marginBottom: 32,
		display: 'flex',
		justifyContent: 'flex-end',
		maxWidth: 1102,
		boxSizing: 'border-box'
	},
	dropdown: {
		display: 'flex',
		marginLeft: 24,
		marginRight: 56
	},
	actionColumns: {
		display: 'flex',
		alignItems: 'center',
		padding: 0
	},
	title: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '28px',
		marginBottom: 24
	},
	text: {
		fontSize: 14
	},
	addButton: {
		marginLeft: '1em'
	}
});

const refreshIcon = {iconName: 'refresh'};

function AddButton() {
	const history = useHistory();

	function handleClick() {
		history.push('/archiving/rules/add');
	}

	return (
		<PrimaryButton
			className={classNames.addButton}
			iconProps={{iconName: 'CirclePlus'}}
			text="Add new rule"
			onClick={handleClick}
		/>
	);
}

function MoreButton({id, hasDependentJobs = false, deleteRow}) {
	const history = useHistory();
	const actions = [
		{key: 'edit', text: 'Edit', onClick: () => history.push(`/archiving/rules/${id}`), iconProps: {iconName: 'PageEdit'}},
		{
			key: 'delete', disabled: hasDependentJobs, text: 'Delete', onClick: () => deleteRow(id), iconProps: {iconName: 'Delete'}
		},
		{
			key: 'jobs', disabled: !hasDependentJobs, text: 'Dependent job list', onClick: () => history.push(`/archiving/rules-jobs/${id}`), iconProps: {iconName: 'BulletedList'}
		}
	];

	return (
		<IconButton
			menuProps={{items: actions}}
			styles={{}}
			iconProps={{iconName: 'more'}}
			title="Actions"
			ariaLabel="Actions"
			onRenderMenuIcon={() => false}
		/>
	);
}

MoreButton.propTypes = {
	id: PropTypes.string,
	deleteRow: PropTypes.func,
	hasDependentJobs: PropTypes.bool
};

function getKey(item) {
	return item.key;
}

function copyAndSort(items, columnKey, isSortedDescending) {
	const key = columnKey;

	return items.slice(0).sort((a, b) => {
		if (a[key] === b[key]) return 0;
		return ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1);
	});
}

class DetailsListDocumentsExample extends Component {
	constructor(props) {
		super(props);

		const columns = [
			{
				key: 'column1',
				name: 'File Type',
				className: classNames.fileIconCell,
				iconClassName: classNames.fileIconHeaderIcon,
				ariaLabel: 'Column operations for File type, Press to sort on File type',
				iconName: 'Dictionary',
				isIconOnly: true,
				fieldName: 'name',
				minWidth: 16,
				maxWidth: 16,
				columnActionsMode: ColumnActionsMode.disabled,
				onRender: () => <FontIcon iconName="Dictionary" className={classNames.fileIconHeaderIconAlt} />
			},
			{
				key: 'column2',
				name: 'Rule name',
				fieldName: 'title',
				isRowHeader: true,
				isResizable: true,
				isSorted: false,
				isSortedDescending: false,
				sortAscendingAriaLabel: 'Sorted A to Z',
				sortDescendingAriaLabel: 'Sorted Z to A',
				onColumnClick: this.onColumnClick,
				data: 'string',
				isPadded: true
			},
			{
				key: 'column3',
				name: 'Status',
				fieldName: 'isActive',
				minWidth: 125,
				maxWidth: 125,
				isResizable: true,
				isCollapsible: true,
				data: 'boolean',
				onColumnClick: this.onColumnClick,
				onRender: item => <span className={classNames.text}>{(item.isActive ? 'Active' : 'Inactive')}</span>
			},
			{
				key: 'column4',
				name: '',
				fieldName: 'space',
				isResizable: false,
				isCollapsible: false,
				data: 'boolean',
				minWidth: 25,
				maxWidth: 25,
				className: classNames.actionColumns,
				columnActionsMode: ColumnActionsMode.disabled,
				onRender: item => (
					<MoreButton
						{...item}
						deleteRow={this.onRowDelete}
					/>
				)
			}
		];
		const {items, updatedDate} = props;

		this.state = {
			items,
			// eslint-disable-next-line
			updatedDate,
			columns,
			searchText: '',
			selectedStatuses: [true, false]
		};
	}

	render() {
		const {updateData} = this.props;
		const {columns, selectedStatuses, items} = this.state;

		return (
			<div className={classNames.body}>
				<h1 className={classNames.title}>Rules list</h1>
				<div className={classNames.buttonWrapper}>
					<DefaultButton text="Refresh" iconProps={refreshIcon} onClick={updateData}/>
					<AddButton />
				</div>
				<div className={classNames.container}>
					<div className={classNames.controlWrapper}>
						<SearchBox
							placeholder="Filter by name"
							onChange={this.onChangeText}
							className={classNames.searchBox}
						/>
						<Dropdown
							placeholder="Status"
							defaultSelectedKeys={selectedStatuses}
							className={classNames.dropdown}
							multiSelect
							styles={{dropdown: {width: 130}}}
							options={[true, false].map(v => ({key: v, text: v === true ? 'Active' : 'Inactive'}))}
							onChange={this.onChangeStatus}
							onRenderTitle={selected => 'Status: ' + (selected.length > 1 ? 'All' : selected.map(i => i.text).join(', '))}
						/>
					</div>
					<DetailsList
						items={items}
						columns={columns}
						selectionMode={SelectionMode.none}
						getKey={getKey}
						setKey="none"
						layoutMode={DetailsListLayoutMode.justified}
						isHeaderVisible
					/>
				</div>
			</div>
		);
	}

	onChangeText = (ev, text) => {
		const {items} = this.props;
		const {selectedStatuses} = this.state;
		const filteredByStatuses = items.filter(i => selectedStatuses.includes(i.isActive));

		this.setState({
			items: text ? filteredByStatuses.filter(i => i.title.toLowerCase().indexOf(text) > -1) : items,
			searchText: text
		});
	};

	onChangeStatus = (ev, {key, selected}) => {
		const {items} = this.props;
		const {searchText, selectedStatuses} = this.state;
		const newStatuses = selected ? [].concat(selectedStatuses, key) : selectedStatuses.filter(v => v !== key);
		const filteredByText = searchText ? items.filter(i => i.title.toLowerCase().indexOf(searchText) > -1) : items;

		this.setState({
			items: filteredByText.filter(i => newStatuses.includes(i.isActive)),
			selectedStatuses: newStatuses
		});
	};

	onColumnClick = (ev, column) => {
		const {columns, items} = this.state;
		const newColumns = columns.slice();
		const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

		newColumns.forEach(newCol => {
			if (newCol === currColumn) {
				const {isSortedDescending, isSorted} = currColumn;

				newCol.isSorted = true;
				newCol.isSortedDescending = !isSorted ? false : !isSortedDescending;
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = false;
			}
		});

		this.setState({
			columns: newColumns,
			items: copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending)
		});
	};

	onRowDelete = id => {
		const {api, updateData} = this.props;

		// eslint-disable-next-line
		if (window.confirm('following action will delete the rule')) {
			api.delete(`/api/rules/${id}`)
				.then(updateData)
				// eslint-disable-next-line
				.catch(() => window.alert(`error while deleting id: ${id}`));
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.updatedDate !== state.updatedDate) {
			const {items: newItems, updatedDate} = props;
			const {columns, selectedStatuses, searchText} = state;
			const sortedColumn = columns.find(col => col.isSorted || col.isSortedDescending);

			let items = searchText ? newItems.filter(i => i.title.toLowerCase().indexOf(searchText) > -1) : newItems;

			items = items.filter(i => selectedStatuses.includes(i.isActive));

			if (sortedColumn) {
				const {fieldName, isSortedDescending} = sortedColumn;

				items = copyAndSort(items, fieldName, isSortedDescending);
			}

			return {
				updatedDate,
				items
			};
		}

		return null;
	}
}

DetailsListDocumentsExample.propTypes = {
	api: PropTypes.func,
	id: PropTypes.string,
	deleteRow: PropTypes.func,
	updateData: PropTypes.func,
	updatedDate: PropTypes.number,
	items: PropTypes.array
};

export default DetailsListDocumentsExample;
