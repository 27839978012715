export default theme => ({
	root: {
		position: 'relative',
		height: 48
	},
	header: {
		// background: theme.palette.neutralLighter
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		background: theme.palette.themePrimary,
		color: theme.palette.white,
		fontSize: 16,
		lineHeight: '22px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	logoWrapper: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		paddingRight: 24
	},
	logo: {
		width: 48,
		height: 48,
		color: theme.palette.white
	},
	iconsWrapper: {
		marginRight: '2em',
		display: 'flex',
		alignItems: 'center'
	},
	icon: {
		marginLeft: 12,
		padding: 6,
		cursor: 'pointer',
		color: '#fff'
	},
	eventsButton: {
		minHeight: '40px',
		minWidth: '42px',
		padding: '0 4px',
		position: 'relative',
		'&:hover': {
			backgroundColor: theme.semanticColors.primaryButtonBackground,
			borderColor: theme.semanticColors.primaryButtonBackground
		},
		'& .ms-Button-flexContainer': {
			'&:hover': {
				backgroundColor: theme.semanticColors.primaryButtonBackgroundHovered
			},
			borderRadius: '50%',
			backgroundColor: theme.palette.blue,
			width: '32px',
			height: '32px',
			padding: 0
		}
	},
	userBtn: {
		display: 'grid',
		gridAutoFlow: 'column',
		columnGap: '10px',
		alignItems: 'center',
		color: '#fff',
		cursor: 'pointer',
		borderRadius: '9999px',
		'&:hover': {
			backgroundColor: theme.semanticColors.primaryButtonBackgroundHovered,
			color: '#fff'
		}
	},
	callout: {
		display: 'grid',
		gridAutoFlow: 'dense',
		minWidth: '120px'
	},
	calloutItem: {
		display: 'flex',
		height: '36px'
	}
});
