import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {mergeStyleSets, Text} from '@fluentui/react';

const classNames = mergeStyleSets({
	container: {
		padding: '24px 32px 32px',
		background: '#fff',
		boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
		borderRadius: 2,
		marginBottom: 32,
		maxWidth: 1102,
		boxSizing: 'border-box'
	},
	title: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '28px',
		marginBottom: 24
	}
});

class GroupFields extends PureComponent {
	render() {
		const {title, children} = this.props;

		return (
			<div className={classNames.container}>
				<Text as="h2" block className={classNames.title}>{title}</Text>
				{children}
			</div>
		);
	}
}

GroupFields.propTypes = {
	title: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf([PropTypes.node]), PropTypes.node])
};

export default GroupFields;
