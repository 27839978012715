import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
	mergeStyleSets, DefaultButton, Spinner, SpinnerSize, Pivot, PivotItem
} from '@fluentui/react';

const classNames = mergeStyleSets({
	body: {
		flex: 3,
		padding: '2em 2em 3em',
		boxSizing: 'border-box',
		background: '#f3f2f1'
	},
	container: {
		padding: 32,
		maxWidth: 1102,
		background: '#fff',
		boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
		borderRadius: 2,
		boxSizing: 'border-box'
	},
	title: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '28px',
		marginBottom: 24
	},
	titleLink: {
		cursor: 'pointer',
		textDecoration: 'underline'
	},
	buttonWrapper: {
		marginBottom: 32,
		display: 'flex',
		justifyContent: 'space-between',
		maxWidth: 1102,
		boxSizing: 'border-box'
	},
	panelDetails: {
		background: '#000',
		padding: '1em',
		margin: '1em 2em 1em 0'
	},
	panelDetailsInner: {
		listStyle: 'decimal',
		padding: '0 1em 0 2em',
		fontFamily: 'monospace'
	},
	panelDetailsItem: {
		color: '#999',
		whiteSpace: 'pre-wrap',
		wordWrap: 'break-word',
		'&:hover': {
			color: '#eee'
		}
	}
});

const refreshIcon = {iconName: 'Refresh'};
const backIcon = {iconName: 'Back'};
const downloadIcon = {iconName: 'Download'};
const playResumeIcon = {iconName: 'PlayResume'};

class JobRuns extends Component {
	constructor(props) {
		super(props);

		this.state = {
			title: '',
			status: null,
			errors: [],
			logs: [],
			isLoading: false
		};
		this.updatedDate = 0;
	}

	componentDidMount() {
		const {match: {params: {jobId, jobRunId}}} = this.props;

		if (jobId && jobRunId) {
			this.getData();
		}
	}

	render() {
		const {
			title,
			status,
			errors,
			logs,
			isLoading
		} = this.state;
		const {history} = this.props;

		if (isLoading) {
			return (
				<Spinner style={{flex: '1 0 0'}} size={SpinnerSize.large} />
			);
		}

		return (
			<div className={classNames.body}>
				<h1 className={classNames.title}>{title}</h1>
				<div className={classNames.buttonWrapper}>
					<DefaultButton text="Back" iconProps={backIcon} onClick={() => history.goBack()}/>
					<div>
						<DefaultButton disabled={errors.length === 0 && logs.length	=== 0} style={{marginRight: 10}} text="Download .CSV" iconProps={downloadIcon} onClick={this.downloadCSV}/>
						<DefaultButton disabled={status !== 'Error'} style={{marginRight: 10}} text="Resume" iconProps={playResumeIcon} onClick={this.resume}/>
						<DefaultButton text="Refresh" iconProps={refreshIcon} onClick={this.getData}/>
					</div>
				</div>
				<div className={classNames.container}>
					<Pivot aria-label="Tabs">
						{errors.length > 0 && (
							<PivotItem headerText="Errors">
								<div className={classNames.panelDetails}>
									<ol className={classNames.panelDetailsInner}>
										{errors.map((str, index) => (
											<li key={index} className={classNames.panelDetailsItem}>{str}</li>
										))}
									</ol>
								</div>
							</PivotItem>
						)}
						{logs.length > 0 && (
							<PivotItem headerText="Logs">
								<div className={classNames.panelDetails}>
									<ol className={classNames.panelDetailsInner}>
										{logs.map((str, index) => (
											<li key={index} className={classNames.panelDetailsItem}>{str}</li>
										))}
									</ol>
								</div>
							</PivotItem>
						)}
						{errors.length === 0 && logs.length	=== 0 && (
							<PivotItem headerText="Logs">
								<div className={classNames.panelDetails}>
									<ol className={classNames.panelDetailsInner}>
										<li className={classNames.panelDetailsItem}>{' '}</li>
									</ol>
								</div>
							</PivotItem>
						)}
					</Pivot>
				</div>
			</div>
		);
	}

	// eslint-disable-next-line
	getData = async () => {
		const {api, match: {params: {jobId, jobRunId}}} = this.props;

		try {
			const {data: {title}} = await api.get(`/api/migrationJobs/${jobId}`);
			const {data: {status, details: {errors = [], logs = []}}} = await api.get(`/api/migrationJobRuns/${jobRunId}`);

			this.setState({
				title,
				errors,
				logs,
				status,
				isLoading: false
			});
			this.updatedDate = new Date().getTime();
		} catch (error) {
			this.state = {
				isLoading: true
			};
		}
	};

	downloadCSV = async () => {
		const {api, match: {params: {jobRunId}}} = this.props;
		const {title} = this.state;

		try {
			const {data} = await api.get(`/api/migrationJobRuns/${jobRunId}/csv`);
			const filename = title + '.csv';
			const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});

			if (navigator.msSaveBlob) { // IE 10+
				navigator.msSaveBlob(blob, filename);
			} else {
				const link = document.createElement('a');

				if (link.download !== undefined) {
					const url = URL.createObjectURL(blob);

					link.setAttribute('href', url);
					link.setAttribute('download', filename);
					link.style.visibility = 'hidden';

					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			}
		} catch (e) {
			console.log('DOWNLOAD BUTTON ERROR: ', e);
		}
	};

	resume = async () => {
		const {api, match: {params: {jobRunId}}} = this.props;

		try {
			await api.post('/api/migrationJobRuns/resume', {
				id: jobRunId
			});
			await this.getData();
		} catch (e) {
			console.log('RESUME BUTTON ERROR: ', e);
		}
	}
}

JobRuns.propTypes = {
	api: PropTypes.func,
	match: PropTypes.object,
	history: PropTypes.object
};

export default JobRuns;
