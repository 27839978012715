import React, {useMemo, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory, Link} from 'react-router-dom';
import {
	DirectionalHint,
	ContextualMenuItemType,
	ColumnActionsMode,
	DetailsHeader,
	ContextualMenu,
	SearchBox,
	DetailsList,
	DetailsListLayoutMode,
	SelectionMode,
	Selection,
	Spinner,
	SpinnerSize,
	Stack,
	DetailsRow
} from '@fluentui/react';
import {useStore} from 'effector-react';
import './styles.scss';
import FilterByColumnType from './components/filter-by-column-type';
import PredefinedFilters from './components/predefined-filters';
import CommandBarComponent from './components/command-bar';
import Pagination from './components/pagination';
import PageSizeSelector from './components/page-size-selector';
import classNames from './classNames';
import getKey from './utils';
import {
	jobList,
	userFilters,
	setOrderBy,
	setIsAscending,
	setSearch,
	setUserFilters,
	orderBy,
	isAscending,
	authTokens,
	blobAuths,
	statuses,
	sites,
	currentPage,
	rulesDefinitions,
	setLastRunDateRange,
	resetOrderBy,
	resetIsAscending,
	isNeverRunStore,
	setIsNeverRun,
	lastRunDateRangeStore
} from '../store';

function getFirstZero(i) {
	return (i < 10 ? '0' : '') + i;
}

function renderDateTime(_date) {
	if (!_date) {
		return '';
	}

	const date = new Date(_date);
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const month = months[date.getMonth()];
	const day = getFirstZero(date.getDate());
	const hours = getFirstZero(date.getHours());
	const minutes = getFirstZero(date.getMinutes());
	const seconds = getFirstZero(date.getSeconds());

	return `${day} ${month} ${date.getFullYear()} ${hours}:${minutes}:${seconds}`;
}

const JobList = ({
					 error,
					 onDelete,
					 onJobRun,
					 onTestRun,
					 onRefreshData,
					 onResetAllFilters,
					 onFilter
				 }) => {
	const history = useHistory();

	const [currentRowId, setCurrentRowId] = useState();
	const [contextualMenuProps, setContextualMenuProps] = useState();
	const [selectedColumn, setSelectedColumn] = useState();
	const [isOpenFilterByPanel, setIsOpenFilterByPanel] = useState(false);

	const userFiltersBoolean = useStore(userFilters);
	const list = useStore(jobList);
	const orderByStr = useStore(orderBy);
	const isAscendingBoolean = useStore(isAscending);
	const authTokensArr = useStore(authTokens);
	const blobAuthsArr = useStore(blobAuths);
	const statusesArr = useStore(statuses);
	const sitesArr = useStore(sites);
	const rulesDefinitionsDef = useStore(rulesDefinitions);
	const isNeverRun = useStore(isNeverRunStore);
	const lastRunDateRange = useStore(lastRunDateRangeStore);

	// eslint-disable-next-line no-unused-vars
	const [selection, setSelection] = useState(
		new Selection({
			getKey: item => item.id,
			onSelectionChanged: () => {
				const keys = selection.getSelection().map(item => item.id);

				setCurrentRowId(keys[0]); // undefined | string
			}
		})
	);

	function panelOnDismiss() {
		setIsOpenFilterByPanel(!isOpenFilterByPanel);
	}

	function renderFilterByPanel(column) {
		setSelectedColumn(column);
		setIsOpenFilterByPanel(true);
	}

	function onContextualMenuDismissed() {
		setContextualMenuProps(undefined);
	}

	function sortItems(item, _isAscending) {
		setOrderBy(item.key);
		setIsAscending(_isAscending);
		setUserFilters(true);
	}

	function needToDisplayFilterPanel(columnKey) {
		if (columnKey === 'title' || !rulesDefinitionsDef) {
			return false;
		}

		let isDisabled = false;

		switch (columnKey) {
			case 'site':
				isDisabled = !!rulesDefinitionsDef.sites.length;
				break;
			case 'status':
				isDisabled = !!rulesDefinitionsDef.authTokens.length;
				break;
			case 'blobAuths':
				isDisabled = !!rulesDefinitionsDef.blobAuths.length;
				break;
			case 'createdBy':
				isDisabled = !!rulesDefinitionsDef.statuses.length;
				break;
		}

		return isDisabled;
	}

	const getActiveFilterStatus = useCallback(columnKey => {
		switch (columnKey) {
			case 'site':
				return !!sitesArr.length;
			case 'status':
				return !!statusesArr.length;
			case 'blobAuths':
				return !!blobAuthsArr.length;
			case 'createdBy':
				return !!authTokensArr.length;
			case 'lastRun':
				return lastRunDateRange !== null ? !!lastRunDateRange.start || !!lastRunDateRange.end : false;
			default:
				return false;
		}
	}, [authTokensArr, blobAuthsArr, sitesArr, statusesArr, lastRunDateRange]);

	function getContextualMenuProps(
		ev,
		column
	) {
		let items = [];

		const checked = getActiveFilterStatus(column.key);

		switch (column.key) {
			case 'lastRun':
				items = [
					{
						key: 'Oldest' + column.key,
						name: 'Sort Oldest first',
						checked: orderByStr === column.key && isAscendingBoolean === true,
						canCheck: true,
						onClick: () => {
							setIsNeverRun(false);
							sortItems(column, true);
						}
					},
					{
						key: 'Latest' + column.key,
						name: 'Sort Latest first',
						checked: orderByStr === column.key && isAscendingBoolean === false,
						canCheck: true,
						onClick: () => {
							setIsNeverRun(false);
							sortItems(column, false);
						}
					},
					//					{
					//						key: 'neverLastRun',
					//						name: 'Never',
					//						checked: isNeverRun,
					//						canCheck: true,
					//						onClick: () => {
					//							const status = !isNeverRun;
					//
					//							resetOrderBy();
					//							resetIsAscending();
					//
					//							if (status) {
					//								setLastRunDateRange({start: '', end: ''});
					//							} else {
					//								setLastRunDateRange({start: undefined, end: undefined});
					//							}
					//
					//							setIsNeverRun(status);
					//						}
					//					},
					{
						key: 'divider_1',
						itemType: ContextualMenuItemType.Divider
					},
					{
						key: 'filterLastRun',
						name: 'Filter',
						checked,
						canCheck: true,
						onClick: () => renderFilterByPanel(column)
					}
				];
				break;
			case 'site':
			case 'status':
			case 'createdBy':
			case 'title':
			case 'blobAuths':
				if (column.key !== 'blobAuths') {
					items = [
						{
							key: 'AZUp' + column.key,
							name: 'Sort A to Z',
							checked: orderByStr === column.key && isAscendingBoolean === true,
							canCheck: true,
							onClick: () => sortItems(column, true)
						},
						{
							key: 'ZADown' + column.key,
							name: 'Sort Z to A',
							checked: orderByStr === column.key && isAscendingBoolean === false,
							canCheck: true,
							onClick: () => sortItems(column, false)
						}
					];
				}

				if (needToDisplayFilterPanel(column.key)) {
					items = [...items, ...[
						{
							key: 'divider_1',
							itemType: ContextualMenuItemType.Divider
						},
						{
							key: 'filterBy',
							name: 'Filter By',
							checked,
							canCheck: true,
							onClick: () => renderFilterByPanel(column)
						}
					]];
				}
				break;
		}

		return {
			shouldFocusOnMount: true,
			items,
			target: ev.currentTarget,
			directionalHint: DirectionalHint.bottomLeftEdge,
			onDismiss: onContextualMenuDismissed
		};
	}

	function onContextMenu(
		column,
		ev
	) {
		if (
			ev
			&& column
			&& column.columnActionsMode !== ColumnActionsMode.disabled
		) {
			setContextualMenuProps(getContextualMenuProps(ev, column));
		}
	}

	function onItemContextMenu(item, index) {
		if (index && index > -1) {
			setContextualMenuProps(contextualMenuProps);
		}

		return false;
	}

	function onColumnClick(
		ev,
		column
	) {
		if (column.columnActionsMode !== ColumnActionsMode.disabled) {
			setContextualMenuProps(getContextualMenuProps(ev, column));
		}
	}

	const renderCustomHeader = props => {
		if (props) {
			return (
				<DetailsHeader
					{...props}
					onColumnContextMenu={onContextMenu}
					onColumnClick={onColumnClick}
				/>
			);
		}
		return <></>;
	};

	const tableColumns = useMemo(() => ([
		{
			key: 'title',
			name: 'Job title',
			fieldName: 'title',
			isRowHeader: true,
			isResizable: false,
			columnActionsMode: 2,
			data: 'string',
			isMultiline: true,
			minWidth: 200,
			maxWidth: 210,
			onRender: ({id = '', title = ''}) => (
				<Link className={classNames.titleLink}
					to={`/archiving/job-runs/${id}`}
				>{title}
				</Link>
			)
		},
		{
			key: 'site',
			name: 'Site Name',
			fieldName: 'siteName',
			columnActionsMode: 2,
			data: 'string',
			isMultiline: true,
			isRowHeader: true,
			isResizable: false,
			minWidth: 250,
			maxWidth: 260,
			ariaLabel: 'Filter by Site Name'
		},
		{
			key: 'lastRun',
			name: 'Last run',
			fieldName: 'lastRun',
			isRowHeader: true,
			isResizable: false,
			columnActionsMode: ColumnActionsMode.hasDropdown,
			data: 'string',
			minWidth: 135,
			maxWidth: 150,
			isMultiline: true,
			onRender: ({lastRun}) => renderDateTime(lastRun)
		},
		{
			key: 'nextRun',
			name: 'Next Run',
			fieldName: 'nextRun',
			isRowHeader: true,
			isResizable: false,
			columnActionsMode: 0,
			data: 'string',
			minWidth: 135,
			maxWidth: 150,
			isMultiline: true,
			onRender: ({nextRun}) => renderDateTime(nextRun)
		},
		{
			key: 'status',
			name: 'Status',
			fieldName: 'status',
			isRowHeader: true,
			isResizable: false,
			columnActionsMode: 2,
			data: 'string',
			minWidth: 135,
			maxWidth: 150
		},
		{
			key: 'blobAuths',
			name: 'Destination',
			fieldName: 'blobAuthName',
			isResizable: false,
			isRowHeader: true,
			columnActionsMode: 2,
			data: 'string',
			minWidth: 135,
			maxWidth: 150
		},
		{
			key: 'createdBy',
			name: 'Run as user',
			fieldName: 'userName',
			isRowHeader: true,
			isResizable: false,
			columnActionsMode: 2,
			data: 'string',
			minWidth: 250,
			maxWidth: 260,
			isMultiline: true,
			isPadded: true
		}
	]), []);

	const columns = useMemo(() => tableColumns.map(column => {
		if (list) {
			const columnKey = column.key;
			const customStyles = {cellTitle: 'job-list__active-filter'};

			const filtred = getActiveFilterStatus(column.key);
			const sorted = orderByStr === columnKey;

			switch (columnKey) {
				case 'site':
				case 'statuses':
				case 'blobAuths':
				case 'createdBy':
				case 'title':
				case 'lastRun':
					return {
						...column,
						isFiltered: filtred,
						isSorted: sorted,
						isSortedDescending: isAscendingBoolean,
						styles: filtred ? customStyles : {}
					};
				default:
					return column;
			}
		} else {
			return column;
		}
	}), [tableColumns, list, getActiveFilterStatus, orderByStr, isAscendingBoolean]);

	const onRenderRow = props => {
		if (props) {
			const customStyles = {};

			customStyles.cell = {display: 'flex', alignItems: 'center'};
			customStyles.checkCell = {display: 'flex', alignItems: 'center'};
			customStyles.root = {
				'.ms-DetailsRow-cell': {
					fontSize: 12,
					':nth-child(1), :nth-child(2)': {
						fontSize: 14
					}
				}
			};

			return <DetailsRow {...props} styles={customStyles}/>;
		}
		return <></>;
	};

	const getJobList = useCallback(async () => {
		try {
			await onFilter();
		} catch (errorEx) {
			throw new Error(`Can't getting the jobs list - ${errorEx}`);
		}
	}, [onFilter]);

	const handleSearch = (_ev, text) => {
		setSearch(text);
		setUserFilters(true);
	};

	useEffect(() => {
		if (userFiltersBoolean) {
			getJobList();
		}
	}, [userFiltersBoolean, getJobList]);

	useEffect(() => {
		if (currentPage) {
			setCurrentRowId(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	if (error && !list) {
		return (
			<div className={classNames.body}>
				Error while loading data.
				Please try again later
			</div>
		);
	}

	if (!list.length && !userFilters) {
		return (
			<Spinner style={{flex: '1 0 0'}} size={SpinnerSize.large}/>
		);
	}

	return (
		<div className={classNames.body}>
			<CommandBarComponent
				methods={{
					deleteRow: onDelete,
					runJob: onJobRun,
					runTest: onTestRun,
					onRefreshData,
					onResetAllFilters
				}}
				id={currentRowId}
				classNames={classNames}
			/>
			<Stack className={classNames.wrapper}>
				<Stack.Item>
					<h1 className={classNames.title}>Jobs list</h1>
				</Stack.Item>
				<Stack.Item>
					<Stack className={classNames.container}>
						<Stack.Item className={classNames.controlWrapper}>
							<SearchBox
								placeholder="Filter by title"
								onChange={handleSearch}
								className={classNames.searchBox}
								underlined
							/>
						</Stack.Item>
						<Stack.Item>
							<PredefinedFilters/>
						</Stack.Item>
						<Stack.Item
							styles={{root: 'job-list__container'}}
						>
							<DetailsList
								items={list}
								columns={columns}
								selection={selection}
								selectionPreservedOnEmptyClick
								selectionMode={SelectionMode.single}
								onRenderDetailsHeader={renderCustomHeader}
								onRenderRow={onRenderRow}
								onItemContextMenu={onItemContextMenu}
								getKey={getKey}
								setKey="none"
								layoutMode={DetailsListLayoutMode.justified}
								onItemInvoked={({id}) => history.push(`/archiving/job-runs/${id}`)}
								isHeaderVisible
								styles={{root: 'job-list'}}
							/>
						</Stack.Item>

						{contextualMenuProps && (
							<ContextualMenu {...contextualMenuProps} />
						)}

						<Stack.Item>
							<Stack className="job-list__page-footer">
								<Stack.Item>
									<div className="job-list__page-pagination">
										<Pagination/>
									</div>
								</Stack.Item>
								<Stack.Item>
									<div className="job-list__page-size-selector">
										<PageSizeSelector/>
									</div>
								</Stack.Item>
							</Stack>
						</Stack.Item>
					</Stack>
				</Stack.Item>
			</Stack>

			{isOpenFilterByPanel && (
				<FilterByColumnType
					onFilter={onFilter}
					column={selectedColumn}
					onDismiss={panelOnDismiss}
				/>
			)}
		</div>
	);
};

JobList.propTypes = {
	error: PropTypes.string,
	onDelete: PropTypes.func,
	onJobRun: PropTypes.func,
	onTestRun: PropTypes.func,
	onRefreshData: PropTypes.func,
	onResetAllFilters: PropTypes.func,
	onFilter: PropTypes.func
};

export default JobList;
