import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {
	mergeStyleSets,
	DetailsList,
	DetailsListLayoutMode,
	SelectionMode,
	DefaultButton,
	Spinner,
	SpinnerSize,
	MessageBar,
	MessageBarType,
	Breadcrumb,
	Text
} from '@fluentui/react';
import cronstrue from 'cronstrue';
import {
	optionsOverwriteFile,
	archivingBehavior
} from '../add-or-edit/options';
import './index.css';

import CancelButton from './components/cancel-btn';
import RunButton from './components/run-btn';
import {getvaluesKey, StatusHint} from '../utils';

function getFirstZero(i) {
	return (i < 10 ? '0' : '') + i;
}

function renderDateTime(_date) {
	if (!_date) return '';

	const date = new Date(_date);
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	];
	const month = months[date.getMonth()];
	const day = getFirstZero(date.getDate());
	const hours = getFirstZero(date.getHours());
	const minutes = getFirstZero(date.getMinutes());
	const seconds = getFirstZero(date.getSeconds());

	return `${day} ${month} ${date.getFullYear()}\n${hours}:${minutes}:${seconds}`;
}

function renderSize(_bytes) {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

	if (_bytes === 0) {
		return '0 Byte';
	}

	const i = parseInt(Math.floor(Math.log(_bytes) / Math.log(1024)), 10);

	return Math.round(_bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

function toFixedIfNotFull(i) {
	return i % 1 > 0 ? i.toFixed(1) : i;
}

function copyAndSort(items, columnKey, isSortedDescending) {
	const key = columnKey;

	return items.slice(0).sort((a, b) => {
		if (a[key] === b[key]) return 0;
		return (isSortedDescending ? a[key] < b[key] : a[key] > b[key])
			? 1
			: -1;
	});
}

const classNames = mergeStyleSets({
	body: {
		flex: 3,
		padding: '2em 2em 3em',
		boxSizing: 'border-box',
		background: '#f3f2f1'
	},
	container: {
		padding: 32,
		height: '100%',
		background: '#fff',
		boxShadow:
			'0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
		borderRadius: 2,
		boxSizing: 'border-box',
		display: 'grid',
		gridAutoFlow: 'row',
		rowGap: '40px'
	},
	title: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '28px',
		marginBottom: 24
	},
	titleLink: {
		cursor: 'pointer',
		textDecoration: 'underline'
	},
	buttonWrapper: {
		marginBottom: 32,
		display: 'flex',
		justifyContent: 'space-between',
		maxWidth: 1102,
		boxSizing: 'border-box'
	},
	panelDetails: {
		background: '#000',
		padding: '1em',
		margin: '1em 2em 1em 0'
	},
	panelDetailsInner: {
		listStyle: 'decimal',
		padding: '0 1em 0 2em',
		fontFamily: 'monospace'
	},
	panelDetailsItem: {
		color: '#999',
		whiteSpace: 'pre-wrap',
		'&:hover': {
			color: '#eee'
		}
	},
	contentItem: {
		display: 'grid',
		gridAutoFlow: 'row',
		rowGap: '12px'
	},
	contentDetailsContainer: {
		display: 'grid',
		gridAutoFlow: 'column'
	},
	contentDetailsGroupContainer: {
		display: 'grid',
		gridAutoFlow: 'row',
		gridAutoRows: 'max-content',
		rowGap: '16px'
	},
	contentDetailsItemsContainer: {
		display: 'grid',
		gridAutoFlow: 'row',
		rowGap: '16px'
	},
	contentDetailsItemContainer: {
		display: 'grid',
		gridAutoFlow: 'row'
	},
	heading: {
		fontSize: '18px',
		fontWeight: 600
	},
	subHeading: {
		fontSize: '16px',
		fontWeight: 600
	},
	muted: {
		color: '#8A8886'
	},
	devider: {
		borderTop: '1px solid #EDEBE9',
		height: '25px'
	},
	jobRunsContainer: {
		display: 'grid',
		gridAutoFlow: 'column',
		gridAutoColumns: 'max-content',
		justifyContent: 'space-between'
	},
	jobRunsActions: {
		display: 'grid',
		gridAutoFlow: 'column',
		gridAutoColumns: 'max-content',
		columnGap: '16px'
	}
});

const refreshIcon = {iconName: 'Refresh'};

class JobRuns extends Component {
	constructor(props) {
		super(props);

		const renderTotalFiles = ({totalFiles, processedFiles}) => (processedFiles === totalFiles
			? totalFiles
			: `${processedFiles} / ${totalFiles}`);
		const renderTotalSize = ({totalSize, processedSize}) => (totalSize === processedSize
			? renderSize(totalSize)
			: `${renderSize(processedSize)} / ${renderSize(totalSize)}`);

		this.state = {
			items: [],
			job: {},
			columns: [
				{
					key: 'index',
					name: '№',
					fieldName: 'index',
					isRowHeader: true,
					isResizable: true,
					isSorted: true,
					isSortedDescending: true,
					sortAscendingAriaLabel: 'Sorted A to Z',
					sortDescendingAriaLabel: 'Sorted Z to A',
					data: 'string',
					isPadded: false,
					minWidth: 40,
					maxWidth: 40
				},
				{
					key: 'isDryRun',
					name: 'Type',
					fieldName: 'isDryRun',
					isRowHeader: true,
					isResizable: true,
					isSorted: false,
					isSortedDescending: false,
					sortAscendingAriaLabel: 'Sorted A to Z',
					sortDescendingAriaLabel: 'Sorted Z to A',
					data: 'bool',
					maxWidth: 40,
					minWidth: 40,
					isPadded: true,
					onRender: ({isDryRun}) => (isDryRun ? 'Test' : 'Full')
				},
				{
					key: 'status',
					name: 'Status',
					fieldName: 'status',
					isRowHeader: true,
					isResizable: true,
					isSorted: false,
					isSortedDescending: false,
					sortAscendingAriaLabel: 'Sorted A to Z',
					sortDescendingAriaLabel: 'Sorted Z to A',
					data: 'string',
					isPadded: true,
					minWidth: 80,
					maxWidth: 80,
					onRender: ({status = '', id, jobId}) => (
						<span
							className={classNames.titleLink}
							onClick={() => this.openDetails(id, jobId)}
						>
							{status}
						</span>
					)
				},
				{
					key: 'start',
					name: 'Start',
					fieldName: 'start',
					isRowHeader: true,
					isResizable: true,
					isSorted: false,
					isSortedDescending: false,
					sortAscendingAriaLabel: 'Sorted A to Z',
					sortDescendingAriaLabel: 'Sorted Z to A',
					isMultiline: true,
					data: 'string',
					isPadded: true,
					minWidth: 150,
					maxWidth: 200,
					onRender: ({start}) => renderDateTime(start)
				},
				{
					key: 'end',
					name: 'End',
					fieldName: 'end',
					isRowHeader: true,
					isResizable: true,
					isSorted: false,
					isSortedDescending: false,
					sortAscendingAriaLabel: 'Sorted A to Z',
					sortDescendingAriaLabel: 'Sorted Z to A',
					isMultiline: true,
					data: 'string',
					isPadded: true,
					minWidth: 150,
					maxWidth: 200,
					onRender: ({end}) => renderDateTime(end)
				},
				{
					key: 'migrationProgress',
					name: 'Progress',
					fieldName: 'migrationProgress',
					isRowHeader: true,
					isResizable: true,
					isSorted: false,
					isSortedDescending: false,
					sortAscendingAriaLabel: 'Sorted A to Z',
					sortDescendingAriaLabel: 'Sorted Z to A',
					data: 'string',
					isPadded: false,
					minWidth: 70,
					maxWidth: 70,
					onRender: ({migrationProgress}) => `${toFixedIfNotFull(migrationProgress)}%`
				},
				{
					key: 'totalFiles',
					name: 'Files archived',
					fieldName: 'totalFiles',
					isRowHeader: true,
					isResizable: true,
					isSorted: false,
					isSortedDescending: false,
					sortAscendingAriaLabel: 'Sorted A to Z',
					sortDescendingAriaLabel: 'Sorted Z to A',
					data: 'string',
					isPadded: false,
					maxWidth: 90,
					minWidth: 90,
					onRender: renderTotalFiles
				},
				{
					key: 'totalSize',
					name: 'Size',
					fieldName: 'totalSize',
					isRowHeader: true,
					isResizable: true,
					isSorted: false,
					isSortedDescending: false,
					sortAscendingAriaLabel: 'Sorted A to Z',
					sortDescendingAriaLabel: 'Sorted Z to A',
					data: 'string',
					isMultiline: true,
					maxWidth: 100,
					minWidth: 100,
					isPadded: false,
					onRender: renderTotalSize
				}
			],
			isLoading: true,
			error: null
		};

		this.updatedDate = 0;

		this.timer = null;
	}

	componentDidMount() {
		const {
			match: {
				params: {jobId}
			}
		} = this.props;

		if (jobId) {
			this.getData();
		}
	}

	componentDidUpdate() {
		const {error} = this.state;

		if (error) {
			this.timer = setTimeout(() => {
				this.setState({
					error: null
				});
			}, 5000);
		} else {
			clearTimeout(this.timer);
		}
	}

	render() {
		const {
			history,
			api,
			match: {
				params: {jobId}
			}
		} = this.props;
		const {
			items, job, columns, isLoading, error
		} = this.state;

		if (isLoading) {
			return (
				<Spinner style={{flex: '1 0 0'}} size={SpinnerSize.large} />
			);
		}

		const breadcrumbs = [
			{
				text: 'Jobs list',
				key: 'jobsList',
				onClick: () => history.goBack()
			},
			{text: job.title, key: 'job'}
		];

		return (
			<>
				<div className={classNames.body}>
					<div className={classNames.container}>
						<Breadcrumb
							items={breadcrumbs}
							maxDisplayedItems={2}
							overflowAriaLabel="More"
						/>

						<div className={classNames.contentItem}>
							<Text className={classNames.heading}>
								Job details
							</Text>
							<div className={classNames.contentDetailsContainer}>
								<div
									className={
										classNames.contentDetailsGroupContainer
									}
								>
									<Text className={classNames.subHeading}>
										Blob
									</Text>
									<div
										className={
											classNames.contentDetailsItemsContainer
										}
									>
										<div
											className={
												classNames.contentDetailsItemContainer
											}
										>
											<Text className={classNames.muted}>
												Blob destination
											</Text>
											<Text>{job.blobAuthName}</Text>
										</div>
										<div
											className={
												classNames.contentDetailsItemContainer
											}
										>
											<Text className={classNames.muted}>
												Storage tier
											</Text>
											<Text>
												{job.overwriteStorageTier
												&& job.overwriteStorageTier
													.length > 0
													? job.overwriteStorageTier
													: 'Default'}
											</Text>
										</div>
										<div
											className={
												classNames.contentDetailsItemContainer
											}
										>
											<Text className={classNames.muted}>
												Archiving blob behaviour
											</Text>
											<Text>
												{
													optionsOverwriteFile.find(
														i => i.key
																=== job.overwriteFile
															?? false
													).text
												}
											</Text>
										</div>
										<div
											className={
												classNames.contentDetailsItemContainer
											}
										>
											<Text className={classNames.muted}>
												Archiving Sharepoint behaviour
											</Text>
											<Text>
												{
													archivingBehavior.find(
														i => i.key
															=== job.archivingBehavior
													).text
												}
											</Text>
										</div>
									</div>
								</div>
								<div
									className={
										classNames.contentDetailsGroupContainer
									}
								>
									<Text className={classNames.subHeading}>
										Rules
									</Text>
									<div
										className={
											classNames.contentDetailsItemsContainer
										}
									>
										<div
											className={
												classNames.contentDetailsItemContainer
											}
										>
											<Text className={classNames.muted}>
												Rule title
											</Text>
											<Text>
												{job.rulesNames.join(', ')}
											</Text>
										</div>
									</div>
								</div>
								<div
									className={
										classNames.contentDetailsGroupContainer
									}
								>
									<Text className={classNames.subHeading}>
										Indexing
									</Text>
									<div
										className={
											classNames.contentDetailsItemsContainer
										}
									>
										<div
											className={
												classNames.contentDetailsItemContainer
											}
										>
											<Text className={classNames.muted}>
												Image analytics
											</Text>
											<Text>
												{
													StatusHint[
														getvaluesKey(
															job.indexingProperties,
															'Image analytics'
														)
													]
												}
											</Text>
										</div>
										<div
											className={
												classNames.contentDetailsItemContainer
											}
										>
											<Text className={classNames.muted}>
												Text analytics
											</Text>
											<Text>
												{
													StatusHint[
														getvaluesKey(
															job.indexingProperties,
															'Text analytics'
														)
													]
												}
											</Text>
										</div>
										<div
											className={
												classNames.contentDetailsItemContainer
											}
										>
											<Text className={classNames.muted}>
												Generate preview
											</Text>
											<Text>
												{
													StatusHint[
														getvaluesKey(
															job.indexingProperties,
															'Generate preview'
														)
													]
												}
											</Text>
										</div>
										<div
											className={
												classNames.contentDetailsItemContainer
											}
										>
											<Text className={classNames.muted}>
												Video analytics
											</Text>
											<Text>
												{
													StatusHint[
														getvaluesKey(
															job.indexingProperties,
															'Video analytics'
														)
													]
												}
											</Text>
										</div>
									</div>
								</div>
								{job.schedule.length ? (
									<div
										className={
											classNames.contentDetailsGroupContainer
										}
									>
										<Text className={classNames.subHeading}>
											Schedule
										</Text>
										<div
											className={
												classNames.contentDetailsItemsContainer
											}
										>
											{
												job.isScheduleEnabled ? (
													<div
														className={
															classNames.contentDetailsItemContainer
														}
													>
														<Text
															className={classNames.muted}
														>
															Schedule
														</Text>
														<Text>
															{cronstrue.toString(
																job.schedule,
																{
																	verbose: false,
																	use24HourTimeFormat: true
																}
															)}
														</Text>
													</div>
												) : (
													<div
														className={
															classNames.contentDetailsItemContainer
														}
													>
														<Text
															className={classNames.muted}
														>
															Manual run
														</Text>
													</div>
												)
											}
										</div>
									</div>
								) : null}
							</div>
						</div>
						<div className={classNames.contentItem}>
							<div>
								<div className={classNames.devider} />
								<div className={classNames.jobRunsContainer}>
									<Text className={classNames.heading}>
										Job runs
									</Text>
									<div className={classNames.jobRunsActions}>
										<DefaultButton
											text="Refresh"
											iconProps={refreshIcon}
											onClick={() => this.getData(true)}
										/>
										<CancelButton
											api={api}
											jobId={jobId}
											doRefresh={this.getData}
											onError={this.onError}
										/>
										<RunButton
											api={api}
											jobId={jobId}
											doRefresh={this.getData}
											onError={this.onError}
										/>
									</div>
								</div>
							</div>
							<DetailsList
								items={items}
								columns={columns}
								selectionMode={SelectionMode.none}
								getKey={v => v && v.id}
								setKey="none"
								layoutMode={DetailsListLayoutMode.justified}
								isHeaderVisible
								onColumnHeaderClick={this.onColumnHeaderClick}
								onItemInvoked={this.onItemInvoked}
								className="details-list"
							/>
						</div>
					</div>
				</div>

				{error && (
					<MessageBar
						messageBarType={MessageBarType.error}
						styles={{root: 'message-bar'}}
					>
						An error has occurred - [{error.code}]: {error.message}
					</MessageBar>
				)}
			</>
		);
	}

	onColumnHeaderClick = (ev, column) => {
		const {columns, items} = this.state;
		const newColumns = columns.slice();
		const currColumn = newColumns.filter(
			currCol => column.key === currCol.key
		)[0];

		newColumns.forEach(newCol => {
			if (newCol === currColumn) {
				const {isSortedDescending, isSorted} = currColumn;

				newCol.isSorted = true;
				newCol.isSortedDescending = !isSorted
					? false
					: !isSortedDescending;
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = false;
			}
		});

		const newItems = copyAndSort(
			items,
			currColumn.fieldName,
			currColumn.isSortedDescending
		);

		this.setState({
			columns: newColumns,
			items: newItems
		});
	};

	onItemInvoked = ({id, jobId, details: {logs, errors}}) => (logs.length > 0 || errors.length !== 0) && this.openDetails(id, jobId); // args: item, index, event

	// eslint-disable-next-line
	getData = async (isUpdate) => {
		const {
			api,
			match: {
				params: {jobId}
			}
		} = this.props;

		try {
			const job = await api.get(`/api/migrationJobs/${jobId}`);
			const {data} = await api.get(`/api/migrationJobs/${jobId}/runs`);
			const {length} = data;

			let items = data.map((item, index) => {
				item.index = length - index;

				return item;
			});

			if (isUpdate) {
				const {columns} = this.state;
				const sortedColumn = columns.find(
					col => col.isSorted || col.isSortedDescending
				);

				if (sortedColumn) {
					const {fieldName, isSortedDescending} = sortedColumn;

					items = copyAndSort(items, fieldName, isSortedDescending);
				}
			}

			this.setState({
				items,
				job: job.data,
				isLoading: false
			});
			this.updatedDate = new Date().getTime();
		} catch (error) {
			this.state = {
				isLoading: true
			};
		}
	};

	onError = ex => {
		this.setState({error: ex});
	};

	openDetails = (id, jobId) => {
		const {history} = this.props;

		history.push(`/archiving/job-runs/${jobId}/${id}`);
	};
}

JobRuns.propTypes = {
	api: PropTypes.func,
	match: PropTypes.object,
	history: PropTypes.object
};

export default withRouter(JobRuns);
