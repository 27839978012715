import React, {Component} from 'react';
import {Route, Redirect} from 'react-router-dom';

import {RulesList, RulesAddOrEdit, JobsListOfRule} from './components/rules';
import {JobList, JobAddOrEdit, JobRunsOrDetails} from './components/jobs';
import {BlobDestinationList, BlobDestinationForm, JobsListOfBlobDestination} from './components/blob-destination';
import {SharepointSitesList, SharepointSitesForm} from './components/sharepoints-sites';
import SystemUsersList from './components/system-users';

class ArchivingComponent extends Component {
	render() {
		return (
			<Route path="/archiving"
				render={({match: {url}}) => (
					<>
						<Route exact path={`${url}`}>
							<Redirect to={`${url}/rules`} />
						</Route>
						<Route exact path={`${url}/rules`} component={() => <RulesList {...this.props}/>} />
						<Route path={`${url}/rules-jobs/:ruleId`} component={() => <JobsListOfRule {...this.props}/>} />
						<Route path={`${url}/rules/:id`} component={() => <RulesAddOrEdit {...this.props}/>} />
						<Route exact path={`${url}/jobs`} component={() => <JobList {...this.props}/>} />
						<Route path={`${url}/jobs/:id/:isCopy?`} component={() => <JobAddOrEdit {...this.props}/>} />
						<Route path={`${url}/job-runs/:jobId/:jobRunId?`} component={() => <JobRunsOrDetails {...this.props}/>} />
						<Route exact path={`${url}/blob-destination`} component={() => <BlobDestinationList {...this.props}/>} />
						<Route path={`${url}/blob-destination-rules/:blobDestinationId`} component={() => <JobsListOfBlobDestination {...this.props}/>} />
						<Route path={`${url}/blob-destination/:id`} component={() => <BlobDestinationForm {...this.props}/>} />
						<Route exact path={`${url}/sharepoints-sites`} component={() => <SharepointSitesList {...this.props}/>} />
						<Route path={`${url}/sharepoints-sites/:id`} component={() => <SharepointSitesForm {...this.props}/>} />
						<Route exact path={`${url}/system-users`} component={() => <SystemUsersList {...this.props}/>} />
					</>
				)}
			/>
		);
	}
}

const ArchivingModule = props => ({
	name: 'archiving-module',
	Component: () => <ArchivingComponent {...props}/>,
	navigation: [
		{
			name: 'Archiving',
			url: '/archiving',
			isExpanded: true,
			links: [
				{
					name: 'Rules',
					url: '/archiving/rules',
					key: 'rules'
				},
				{
					name: 'Jobs',
					url: '/archiving/jobs',
					key: 'jobs'
				},
				{
					name: 'Blob destination',
					url: '/archiving/blob-destination',
					key: 'blob-destination'
				},
				{
					name: 'System users',
					url: '/archiving/system-users',
					key: 'system-users'
				}
			]
		}
	]
});

export default ArchivingModule;
