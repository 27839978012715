import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Dropdown, TextField} from '@fluentui/react';
import {Status, StatusOptions, getvaluesKey} from '../../utils';

class IndexingSettings extends Component {
	render() {
		const {values} = this.props;

		return (
			<>
				<Dropdown
					label="Image analytics"
					options={StatusOptions}
					selectedKey={getvaluesKey(values, 'Image analytics')}
					onChange={(e, v) => this.onChangevalues('Image analytics', v.key)}
				/>
				<Dropdown
					label="Text analytics"
					options={StatusOptions}
					selectedKey={getvaluesKey(values, 'Text analytics')}
					onChange={(e, v) => this.onChangevalues('Text analytics', v.key)}
				/>
				{values.enable.includes('Text analytics') && (
					<TextField
						label="Character limitation for Text analytics"
						defaultValue={1000}
						value={values.textAnalysisMaxSize}
						name="textAnalysisMaxSize"
						onChange={e => this.onChangeValue(e)}
						type="number"
					/>
				)}
				<Dropdown
					label="Generate preview"
					options={StatusOptions}
					selectedKey={getvaluesKey(values, 'Generate preview')}
					onChange={(e, v) => this.onChangevalues('Generate preview', v.key)}
				/>
				<Dropdown
					label="Video analytics"
					options={StatusOptions}
					selectedKey={getvaluesKey(values, 'Video analytics')}
					onChange={(e, v) => this.onChangevalues('Video analytics', v.key)}
				/>
				{values.enable.includes('Video analytics') && (
					<TextField
						label="Max Video indexing duration (sec.)"
						defaultValue={60}
						value={values.maxVideoIndexingDurationSeconds}
						name="maxVideoIndexingDurationSeconds"
						onChange={e => this.onChangeValue(e)}
						type="number"
					/>
				)}
			</>
		);
	}

	onChangeValue(e) {
		const {values, onChange} = this.props;

		const newValue = {
		 ...values,
		 [e.target.name]: e.target.value
		};

		onChange('indexingProperties', newValue);
	}

	onChangevalues(value, status) {
		const {values, onChange} = this.props;

		let disabled = values.disable;

		let enabled = values.enable;

		if (status === Status.disabled) {
			disabled = values.disable.concat(value);
			enabled = values.enable.filter(a => a !== value);
		}

		if (status === Status.enabled) {
			enabled = values.enable.concat(value);
			disabled = values.disable.filter(a => a !== value);
		}

		if (status === Status.default) {
			enabled = values.enable.filter(a => a !== value);
			disabled = values.disable.filter(a => a !== value);
		}

		onChange('indexingProperties', {disable: disabled, enable: enabled});
	}
}

IndexingSettings.propTypes = {
	values: PropTypes.object,
	onChange: PropTypes.func
};

export default withRouter(IndexingSettings);
