import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import {
	mergeStyleSets, TextField, PrimaryButton, DefaultButton, Spinner, Dialog, DialogFooter, DialogType
} from '@fluentui/react';

const classNames = mergeStyleSets({
	spinnerWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		position: 'fixed',
		top: 0,
		left: 0,
		background: 'rgba(256, 256, 256, 0.5)',
		zIndex: 1000
	},
	inputsContainer: {
		display: 'flex',
		flexDirection: 'column',
		'& > *:not(:last-child)': {
			marginBottom: '16px'
		}
	},
	actionsContainer: {
		'& div': {
			display: 'flex',
			justifyContent: 'flex-end'
		}
	}
});

class ModalBlobDestinationForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			values: {},
			errors: {},
			touched: {},
			isValidForm: true,
			isLoading: false
		};

		this.onChangeField = this.onChangeField.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.validateForm = this.validateForm.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		const {values} = this.state;

		if (prevState.values !== values) {
			this.validateForm();
		}
	}

	render() {
		const {closePopup, isPopupOpen} = this.props;
		const {
			values, isLoading, isValidForm, errors, touched
		} = this.state;

		return (
			<Dialog
				hidden={!isPopupOpen}
				dialogContentProps={{
					type: DialogType.normal,
					title: 'Add new blob destination',
					closeButtonAriaLabel: 'Close'
				}}
			>
				{isLoading && (<div className={classNames.spinnerWrapper}><Spinner/></div>)}

				<TextField
					label="Blob destination name"
					value={values.name}
					name="name"
					onChange={this.onChangeField}
					errorMessage={touched.name !== undefined && errors.name}
				/>

				<TextField
					label="Connection string"
					value={values.connectionString}
					name="connectionString"
					onChange={this.onChangeField}
					errorMessage={touched.connectionString !== undefined && errors.connectionString}
				/>

				<TextField
					label="Container name"
					value={values.containerName}
					name="containerName"
					onChange={this.onChangeField}
					errorMessage={touched.containerName !== undefined && errors.containerName}
				/>

				<DialogFooter className={classNames.actionsContainer}>
					<PrimaryButton
						text="Save"
						iconProps={{iconName: 'CheckMark'}}
						onClick={this.submitForm}
						disabled={isLoading || !isValidForm}
					/>
					<DefaultButton
						text="Cancel"
						onClick={closePopup}
					/>
				</DialogFooter>
			</Dialog>
		);
	}

	onChangeField(e) {
		const {values, touched} = this.state;
		const newValues = {...values, [e.target.name]: e.target.value};

		this.setState({
			values: newValues,
			touched: {...touched, [e.target.name]: true}
		});
	}

	validateForm() {
		const {values} = this.state;

		let errors = {};

		function checkRequired(value) {
			if (typeof value === 'boolean') {
				return null;
			}

			const isValid = (value && value.length);

			return isValid ? null : 'Required field';
		}

		const getErrorMessage = {
			name: checkRequired,
			connectionString: checkRequired,
			containerName: checkRequired
		};

		const isValidForm = Object.keys(getErrorMessage).reduce((isValid, fieldName) => {
			const error = getErrorMessage[fieldName] && getErrorMessage[fieldName](values[fieldName]);

			if (error !== null) {
				errors = {
					...errors,
					[fieldName]: error
				};
			}

			return isValid && !error;
		}, true);

		this.setState({
			isValidForm,
			errors
		});
	}

	async submitForm() {
		const {api, closePopup} = this.props;
		const {values} = this.state;

		this.setState({isLoading: true});

		await api.post('/api/blobAuths/', values);

		this.setState({
			values: {},
			errors: {},
			touched: {},
			isValidForm: true,
			isLoading: false
		});
		closePopup();
	}
}

ModalBlobDestinationForm.propTypes = {
	api: PropTypes.func,
	isPopupOpen: PropTypes.bool,
	closePopup: PropTypes.func
};

export default withRouter(ModalBlobDestinationForm);
