const style = {
	root: {
		minHeight: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column',
		background: '#F3F2F1'
	},
	content: {
		flex: '1 0 0%',
		display: 'flex',
		width: '100%',
		background: '#F3F2F1'
	}
};

export default style;
