import React from 'react';
import PropTypes from 'prop-types';
import {
	DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton
} from '@fluentui/react';
import {useId, useBoolean} from '@fluentui/react-hooks';

import './styles.scss';

const cancelIcon = {iconName: 'Cancel'};
const dialogContentProps = {
	type: DialogType.normal,
	title: 'Cancel Job',
	closeButtonAriaLabel: 'Close',
	subText: 'Do you want to cancel this job?'
};
const dialogStyles = {main: {maxWidth: 450}};

const CancelButton = ({api, jobId, doRefresh, onError}) => {
	const labelId = useId('dialogLabel');
	const subTextId = useId('subTextLabel');
	const [hideDialog, {toggle: toggleHideDialog}] = useBoolean(true);

	const modalProps = {
		titleAriaId: labelId,
		subtitleAriaId: subTextId,
		isBlocking: false,
		styles: dialogStyles
	};

	async function cancelJob() {
		onError(null);

		// cancel job
		try {
			await api.put(`/api/migrationJobs/${jobId}/cancel`);
		} catch (ex) {
			onError(ex);
		}

		// refresh list
		try {
			await doRefresh(true);
		} catch (ex) {
			onError(ex);
		}

		toggleHideDialog();
	}

	return (
		<>
			<DefaultButton text="Cancel Job" iconProps={cancelIcon} onClick={toggleHideDialog} styles={{root: 'cancel-btn'}}/>

			<Dialog
				hidden={hideDialog}
				onDismiss={toggleHideDialog}
				dialogContentProps={dialogContentProps}
				modalProps={modalProps}
			>
				<DialogFooter>
					<PrimaryButton onClick={cancelJob} text="Cancel" />
					<DefaultButton onClick={toggleHideDialog} text="Close" />
				</DialogFooter>
			</Dialog>
		</>
	);
};

CancelButton.propTypes = {
	api: PropTypes.func.isRequired,
	jobId: PropTypes.string.isRequired,
	doRefresh: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired
};

export default CancelButton;
