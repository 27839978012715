import React from 'react';
import PropTypes from 'prop-types';
import {useStore} from 'effector-react';
import './page-size-selector.scss';

import {Dropdown} from '@fluentui/react/lib/Dropdown';

import {
	itemsPerPage,
	setItemsPerPage,
	setCurrentPage,
	setUserFilters,
	setSkip
} from '../../../store';

const PageSizeSelector = ({
	pageSizeOptions
}) => {
	const itemsPerPageNum = useStore(itemsPerPage);

	function changeItemsPerPageCount(event, option) {
		if (!event) {
			return;
		}

		if (option && option.key) {
			setItemsPerPage(+option.key);
			setCurrentPage(0);
			setSkip(0);

			setUserFilters(true);
		}
	}

	return (
		<div className="page-size-selector">
			<span className="page-size-selector__label">Show items on page</span>
			<Dropdown
				selectedKey={itemsPerPageNum}
				className="page-size-selector__selector"
				onChange={changeItemsPerPageCount}
				options={pageSizeOptions}
			/>
		</div >
	);
};

PageSizeSelector.defaultProps = {
	pageSizeOptions: [
		{key: 1, text: '1'},
		{key: 2, text: '2'},
		{key: 3, text: '3'},
		{key: 10, text: '10'},
		{key: 50, text: '50'},
		{key: 100, text: '100'}
	]
};

PageSizeSelector.propTypes = {
	pageSizeOptions: PropTypes.array
};

export default PageSizeSelector;
