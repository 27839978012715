import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ThemeProvider} from 'react-jss';
import {BrowserRouter} from 'react-router-dom';
import {withAuth} from './auth';

import api from './api';

import theme from './theme';
import Layout from './components/layout';
import AccessDenied from './components/access-denied';

class CommonAdminComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			api: api(this.navigateToError),
			auth: props.auth,
			hasError: false
		};
	}

	render() {
		const {render: renderProps} = this.props;
		const {hasError} = this.state;

		return (
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					{hasError ? (<AccessDenied {...this.state}/>) : (
						<Layout {...this.state}>
							{renderProps && renderProps(this.state)}
						</Layout>
					)}
				</BrowserRouter>
			</ThemeProvider>
		);
	}

	navigateToError = () => {
		this.setState({
			hasError: true
		});
	}
}

CommonAdminComponent.propTypes = {
	render: PropTypes.func,
	auth: PropTypes.object.isRequired
};

export default withAuth(CommonAdminComponent);
