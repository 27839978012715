import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {
	PrimaryButton, IconButton, SearchBox, DetailsList, DetailsListLayoutMode, SelectionMode, mergeStyleSets, Dialog, DialogFooter, ColumnActionsMode, DefaultButton
} from '@fluentui/react';

const classNames = mergeStyleSets({
	controlWrapper: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	exampleToggle: {
		display: 'inline-block',
		marginBottom: '10px',
		marginRight: '30px'
	},
	selectionDetails: {
		marginBottom: '20px'
	},
	body: {
		flex: 3,
		maxWidth: '85%',
		padding: '2em 2em 3em',
		boxSizing: 'border-box',
		background: '#f3f2f1'
	},
	container: {
		padding: 32,
		background: '#fff',
		boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
		borderRadius: 2,
		maxWidth: 1102,
		boxSizing: 'border-box'
	},
	searchBox: {
		flex: '1 0 0'
	},
	buttonWrapper: {
		marginBottom: 32,
		display: 'flex',
		justifyContent: 'flex-end',
		maxWidth: 1102,
		boxSizing: 'border-box'
	},
	dropdown: {
		display: 'flex',
		marginLeft: 24,
		marginRight: 56
	},
	actionColumns: {
		display: 'flex',
		alignItems: 'center',
		padding: 0
	},
	title: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '28px',
		marginBottom: 24
	},
	addButton: {
		marginLeft: '1em'
	}
});

const refreshIcon = {iconName: 'refresh'};

function getKey(item) {
	return item.id;
}

function copyAndSort(items, columnKey, isSortedDescending) {
	const key = columnKey;

	return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function AddButton() {
	const history = useHistory();

	function handleClick() {
		history.push('/archiving/blob-destination/add');
	}

	return (
		<PrimaryButton
			className={classNames.addButton}
			iconProps={{iconName: 'CirclePlus'}}
			text="Add new blob destination"
			onClick={handleClick}
		/>
	);
}

function MoreButton({id, hasDependentJobs = false, deleteRow}) {
	const history = useHistory();
	const actions = [
		{key: 'edit', text: 'Edit', onClick: () => history.push(`/archiving/blob-destination/${id}`), iconProps: {iconName: 'PageEdit'}},
		{
			key: 'delete', disabled: hasDependentJobs, text: 'Delete', onClick: () => deleteRow(id), iconProps: {iconName: 'Delete'}
		},
		{
			key: 'jobs', disabled: !hasDependentJobs, text: 'Dependent job list', onClick: () => history.push(`/archiving/blob-destination-rules/${id}`), iconProps: {iconName: 'BulletedList'}
		}
	];

	return (
		<IconButton
			menuProps={{items: actions}}
			styles={{}}
			iconProps={{iconName: 'more'}}
			title="Actions"
			ariaLabel="Actions"
			onRenderMenuIcon={() => false}
		/>
	);
}

MoreButton.propTypes = {
	id: PropTypes.string.isRequired,
	deleteRow: PropTypes.func.isRequired,
	hasDependentJobs: PropTypes.bool
};

class BlobDesinationList extends Component {
	constructor(props) {
		super(props);

		const columns = [
			{
				key: 'column1',
				name: 'Name',
				fieldName: 'name',
				isRowHeader: true,
				isResizable: true,
				isSorted: false,
				isSortedDescending: false,
				sortAscendingAriaLabel: 'Sorted A to Z',
				sortDescendingAriaLabel: 'Sorted Z to A',
				onColumnClick: this.onColumnClick,
				data: 'string',
				isPadded: true,
				isConfirmOpen: false
			},
			{
				key: 'column3',
				name: '',
				fieldName: 'space',
				isResizable: false,
				isCollapsible: false,
				data: 'boolean',
				minWidth: 25,
				maxWidth: 25,
				className: classNames.actionColumns,
				columnActionsMode: ColumnActionsMode.disabled,
				onRender: item => (<MoreButton {...item} deleteRow={this.onRowDelete}/>)
			}
		];

		const {items, updatedDate} = props;

		this.state = {
			items,
			// eslint-disable-next-line
			updatedDate,
			columns,
			searchText: '',
			dialogText: ''
		};

		this.onClose = this.onClose.bind(this);
		this.onConfirm = this.onConfirm.bind(this);
	}

	render() {
		const {updateData} = this.props;
		const {
			columns, items, searchText, isConfirmOpen, dialogText
		} = this.state;

		return (
			<div className={classNames.body}>
				<h1 className={classNames.title}>Blob destination list</h1>
				<div className={classNames.buttonWrapper}>
					<DefaultButton text="Refresh" iconProps={refreshIcon} onClick={updateData}/>
					<AddButton />
				</div>
				<div className={classNames.container}>
					<div className={classNames.controlWrapper}>
						<SearchBox
							placeholder="Filter by name"
							onChange={this.onChangeText}
							value={searchText}
							className={classNames.searchBox}
						/>
					</div>
					<DetailsList
						items={items}
						columns={columns}
						selectionMode={SelectionMode.none}
						getKey={getKey}
						setKey="none"
						layoutMode={DetailsListLayoutMode.justified}
						isHeaderVisible
					/>
				</div>

				<Dialog
					hidden={!isConfirmOpen}
					onDismiss={this.onClose}
					dialogContentProps={{
						title: 'Delete destination blob',
						closeButtonAriaLabel: 'Close',
						subText: dialogText
					}}
				>
					<DialogFooter>
						<PrimaryButton text="Delete" onClick={this.onConfirm}/>
						<DefaultButton text="Close" onClick={this.onClose}/>
					</DialogFooter>
				</Dialog>
			</div>
		);
	}

	onChangeText = (ev, text) => {
		const {items} = this.props;

		this.setState({
			items: text ? items.filter(i => i.name.toLowerCase().indexOf(text) > -1) : items,
			searchText: text
		});
	};

	onColumnClick = (ev, column) => {
		const {columns, items} = this.state;
		const newColumns = columns.slice();
		const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

		newColumns.forEach(newCol => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});

		const newItems = copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);

		this.setState({
			columns: newColumns,
			items: newItems
		});
	};

	onRowDelete = deleteRow => {
		const {items} = this.props;
		const deleteItem = items.find(item => item.id === deleteRow);

		this.setState({
			isConfirmOpen: true,
			deleteItem,
			dialogText: `Are you sure you want to delete the destination blob ${deleteItem && deleteItem.name}?`
		});
	}

	onConfirm() {
		const {api, updateData} = this.props;
		const {deleteItem} = this.state;

		api.delete(`/api/blobAuths/${deleteItem.id}`)
			.then(updateData)
			.then(this.onClose)
			.catch(() => {
				this.setState({
					isConfirmOpen: true,
					deleteItem: null,
					dialogText: `Error while delete destination blob ${deleteItem && deleteItem.name}`
				});
			});
	}

	onClose() {
		this.setState({
			isConfirmOpen: false,
			deleteItem: null,
			dialogText: ''
		});
	}

	static getDerivedStateFromProps(props, state) {
		if (props.updatedDate !== state.updatedDate) {
			const {items: newItems, updatedDate} = props;
			const {columns, searchText} = state;
			const sortedColumn = columns.find(col => col.isSorted || col.isSortedDescending);

			let items = searchText ? newItems.filter(i => i.title.toLowerCase().indexOf(searchText) > -1) : newItems;

			if (sortedColumn) {
				const {fieldName, isSortedDescending} = sortedColumn;

				items = copyAndSort(items, fieldName, isSortedDescending);
			}

			return {
				updatedDate,
				items
			};
		}

		return null;
	}
}

BlobDesinationList.propTypes = {
	items: PropTypes.array,
	api: PropTypes.func,
	updateData: PropTypes.func,
	updatedDate: PropTypes.number
};

export default BlobDesinationList;
