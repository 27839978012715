import {createEvent, createStore} from 'effector';

const jobList = createStore([]);
const setJobList = createEvent();

jobList.on(setJobList, (_, status) => status);

const jobListTotalCount = createStore(0);
const setJobListTotalCount = createEvent();

jobListTotalCount.on(setJobListTotalCount, (_, status) => status);

const itemsPerPage = createStore(10);
const setItemsPerPage = createEvent();

itemsPerPage.on(setItemsPerPage, (_, status) => status);

const currentPage = createStore(0);
const setCurrentPage = createEvent();

currentPage.on(setCurrentPage, (_, status) => status);

const rulesDefinitions = createStore(null);
const setRulesDefinitions = createEvent();

rulesDefinitions.on(setRulesDefinitions, (_, status) => status);

const userFilters = createStore(false);
const setUserFilters = createEvent();

userFilters.on(setUserFilters, (_, status) => status);

const sites = createStore([]);
const setSites = createEvent();
const resetSites = createEvent();

sites.on(setSites, (_, status) => status).reset(resetSites);

const statuses = createStore([]);
const setStatuses = createEvent();
const resetStatuses = createEvent();

statuses.on(setStatuses, (_, status) => status).reset(resetStatuses);

const authTokens = createStore([]);
const setAuthTokens = createEvent();
const resetAuthTokens = createEvent();

authTokens.on(setAuthTokens, (_, status) => status).reset(resetAuthTokens);

const blobAuths = createStore([]);
const setBlobAuths = createEvent();
const resetBlobAuths = createEvent();

blobAuths.on(setBlobAuths, (_, status) => status).reset(resetBlobAuths);

const filterTypes = createStore([]);
const setMigrationTypesFilter = createEvent();
const resetFilterTypes = createEvent();

filterTypes
	.on(setMigrationTypesFilter, (_, status) => status)
	.reset(resetFilterTypes);

const skip = createStore(0);
const setSkip = createEvent();

skip.on(setSkip, (_, status) => status);

const search = createStore('');
const setSearch = createEvent();

search.on(setSearch, (_, status) => status);

const orderBy = createStore('title');
const setOrderBy = createEvent();
const resetOrderBy = createEvent();

orderBy.on(setOrderBy, (_, status) => status).reset(resetOrderBy);

const isAscending = createStore(false);
const setIsAscending = createEvent();
const resetIsAscending = createEvent();

isAscending.on(setIsAscending, (_, status) => status).reset(resetIsAscending);

const isOpenFilterByPanel = createStore(null);
const setIsOpenFilterByPanel = createEvent();

isOpenFilterByPanel.on(setIsOpenFilterByPanel, (_, status) => status);

const lastRunDateRangeStore = createStore(null);
const setLastRunDateRange = createEvent();
const resetLastRunDateRange = createEvent();

lastRunDateRangeStore.on(setLastRunDateRange, (_, status) => status).reset(resetLastRunDateRange);

const isNeverRunStore = createStore(false);
const setIsNeverRun = createEvent();

isNeverRunStore.on(setIsNeverRun, (_, status) => status);

export {
	jobList,
	setJobList,
	jobListTotalCount,
	setJobListTotalCount,
	itemsPerPage,
	setItemsPerPage,
	currentPage,
	setCurrentPage,
	rulesDefinitions,
	setRulesDefinitions,
	userFilters,
	setUserFilters,
	sites,
	setSites,
	resetSites,
	statuses,
	setStatuses,
	resetStatuses,
	filterTypes,
	setMigrationTypesFilter,
	resetFilterTypes,
	authTokens,
	setAuthTokens,
	resetAuthTokens,
	blobAuths,
	setBlobAuths,
	resetBlobAuths,
	skip,
	setSkip,
	search,
	setSearch,
	orderBy,
	setOrderBy,
	isAscending,
	setIsAscending,
	lastRunDateRangeStore,
	setLastRunDateRange,
	resetLastRunDateRange,
	resetOrderBy,
	resetIsAscending,
	isNeverRunStore,
	setIsNeverRun
};
