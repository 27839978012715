import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {
	mergeStyleSets, Text, TextField, PrimaryButton, DefaultButton, Spinner, Toggle, Dialog, DialogFooter, Icon, Callout, Stack, DirectionalHint
} from '@fluentui/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {useBoolean} from '@uifabric/react-hooks';

import FieldsRules from './fields';
import {validateRequired, validateFields, validateFieldsLogic} from './fields/utils';

const classNames = mergeStyleSets({
	body: {
		flex: 3,
		maxWidth: '85%',
		padding: '2em 2em 3em',
		boxSizing: 'border-box',
		background: '#f3f2f1',
		position: 'relative',
		width: '100%',
		minHeight: '100%'
	},
	spinnerWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		position: 'fixed',
		top: 0,
		left: 0,
		background: 'rgba(256, 256, 256, 0.5)',
		zIndex: 1000
	},
	container: {
		padding: 32,
		background: '#fff',
		boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
		borderRadius: 2,
		marginBottom: 32,
		maxWidth: 1102,
		boxSizing: 'border-box'
	},
	title: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '28px',
		marginBottom: 24
	},
	inputsContainer: {
		display: 'flex',
		flexDirection: 'column',
		'& > *:not(:last-child)': {
			marginBottom: '16px'
		}
	},
	actionsContainer: {
		marginTop: 64,
		display: 'flex',
		alignItems: 'center',
		'& > *:first-child': {
			marginRight: 8
		}
	},
	groupContainer: {
		padding: 32,
		background: '#fff',
		boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
		borderRadius: 2,
		marginBottom: 32,
		maxWidth: 1102,
		boxSizing: 'border-box'
	},
	groupTitle: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '28px',
		marginBottom: 24
	},
	info: {
		padding: 16,
		'& strong': {fontWeight: 'bold'}
	},
	label: {
		fontWeight: '600',
		marginBottom: 4
	}
});

const CustomLabel = ({id, label}) => {
	const [isCalloutVisible, {toggle: toggleIsCalloutVisible}] = useBoolean(false);

	return (
		<>
			<Stack horizontal verticalAlign="center" tokens={{childrenGap: 4}}>
				<Text className={classNames.label} id={id}>{label}</Text>
				<Icon
					id="iconButton"
					iconName="Info"
					onClick={toggleIsCalloutVisible}
					styles={{root: {cursor: 'pointer'}}}
				/>
			</Stack>
			{isCalloutVisible && (
				<Callout
					target="#iconButton"
					onDismiss={toggleIsCalloutVisible}
					position
					directionalHint={DirectionalHint.topLeftEdge}
				>
					<div className={classNames.info}>
						<Text block>Field Logic is used to define the way in which the filters will be applied.</Text>
						<br/>
						<Text block>Available operators: <strong>AND OR ( ) N</strong>, where <strong>N</strong> is the number of field listed above:</Text>
						<br/>
						<Text block>Example:</Text>
						<br/>
						<Text block>1 AND 2 = Both Field 1 AND Field 2 will need to be positive for the rule to work</Text>
						<Text block>1 OR 2 = Field 1 OR Field 2 will need to be positive for the rule to work</Text>
						<Text block>(1 AND 2) OR 3 = Both Field 1 AND Field 2 will need to be positive OR field 3 will need to match</Text>
					</div>
				</Callout>
			)}
		</>
	);
};

CustomLabel.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string
};

class RuleForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			options: {},
			values: {
				fields: {1: {fieldOperationId: '', id: '', value: ''}}
			},
			errors: {},
			touched: {},
			isEdit: false,
			id: 'add',
			isLoading: true,
			dialog: {
				isOpen: false,
				text: '',
				title: ''
			}
		};

		this.onChangeField = this.onChangeField.bind(this);
		this.onChangeFieldByKey = this.onChangeFieldByKey.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.goBack = this.goBack.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onConfirm = this.onConfirm.bind(this);
	}

	async componentDidMount() {
		const {api, match: {params: {id}}} = this.props;

		let {values} = this.state;
		const optionsData = await api.get('/api/rules/options');

		if (id !== 'add') {
			const dataForm = await api.get(`/api/rules/${id}`);

			values = dataForm.data;
		}

		this.setState({
			options: optionsData.data.fields,
			isEdit: id !== 'add',
			values,
			id,
			isLoading: false,
			titleCallout: false,
			fieldsCallout: false
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const {values} = this.state;

		if (prevState.values !== values) {
			this.validateForm();
		}
	}

	render() {
		const {
			options, isEdit, values, isLoading, errors, touched, dialog, titleCallout, fieldsCallout
		} = this.state;

		if (isLoading === true) {
			return (
				<div className={classNames.body}>
					<div className={classNames.spinnerWrapper}><Spinner/></div>
				</div>
			);
		}

		return (
			<div className={classNames.body}>
				{isLoading && (
					<div className={classNames.spinnerWrapper}><Spinner/></div>
				)}

				<Text as="h1" block className={classNames.title}>
					{isEdit ? `Edit ${values.title} rule` : 'Add new rule'}
					<Icon
						id="titleCallout"
						iconName="Info"
						onClick={() => this.setState({titleCallout: !titleCallout})}
						styles={{
							root: {
								cursor: 'pointer',
								marginLeft: 5,
								position: 'relative',
								top: 3
							}
						}}
					/>
					{titleCallout && (
						<Callout
							target="#titleCallout"
							onDismiss={() => this.setState({titleCallout: false})}
							directionalHint={DirectionalHint.leftTopEdge}
						>
							<div className={classNames.info}>
								<Text block>Rules are used to selectively choose which files will be archived from Sharepoint.</Text>
								<Text block>When creating a rule it can contain one of more fields allowing very selective filtering.</Text>
								<Text block>They can be either positive filters (has to include) or negative filters (will exclude)</Text>
							</div>
						</Callout>
					)}
				</Text>

				<div className={classNames.container}>
					<TextField
						label="Rule name"
						value={values.title}
						name="title"
						onChange={this.onChangeField}
						errorMessage={touched.title !== undefined && errors.title}
					/>
				</div>

				<div className={classNames.container}>
					<Toggle
						label="Status"
						name="isActive"
						checked={values.isActive}
						onChange={this.onChangeField}
						disabled={values.hasDependentJobs}
						onText="Active"
						offText="Inactive"
					/>
				</div>

				<div className={classNames.container}>
					<Text as="h2" variant="xLarge" block>
						Fields
						<Icon
							id="fieldsCallout"
							iconName="Info"
							onClick={() => this.setState({fieldsCallout: !fieldsCallout})}
							styles={{
								root: {
									cursor: 'pointer',
									marginLeft: 5,
									position: 'relative',
									top: 3
								}
							}}
						/>
						{fieldsCallout && (
							<Callout
								target="#fieldsCallout"
								onDismiss={() => this.setState({fieldsCallout: false})}
								directionalHint={DirectionalHint.topLeftEdge}
							>
								<div className={classNames.info}>
									<Text block><strong>Extensions</strong> - the extension of the files type, example: jpg</Text>
									<Text block><strong>File Kind</strong> - A selection of extensions, example Spreadsheet will be XLSX and XLS</Text>
									<Text block><strong>Last Modified</strong> - A file with a specific date or before or after the date specified</Text>
									<Text block><strong>Last Modified (Relative)</strong> - Similar to Last Modified but is relative to todays date.<br/>So 3 months would be 3 months from today</Text>
									<Text block><strong>Sharepoint List Name</strong> - By default archiving only applies to the standard “Document” Library.<br/>If you want to archive another  document library within the site this will need to be specified here.<br/>Equally, if you select does not equal “Document” it will apply the filter to all libraires apart from “Document”</Text>
									<Text block><strong>Size</strong> - The file size, useful for archiving content over a certain file size, Example Greater than 15Gb</Text>
								</div>
							</Callout>
						)}
					</Text>

					<FieldsRules
						options={options}
						onChange={this.onChangeFieldByKey}
						values={values.fields}
						errors={errors.fields}
						touched={touched.fields}
					/>

					<TextField
						onRenderLabel={labelProps => <CustomLabel {...labelProps} id="fieldsLogic" label="Fields logic"/>}
						value={values.fieldsLogic}
						name="fieldsLogic"
						onChange={this.onChangeField}
						errorMessage={touched.fieldsLogic !== undefined && errors.fieldsLogic}
					/>
				</div>

				<div className={classNames.actionsContainer}>
					<PrimaryButton
						text="Save"
						iconProps={{iconName: 'CheckMark'}}
						onClick={this.submitForm}
						disabled={isLoading}
					/>
					<DefaultButton
						text="Cancel"
						onClick={this.goBack}
					/>
				</div>

				<Dialog
					hidden={!dialog.isOpen}
					onDismiss={this.onClose}
					dialogContentProps={{
						title: dialog.title,
						closeButtonAriaLabel: 'Close',
						subText: dialog.text
					}}
				>
					<DialogFooter>
						<DefaultButton text="Close" onClick={this.onClose}/>
					</DialogFooter>
				</Dialog>
			</div>
		);
	}

	onChangeFieldByKey(key, newValue, touchedValue) {
		const {values, touched} = this.state;
		const newValues = {...values, [key]: newValue};

		this.setState({
			values: newValues,
			touched: {...touched, [key]: touchedValue || true}
		});
	}

	onChangeField(e, value) {
		const fieldName = e.target.name;
		const {values, touched} = this.state;
		const newValues = {...values, [fieldName]: value};

		this.setState({
			values: newValues,
			touched: {...touched, [fieldName]: true}
		});
	}

	onConfirm(title, text) {
		this.setState({dialog: {isOpen: true, text, title}});
	}

	onClose() {
		this.setState({dialog: {isOpen: false, text: '', title: ''}});
	}

	async submitForm() {
		const {api} = this.props;
		const {id, values, isEdit} = this.state;
		const isValidForm = this.validateForm();

		if (isValidForm) {
			this.setState({isLoading: true});

			try {
				if (!isEdit) {
					await api.post('/api/rules/', values);
					this.onConfirm('Succesess message', `The rule "${values.title}" was successfuly created`);
				} else {
					await api.put(`/api/rules/${id}`, values);
					this.onConfirm('Edit rule', `The rule "${values.title}" was successfuly updated`);
				}
			} catch (error) {
				this.onConfirm('Error', error);
			}

			this.setState({isLoading: false});
		} else {
			let fieldsTouched = {};

			Object.keys(values.fields).forEach(index => {
				fieldsTouched = {
					...fieldsTouched,
					[index]: {
						id: true,
						fieldOperationId: true,
						value: true
					}
				};
			});

			this.setState({
				touched: {
					title: true,
					fields: fieldsTouched,
					fieldsLogic: true
				}
			});
		}
	}

	validateForm() {
		const {values} = this.state;

		let errors = {};

		const getErrorMessage = {
			title: validateRequired,
			fields: validateFields,
			fieldsLogic: v => validateFieldsLogic(v, values.fields)
		};

		const isValidForm = Object.keys(getErrorMessage).reduce((isValid, fieldName) => {
			const error = getErrorMessage[fieldName] && getErrorMessage[fieldName](values[fieldName]);

			if (error !== null) {
				errors = {
					...errors,
					[fieldName]: error
				};
			}

			return isValid && !error;
		}, true);

		this.setState({errors});

		return isValidForm;
	}

	goBack() {
		const {history} = this.props;

		history.goBack();
	}
}

RuleForm.propTypes = {
	api: PropTypes.func,
	history: PropTypes.object,
	match: PropTypes.object
};

export default withRouter(RuleForm);
