import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {mergeStyleSets, DayOfWeek, DatePicker} from '@fluentui/react';

const classNames = mergeStyleSets({
	datepicker: {
		width: 208
	}
});

const DayPickerStrings = {
	months: [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	],
	shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
	days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
	shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
	goToToday: 'Go to today',
	prevMonthAriaLabel: 'Go to previous month',
	nextMonthAriaLabel: 'Go to next month',
	prevYearAriaLabel: 'Go to previous year',
	nextYearAriaLabel: 'Go to next year',
	closeButtonAriaLabel: 'Close date picker',
	monthPickerHeaderAriaLabel: '{0}, select to change the year',
	yearPickerHeaderAriaLabel: '{0}, select to change the month',
	isRequiredErrorMessage: 'Required field',
	invalidInputErrorMessage: 'Invalid date format'
};
const firstDayOfWeek = DayOfWeek.Monday;
const desc = 'This field is required. One of the support input formats is year dash month dash day.';

function FieldDatePicker({onChange, value, placeholder, errorMessage}) {
	const [val, setVal] = useState(value ? new Date(value) : '');
	const datePickerRef = useRef(null);
	const onSelectDate = date => {
		if (date) {
			onChange(date ? date.toISOString() : '');
			setVal(date);
		}
	};
	const formatDate = date => {
		if (typeof date !== 'object') {
			return new Date(date);
		}

		const month = DayPickerStrings.shortMonths[date.getMonth()];
		const addZero = num => (num < 10 ? '0' + num : num);

		return `${month} ${addZero(date.getDate())} ${date.getFullYear()} ${addZero(date.getHours())}:${addZero(date.getMinutes())}:${addZero(date.getSeconds())}`;
	};
	const parseDateFromString = dateStr => {
		const parsed = Date.parse(dateStr);

		return new Date(parsed);
	};

	return (
		<DatePicker
			componentRef={datePickerRef}
			className={classNames.datepicker}
			isRequired
			allowTextInput
			textField={{
				styles: {
					fieldGroup: {
						':before': {display: 'none'}
					}
				}
			}}
			formatDate={formatDate}
			parseDateFromString={parseDateFromString}
			onSelectDate={onSelectDate}
			ariaLabel={desc}
			firstDayOfWeek={firstDayOfWeek}
			strings={DayPickerStrings}
			value={val}
			placeholder={placeholder}
			errorMessage={errorMessage}
		/>
	);
}

FieldDatePicker.propTypes = {
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	errorMessage: PropTypes.string
};

export default FieldDatePicker;
