import React from 'react';
import {PropTypes} from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useBoolean} from '@fluentui/react-hooks';
import {
	Callout,
	IconButton,
	CommandBarButton
} from '@fluentui/react';

import NotificationButton from './notifications';
import Config from './config.json';
import Logo from './logo.svg';
import {version} from '../../../../package.json';

const Header = ({classes, api, auth: {signOut}}) => {
	const history = useHistory();
	const logoOnClick = () => history.push('/');
	const buildNumber = process.env.REACT_APP_BUILD_NUMBER;
	const appVersion = buildNumber || version;
	const [isCalloutVisible, {toggle: toggleIsCalloutVisible}] = useBoolean(false);

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<div className={classes.logoWrapper} onClick={logoOnClick}>
					<img className={classes.logo} src={Logo} alt={`${Config.name} Logo`} />
					{Config.name}
				</div>
				<div className={classes.iconsWrapper}>
					<NotificationButton api={api}/>
					<IconButton
						iconProps={{iconName: 'GlobalNavButton'}}
						id="persona"
						className={classes.userBtn}
						onClick={toggleIsCalloutVisible}
						title="Menu"
						ariaLabel="Menu"
					/>
					{isCalloutVisible && (
						<Callout
							role="alertdialog"
							gapSpace={0}
							target="#persona"
							onDismiss={toggleIsCalloutVisible}
							setInitialFocus
						>
							<div className={classes.callout}>
								<CommandBarButton
									iconProps={{iconName: 'SignOut'}}
									text="Sign out"
									className={classes.calloutItem}
									onClick={signOut}
								/>
								<CommandBarButton
									className={classes.calloutItem}
									text={`ver. ${appVersion}`}
									disabled
								/>
							</div>
						</Callout>
					)}
				</div>
			</div>
		</div>
	);
};

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
	api: PropTypes.func.isRequired
};

export default Header;
