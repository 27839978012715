import React from 'react';
import PropTypes from 'prop-types';
import {PrimaryButton, ContextualMenu} from '@fluentui/react';

import './styles.scss';

function RunButton({api, jobId, doRefresh, onError}) {
	const API_URL = '/api/migrationJobs';
	const onJobRun = async () => {
		try {
			await api.post(`${API_URL}/${jobId}/start`);
			doRefresh(true);
		} catch (err) {
			onError(err);
		}
	};

	const onTestRun = async () => {
		try {
			await api.post(`${API_URL}/${jobId}/dryrun`);
			doRefresh(true);
		} catch (err) {
			onError(err);
		}
	};

	const runProps = {
		items: [
			{key: 'run', text: 'Full run', onClick: onJobRun, iconProps: {iconName: 'Play'}},
			{key: 'test', text: 'Test run', onClick: onTestRun, iconProps: {iconName: 'CheckboxComposite'}}
		],
		directionalHintFixed: true
	};

	function getRunMenu(props) {
		return (
			<ContextualMenu
				{...props}
			/>
		);
	}

	return (
		<PrimaryButton
			text="Job Run/Test"
			iconProps={{iconName: 'Play'}}
			menuProps={runProps}
			menuAs={getRunMenu}
			styles={{root: 'run-btn'}}
			persistMenu
		/>
	);
}

RunButton.propTypes = {
	api: PropTypes.func.isRequired,
	jobId: PropTypes.string.isRequired,
	doRefresh: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired
};

export default RunButton;
