import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, TextField} from '@fluentui/react';
import {findHighestUnitIndex, validateNumber} from './utils';

function FieldRelativeDate({onChange, value, placeholder, errorMessage}) {
	const UNITS = [
		{key: 'min', text: 'Minute', multiplier: 60000}, // 60 * 1000
		{key: 'hour', text: 'Hour', multiplier: 3600000}, // 60*60*1000
		{key: 'day', text: 'Day', multiplier: 86400000}, // 24*60*60*1000
		{key: 'week', text: 'Week', multiplier: 604800000}, // 7*24*60*60*1000
		{key: 'month', text: 'Months', multiplier: 2628000000}, // year / 12
		{key: 'year', text: 'Years', multiplier: 31536000000} // 365*24*60*60*1000
	];

	let parsed = parseInt(value, 10);

	const highestUnitIndex = findHighestUnitIndex(UNITS, parsed);
	const target = UNITS[highestUnitIndex];
	const highestUnit = target.key;

	parsed /= target.multiplier;

	const [unit, setUnit] = useState(highestUnit);
	const [num, setNum] = useState(Number.isNaN(parsed) ? 0 : parsed);

	const comboChange = (field, v) => {
		if (field === 'unit') {
			const val = (num * v.multiplier).toString();

			onChange(val);
			setUnit(v.key);
		} else {
			const val = (v * UNITS.find(({key}) => key === unit).multiplier).toString();

			onChange(val);
			setNum(v);
		}
	};

	return (
		<>
			<TextField
				placeholder={placeholder}
				onChange={(e, newValue) => comboChange('num', newValue)}
				value={num}
				style={{width: 106}}
				errorMessage={errorMessage}
				onGetErrorMessage={v => validateNumber(v)}
			/>
			<Dropdown
				styles={{dropdown: {width: 84}}}
				placeholder="Select a unit"
				onChange={(e, newValue) => comboChange('unit', newValue)}
				selectedKey={unit}
				options={UNITS}
			/>
		</>
	);
}

FieldRelativeDate.propTypes = {
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	errorMessage: PropTypes.string,
	value: PropTypes.string // number as a string
};

export default FieldRelativeDate;
