
import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {PrimaryButton, ContextualMenu} from '@fluentui/react';

const AddButton = ({classNames}) => {
	const history = useHistory();
	const addProps = {
		items: useMemo(() => [
			{key: 'sharePoint', text: 'Sharepoint archiving', onClick: () => history.push('/archiving/jobs/add'), iconProps: {iconName: 'TaskAdd'}},
			{key: 'blob', text: 'Blob archiving', onClick: () => history.push('/archiving/jobs/add-blob'), iconProps: {iconName: 'TaskAdd'}}
		], [history]),
		directionalHintFixed: true
	};

	function getAddMenu(props) {
		return (
			<ContextualMenu
				{...props}
			/>
		);
	}

	return (
		<PrimaryButton
			text="Add new job"
			iconProps={{iconName: 'CirclePlus'}}
			menuProps={addProps}
			menuAs={getAddMenu}
			className={classNames.addButton}
			persistMenu
		/>
	);
};

AddButton.propTypes = {
	classNames: PropTypes.object
};

export default AddButton;
