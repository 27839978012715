import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {
	PrimaryButton, IconButton, SearchBox, DetailsList, DetailsListLayoutMode, SelectionMode, mergeStyleSets
} from '@fluentui/react';

function getKey(item) {
	return item.id;
}

const classNames = mergeStyleSets({
	controlWrapper: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	exampleToggle: {
		display: 'inline-block',
		marginBottom: '10px',
		marginRight: '30px'
	},
	selectionDetails: {
		marginBottom: '20px'
	},
	body: {
		flex: 3,
		maxWidth: '85%',
		padding: '2em 2em 3em',
		boxSizing: 'border-box',
		background: '#f3f2f1'
	},
	container: {
		padding: 32,
		background: '#fff',
		boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
		borderRadius: 2,
		maxWidth: 1102,
		boxSizing: 'border-box'
	},
	searchBox: {
		flex: '1 0 0'
	},
	buttonWrapper: {
		marginBottom: 32,
		display: 'flex',
		justifyContent: 'flex-end',
		maxWidth: 1102,
		boxSizing: 'border-box'
	},
	dropdown: {
		display: 'flex',
		marginLeft: 24,
		marginRight: 56
	},
	actionColumns: {
		display: 'flex',
		alignItems: 'center',
		padding: 0
	},
	title: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '28px',
		marginBottom: 24
	}
});

function AddButton() {
	const history = useHistory();

	function handleClick() {
		history.push('/archiving/blob-destination/add');
	}

	return (
		<PrimaryButton
			className={classNames.addButton}
			text="Add new sharepoint sites"
			onClick={handleClick}
		/>
	);
}

function MoreButton({id, deleteRow}) {
	const history = useHistory();
	const actions = [
		{key: 'edit', text: 'Edit', onClick: () => history.push(`/archiving/blob-destination/${id}`), iconProps: {iconName: 'PageEdit'}},
		{key: 'delete', text: 'Delete', onClick: () => deleteRow(id), iconProps: {iconName: 'Delete'}}
	];

	return (
		<IconButton
			menuProps={{items: actions}}
			styles={{}}
			iconProps={{iconName: 'more'}}
			title="Actions"
			ariaLabel="Actions"
			onRenderMenuIcon={() => false}
		/>
	);
}

MoreButton.propTypes = {
	id: PropTypes.string.isRequired,
	deleteRow: PropTypes.func.isRequired
};

function copyAndSort(items, columnKey, isSortedDescending) {
	const key = columnKey;

	return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
class SharepointSitesList extends Component {
	constructor(props) {
		super(props);

		const columns = [
			{
				key: 'column1',
				name: 'Name',
				fieldName: 'name',
				isRowHeader: true,
				isResizable: true,
				isSorted: true,
				isSortedDescending: false,
				sortAscendingAriaLabel: 'Sorted A to Z',
				sortDescendingAriaLabel: 'Sorted Z to A',
				onColumnClick: this.onColumnClick,
				data: 'string',
				isPadded: true
			},
			{
				key: 'column3',
				name: '',
				fieldName: 'space',
				isResizable: false,
				isCollapsible: false,
				data: 'boolean',
				minWidth: 25,
				maxWidth: 25,
				className: classNames.actionColumns,
				onRender: item => (<MoreButton {...item} deleteRow={this.onRowDelete}/>)
			}
		];
		const {items} = props;

		this.state = {
			items,
			columns,
			searchText: ''
		};
	}

	render() {
		const {columns, items, searchText} = this.state;

		return (
			<div className={classNames.body}>
				<h1 className={classNames.title}>Sharepoint sites list</h1>
				<div className={classNames.buttonWrapper}>
					<AddButton />
				</div>
				<div className={classNames.container}>
					<div className={classNames.controlWrapper}>
						<SearchBox
							placeholder="Filter by name"
							onChange={this.onChangeText}
							className={classNames.searchBox}
							value={searchText}
						/>
					</div>
					<DetailsList
						items={items}
						columns={columns}
						selectionMode={SelectionMode.none}
						getKey={getKey}
						setKey="none"
						layoutMode={DetailsListLayoutMode.justified}
						isHeaderVisible
					/>
				</div>
			</div>
		);
	}

	onChangeText = (ev, text) => {
		const {items} = this.props;

		this.setState({
			items: text ? items.filter(i => i.name.toLowerCase().indexOf(text) > -1) : items,
			searchText: text
		});
	};

	onColumnClick = (ev, column) => {
		const {columns, items} = this.state;
		const newColumns = columns.slice();
		const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

		newColumns.forEach(newCol => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});

		const newItems = copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);

		this.setState({
			columns: newColumns,
			items: newItems
		});
	};

	onRowDelete = id => {
		const {items, api, updateItems} = this.props;
		const doDelete = () => {
			const newItems = items.filter(i => i.id !== id);

			this.setState({
				items: [...newItems]
			});

			updateItems(newItems);
		};

		// eslint-disable-next-line
		if (window.confirm('following action will delete the destination blob')) {
			api.delete(`/api/blob-destination/${id}`)
				.then(doDelete)
				// eslint-disable-next-line
				.catch(() => window.alert(`error while deleting id: ${id}`));
		}
	}
}

SharepointSitesList.propTypes = {
	items: PropTypes.array,
	api: PropTypes.func,
	updateItems: PropTypes.func
};

export default SharepointSitesList;
