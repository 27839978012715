import React, {useEffect, useState, useCallback, useMemo} from 'react';
import {
	Panel,
	Checkbox,
	Stack,
	PrimaryButton,
	DefaultButton,
	Separator,
	Text,
	DatePicker,
	DayOfWeek
} from '@fluentui/react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {useStore} from 'effector-react';
import {
	sites,
	authTokens,
	blobAuths,
	statuses,
	rulesDefinitions,
	setStatuses,
	setUserFilters,
	setSites,
	setBlobAuths,
	setAuthTokens,
	setLastRunDateRange,
	lastRunDateRangeStore,
	setIsNeverRun
} from '../../../store';

import './filter-by-column-type.scss';

const columnType = {
	SiteName: 'Site Name',
	Status: 'Status',
	Destination: 'Destination',
	RunAsUser: 'Run as user'
};

const stackTokens = {padding: '20px 0'};
const checkBoxStackTokens = {childrenGap: 10, padding: '0 0 20px'};

function FilterByColumnType(props) {
	const lastRunDateRange = useStore(lastRunDateRangeStore);

	const rulesDefinitionsObj = useStore(rulesDefinitions);
	const sitesArr = useStore(sites);
	const authTokensArr = useStore(authTokens);
	const blobAuthsArr = useStore(blobAuths);
	const statusesArr = useStore(statuses);

	const {column, onDismiss} = props;

	const [title, setTitle] = useState('');
	const [filterByArray, setFilterByArray] = useState([]);
	const [currentPanelType, setCurrentPanelType] = useState();
	const [filtersSnapshot, setFiltersSnapshot] = useState();
	const [startDate, setStartDate] = useState(
		(lastRunDateRange !== null
			&& lastRunDateRange.start
			&& new Date(lastRunDateRange.start))
			|| undefined
	);
	const [endDate, setEndDate] = useState(
		(lastRunDateRange !== null
			&& lastRunDateRange.end
			&& new Date(lastRunDateRange.end))
			|| undefined
	);

	const list = useMemo(() => {
		if (currentPanelType && rulesDefinitionsObj) {
			switch (currentPanelType) {
				case columnType.SiteName:
					return rulesDefinitionsObj.sites;

				case columnType.Status:
					return rulesDefinitionsObj.statuses.map(status => ({
						id: status,
						name: status
					}));

				case columnType.Destination:
					return rulesDefinitionsObj.blobAuths;

				case columnType.RunAsUser:
					return rulesDefinitionsObj.authTokens;
			}
		}

		return [];
	}, [rulesDefinitionsObj, currentPanelType]);

	const onChange = (ev, isChecked) => {
		if (!ev) return;
		const {target} = ev;

		if (isChecked) {
			setFilterByArray(
				_.uniq([
					...filterByArray,
					...list.filter(elem => elem.id.toString() === target.id)
				])
			);
		} else {
			const index = filterByArray.findIndex(
				elem => elem.id.toString() === target.id
			);

			setFilterByArray(filterByArray.filter((elem, i) => i !== index));
		}
	};

	const apply = useCallback(() => {
		if (currentPanelType) {
			switch (currentPanelType) {
				case columnType.SiteName:
					setSites(filterByArray);
					break;
				case columnType.Status:
					setStatuses(filterByArray);
					break;
				case columnType.Destination:
					setBlobAuths(filterByArray);
					break;
				case columnType.RunAsUser:
					setAuthTokens(filterByArray);
					break;
			}
		}

		setUserFilters(true);

		if (onDismiss) onDismiss();
	}, [currentPanelType, onDismiss, filterByArray]);

	const clearAll = () => {
		setFilterByArray([]);
	};

	const uncheckAll = () => {
		setFilterByArray([]);
	};

	const onDismissPanel = () => {
		setFilterByArray([]);
		onDismiss();
	};

	const selectAll = () => {
		if (filterByArray.length === list.length) {
			uncheckAll();
		} else {
			setFilterByArray(list);
		}
	};

	const checked = useCallback(
		elem => filterByArray.some(
			item => item.id.toString() === elem.id.toString()
		),
		[filterByArray]
	);

	const onSelectStartDate = data => {
		setStartDate(data);
	};

	const onSelectEndDate = data => {
		setEndDate(data);
	};

	const applyDateRange = () => {
		let start = '';

		let end = '';

		if (startDate) {
			start = startDate.toISOString();
		}

		if (endDate) {
			end = endDate.toISOString();
		}

		setLastRunDateRange({start, end});
		setIsNeverRun(false);
		onDismissPanel();
	};

	const clearDateRange = () => {
		setStartDate(undefined);
		setEndDate(undefined);
	};

	function isDateRangeBtnDisabled() {
		if (lastRunDateRange !== null) {
			const startDateTemp = startDate === undefined ? undefined : startDate.toISOString();

			const endDateTemp = endDate === undefined ? undefined : endDate.toISOString();

			return (
				startDateTemp === lastRunDateRange.start
				&& endDateTemp === lastRunDateRange.end
			);
		}

		return startDate === undefined && endDate === undefined;
	}

	useEffect(() => {
		setFilterByArray([]);
		setFiltersSnapshot([]);
		switch (currentPanelType) {
			case columnType.SiteName:
				if (sitesArr && sitesArr.length) {
					setFilterByArray(sitesArr);
					setFiltersSnapshot(sitesArr);
				}
				break;
			case columnType.Status:
				if (statusesArr && statusesArr.length) {
					setFilterByArray(statusesArr);
					setFiltersSnapshot(statusesArr);
				}
				break;
			case columnType.Destination:
				if (blobAuthsArr && blobAuthsArr.length) {
					setFilterByArray(blobAuthsArr);
					setFiltersSnapshot(blobAuthsArr);
				}
				break;
			case columnType.RunAsUser:
				if (authTokensArr && authTokensArr.length) {
					setFilterByArray(authTokensArr);
					setFiltersSnapshot(authTokensArr);
				}
				break;
		}
	}, [currentPanelType, sitesArr, statusesArr, blobAuthsArr, authTokensArr]);

	useEffect(() => {
		if (column && column.name) {
			setCurrentPanelType(column.name);
			setTitle('Filter by ' + column.name);
		}
	}, [column]);

	return (
		<Panel
			headerText={title}
			isOpen
			onDismiss={onDismissPanel}
			closeButtonAriaLabel="Close"
			className="panel"
		>
			<Stack tokens={stackTokens}>
				{column.key === 'lastRun' ? (
					<>
						<Stack.Item>
							<Stack tokens={checkBoxStackTokens}>
								<Stack.Item>
									<DatePicker
										label="Range Start"
										firstDayOfWeek={DayOfWeek.Sunday}
										placeholder="Select a date..."
										ariaLabel="Select a date"
										onSelectDate={onSelectStartDate}
										value={startDate || undefined}
									/>
								</Stack.Item>
								<Stack.Item>
									<DatePicker
										label="Range End"
										firstDayOfWeek={DayOfWeek.Sunday}
										placeholder="Select a date..."
										ariaLabel="Select a date"
										onSelectDate={onSelectEndDate}
										value={endDate || undefined}
									/>
								</Stack.Item>
							</Stack>
						</Stack.Item>

						<Stack.Item>
							<Stack horizontal className="panel__actions">
								<Stack.Item>
									<PrimaryButton
										text="Apply"
										onClick={applyDateRange}
										disabled={isDateRangeBtnDisabled()}
									/>
								</Stack.Item>
								<Stack.Item>
									<DefaultButton
										text="Clear"
										onClick={clearDateRange}
										disabled={
											startDate === undefined
											&& endDate === undefined
										}
									/>
								</Stack.Item>
							</Stack>
						</Stack.Item>
					</>
				) : (
					<>
						{list.length > 5 ? (
							<Stack.Item>
								<Stack>
									<Stack.Item>
										<Checkbox
											key="selectAll"
											label="All"
											id="all"
											onChange={selectAll}
											defaultIndeterminate
											indeterminate={
												list.length
													> filterByArray.length
												&& filterByArray.length > 0
											}
											checked={
												list.length
												=== filterByArray.length
											}
										/>
									</Stack.Item>
									<Stack.Item>
										<Separator />
									</Stack.Item>
								</Stack>
							</Stack.Item>
						) : (
							<></>
						)}

						{list.length ? (
							<Stack.Item>
								<Stack tokens={checkBoxStackTokens}>
									{list.map(elem => (
										<Stack.Item key={elem.id.toString()}>
											<Checkbox
												label={elem.name}
												id={elem.id.toString()}
												onChange={onChange}
												checked={checked(elem)}
											/>
										</Stack.Item>
									))}
								</Stack>
							</Stack.Item>
						) : (
							<Text variant="mediumPlus">
								No options for display
							</Text>
						)}

						{list.length ? (
							<Stack.Item>
								<Stack horizontal className="panel__actions">
									<Stack.Item>
										<PrimaryButton
											text="Apply"
											onClick={apply}
											disabled={
												JSON.stringify(
													filterByArray
												)
												=== JSON.stringify(filtersSnapshot)
											}
										/>
									</Stack.Item>
									<Stack.Item>
										<DefaultButton
											text="Clear All"
											onClick={clearAll}
											disabled={
												filterByArray.length === 0
											}
										/>
									</Stack.Item>
								</Stack>
							</Stack.Item>
						) : (
							<></>
						)}
					</>
				)}
			</Stack>
		</Panel>
	);
}

FilterByColumnType.propTypes = {
	column: PropTypes.object,
	onDismiss: PropTypes.func
};

export default FilterByColumnType;
