/* eslint-disable */
import axios from 'axios';
import {getToken} from './auth';
import {STORAGE_KEY_STATE} from './auth/constants';

const createApiInstance = accessDeniedCallback => {
	const axiosApiInstance = axios.create();

	axiosApiInstance.interceptors.request.use(
		config => {
			const accessToken = getToken();

			config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
			config.headers['X-Session-Id'] = window.localStorage.getItem(STORAGE_KEY_STATE);

			return config;
		},

		error => Promise.reject(error)
	);

	axiosApiInstance.interceptors.response.use(
		response => response,
		error => {
			if (error.response.status === 403) {
				accessDeniedCallback();
			}

			return Promise.reject(error);
		}
	);

	return axiosApiInstance;
}



export default createApiInstance;
