import React from 'react';
import {PropTypes} from 'prop-types';
import {PrimaryButton} from '@fluentui/react/lib/Button';
import {Text} from '@fluentui/react/lib/Text';

const style = {
	display: 'flex',
	alignItems: 'center',
	height: '100%',
	justifyContent: 'center',
	flexDirection: 'column'
};

const AccessDenied = ({auth: {signOut}}) => (
	<div style={style}>
		<Text variant="mega">Access Denied</Text>
		<PrimaryButton text="Sign-Out" onClick={signOut} />
	</div>
);

AccessDenied.propTypes = {
	auth: PropTypes.object.isRequired
};

export default AccessDenied;
