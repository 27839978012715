import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Spinner, SpinnerSize} from '@fluentui/react';

export default function dataLoader(HocComponent) {
	const Loader = props => {
		const [isLoading, setIsLoading] = useState(true);
		const [dataLoaded, setDataLoaded] = useState(false);
		const [hasError, setHasError] = useState(false);
		const [data, setData] = useState({});
		const {api} = props;

		useEffect(() => {
			function getData() {
				api.get('/api/sharepointSites')
					.then(res => {
						setData(res.data);
						setIsLoading(false);
						setDataLoaded(true);
						setHasError(false);
					})
					.catch(() => {
						setIsLoading(false);
						setDataLoaded(false);
						setHasError(true);
					});
			}

			if (!dataLoaded && isLoading) {
				getData();
			}

			if (dataLoaded && isLoading) {
				setIsLoading(false);
			}
		}, [dataLoaded, isLoading, api]);

		if (hasError) {
			return (
				<div>Error while loading</div>
			);
		}

		if (isLoading) {
			return (
				<Spinner style={{flex: '1 0 0'}} size={SpinnerSize.large} />
			);
		}

		if (dataLoaded) {
			return (
				<HocComponent
					items={data}
					updateItems={setData}
					{...props}
				/>
			);
		}

		return (
			<div />
		);
	};

	Loader.propTypes = {
		api: PropTypes.func
	};

	return Loader;
}
