/* eslint-disable */
import React, {useState, useEffect} from 'react';
import jwtDecode from 'jwt-decode';
import initApi from '../api';
import {
	STORAGE_KEY_TOKEN,
	STORAGE_KEY_USER,
	STORAGE_KEY_STATE
} from './constants';

const api = initApi(() => {});

async function trackEvent(actionType, token) {
	const sessionId = window.localStorage.getItem(STORAGE_KEY_STATE);
	await api.post('/api/authentication/track', {
		actionType
	}, {
		headers: {
			Authorization: `Bearer ${token}`,
			'X-Session-Id': sessionId
		}
	})
	.catch(e => {
		// ignore errors
		console.log(e);
	});
}

export function getToken() {
	const raw = window.localStorage.getItem(STORAGE_KEY_TOKEN);

	if (!raw) {
		return null;
	}

	const token = jwtDecode(raw);

	if (Math.floor(new Date().getTime() / 1000) >= token.exp) {
		return null;
	}

	return raw;
}

export function withAuth(HocComponent) {
	return function Wrapper(props) {
		const [isAuthenticated, setIsAuthenticated] = useState(false);
		const [isLoading, setIsLoading] = useState(true);

		function signOut() {
			const token = getToken();

			trackEvent('SignOut', token)
				.then(() => {
					window.localStorage.removeItem(STORAGE_KEY_TOKEN);
					window.localStorage.removeItem(STORAGE_KEY_USER);

					window.location.href = '/';
				});
		}

		const authObj = {
			signOut
		};

		useEffect(() => {
			async function getData() {
				const {data} = await api.get('/api/token/request');

				window.localStorage.setItem(STORAGE_KEY_STATE, data.state);

				window.location = data.uri;
			}

			async function createToken() {
				const query = (new URLSearchParams(window.location.search));
				const code = query.get('code');
				const state = query.get('state');
				const {data} = await api.post('/api/token', {
					code,
					state
				});

				const {accessToken, user} = data;

				window.localStorage.setItem(STORAGE_KEY_TOKEN, accessToken);
				window.localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(user));

				await trackEvent('SignIn', accessToken);

				window.location.href = '/';
			}

			if (window.location.pathname === '/sign-in-callback') {
				createToken();
			} else {
				const token = getToken();

				if (token) {
					setIsAuthenticated(true);
					setIsLoading(false);
				} else {
					getData();
				}
			}
		}, []);

		if (isLoading) {
			return (
				<div>Loading...</div>
			);
		}

		if (isAuthenticated) {
			return (
				<HocComponent
					auth={authObj}
					{...props}
				/>
			);
		}

		return (
			<div>Hello!</div>
		);
	};

	// return class extends Component {
	// 	constructor(props) {
	// 		super(props);

	// 		this.state = {
	// 			isAuthenticated: false,
	// 			user: {},
	// 			renewIframe: false,
	// 			hasError: false,
	// 			errorMessage: null
	// 		};
	// 	}

	// 	componentWillMount() {
	// 	}

	// 	onSignIn() {
	// 	}

	// 	onSignOut() {
	// 	}

	// 	render() {
	// 		if (this.state.renewIframe) {
	// 			return <div>hidden renew iframe - not visible</div>;
	// 		}

	// 		if (this.state.isAuthenticated) {
	// 			return (
	// 				<HocComponent auth={this.state} onSignIn={() => this.onSignIn()} onSignOut={() => this.onSignOut() } {...this.props} />);
	// 		}

	// 		if (this.state.hasError) {
	// 			return <div>{this.state.errorMessage}</div>;
	// 		}

	// 		return <div>Login in progress...</div>;
	// 	}
	// };
}
