const styles = theme => ({
	eventsButton: {
		minHeight: '40px',
		minWidth: '42px',
		padding: '0 4px',
		position: 'relative',
		'&:hover': {
			backgroundColor: theme.semanticColors.primaryButtonBackground,
			borderColor: theme.semanticColors.primaryButtonBackground
		},
		'& .ms-Button-flexContainer': {
			'&:hover': {
				backgroundColor: theme.semanticColors.primaryButtonBackgroundHovered
			},
			borderRadius: '50%',
			backgroundColor: theme.palette.blue,
			width: '32px',
			height: '32px',
			padding: 0
		}
	},
	listItem: {
		display: 'block',
		minWidth: '100%',
		boxSizing: 'border-box',
		cursor: 'pointer',
		padding: '16px',
		borderBottom: '1px solid #f3f2f1',
		transition: ['background'],
		transitionDuration: 300,

		'&:hover': {
			textDecoration: 'none',
			background: 'rgba(0, 120, 212, 0.1)'
		}
	},
	listItemNew: {
		background: 'rgba(0, 120, 212, 0.2)',
		borderBottomColor: 'rgba(0, 120, 212, 0.25)'
	},
	entity: {
		color: theme.semanticColors.primaryButtonBackground
	},
	titleContainer: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	jobIcon: {
		marginRight: '8px',
		'&.Clock, &.Sync': {
			color: '#FFB900'
		},
		'&.Completed': {
			color: '#107C10'
		},
		'&.ErrorBadge': {
			color: '#E81123'
		}
	},
	completedJobsCount: {
		position: 'absolute',
		cursor: 'pointer',
		color: 'white',
		backgroundColor: '#ff4343',
		borderRadius: '3px',
		fontSize: '12px',
		lineHeight: '12px',
		padding: '2px 5px',
		top: '2px'
	},
	recentDownloads: {
		display: 'block',
		textAlign: 'center',
		margin: '16px'
	},
	calloutBody: {
		maxHeight: '444px',
		overflow: 'auto'
	},

	notificationItem: {
		display: 'block',
		boxShadow: theme.effects.elevation8,
		padding: theme.spacing.m,
		marginBottom: 32,
		position: 'relative',
		minWidth: '100%',
		boxSizing: 'border-box',
		transition: ['background'],
		transitionDuration: 300,

		'&:hover': {
			textDecoration: 'none',
			background: 'rgba(0, 120, 212, 0.1)'
		}
	},
	notificationItemNew: {
		background: 'rgba(0, 120, 212, 0.2)',
		borderBottomColor: 'rgba(0, 120, 212, 0.25)'
	},
	allNotificationsContainer: {
		padding: '32px 0 0'
	},
	emptyContainer: {
		padding: '64px 0 0'
	},
	paginationContainer: {
		margin: '10px 0',

		'& .ms-Pagination-container > div:first-child': {
			display: 'flex'
		}
	}
});

export default styles;
