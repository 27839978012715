import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import Header from '../header';

class Layout extends Component {
	render() {
		const {classes, children, auth, api} = this.props;

		return (
			<div className={classes.root}>
				<Header auth={auth} api={api}/>
				<div className={classes.content}>
					{children}
				</div>
			</div>
		);
	}
}

Layout.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf([PropTypes.node])]),
	auth: PropTypes.object.isRequired,
	api: PropTypes.func.isRequired
};

export default Layout;
