import React, {useEffect, useState} from 'react';
import {PropTypes} from 'prop-types';
import {
	Stack, Text, Icon, ProgressIndicator, Spinner, SpinnerSize, Link, Panel, PanelType,
	PrimaryButton, Callout, DirectionalHint, List, ActionButton
} from '@fluentui/react';
import {Pagination} from '@uifabric/experiments/lib/Pagination';
import {
	getAllNotifications, markAsViewed, getNotificationsWithIntervalFetch, getJobStatusIcon, formatSize, formatDate
} from './utils';
import withStyles from '../../../utils/with-styles';
import styles from './styles';

const NOTIFICATIONS_PER_PAGE = 5;

const Title = ({classes, jobTitle = '', isDryRun = false, status = ''}) => (
	<div className={classes.titleContainer}>
		<Icon
			iconName={getJobStatusIcon(status)}
			className={`${classes.jobIcon} ${getJobStatusIcon(status)}`}
		/>
		<Text>{jobTitle + (isDryRun ? ' [test]' : '')}</Text>
		<Icon
			iconName="NavigateExternalInline"
		/>
	</div>
);

Title.propTypes = {
	classes: PropTypes.object.isRequired,
	jobTitle: PropTypes.string,
	isDryRun: PropTypes.bool,
	status: PropTypes.string
};

const Description = ({
	isDryRun = false, processedFiles, totalFiles, processedSize, totalSize, end = '', status
}) => {
	let text = 'No files were affected';
	const isFinished = status === 'Success' || status === 'Error';
	const text2 = isFinished ? `Ended ${formatDate(end)}` : status;

	if (status === 'Enqueued' || status === 'Pending') {
		text = 'Waiting';
	} else if (isDryRun) {
		text = totalFiles > 0
			? `${totalFiles} job${totalFiles > 1 ? 's' : ''} ${formatSize(totalSize)} expected`
			: 'No files will be affected';
	} else if (totalFiles !== 0 && totalSize !== 0) {
		text = totalFiles === processedFiles && totalSize === processedSize
			? `${totalFiles} job${totalFiles > 1 ? 's' : ''} (${formatSize(processedSize)}) completed`
			: `${processedFiles} of ${totalFiles} job${totalFiles > 1 ? 's' : ''} (${formatSize(processedSize)} of ${formatSize(totalSize)}) completed`;
	}

	return (
		<>
			<Text block>{text}</Text>
			<Text block variant="small">{text2}</Text>
		</>
	);
};

Description.propTypes = {
	processedFiles: PropTypes.number.isRequired,
	totalFiles: PropTypes.number.isRequired,
	processedSize: PropTypes.number.isRequired,
	totalSize: PropTypes.number.isRequired,
	end: PropTypes.string,
	isDryRun: PropTypes.bool,
	status: PropTypes.string
};

const getProgressIndicatorParams = ({status, migrationProgress}) => {
	if (status === 'Processing') {
		return {};
	}

	return {
		percentComplete: (status === 'Success' || status === 'Error') ? 1 : migrationProgress / 100
	};
};

const Notifications = ({classes, api}) => {
	const [state, setState] = useState({
		currentView: 'hidden', // hidden, latest, all
		latestItems: [],
		indicator: 0,
		allItems: [],
		totalItems: 0,
		currentOffset: 0,
		itemsLoading: false
	});
	const buttonRef = React.createRef();

	const updateState = newValues => setState(prevState => ({
		...prevState,
		...newValues
	}));
	const toggleView = (target = 'hidden') => {
		// eslint-disable-next-line
		const currentView = target === 'all' ? 'all' : (
			state.currentView === 'hidden' ? 'latest' : 'hidden'
		);

		if (currentView === 'all') {
			getAllNotifications(state.currentOffset, state.currentOffset + NOTIFICATIONS_PER_PAGE, updateState, api);
		}

		return setState(prevState => ({
			...prevState,
			currentView,
			itemsLoading: state.allItems.length === 0
		}));
	};
	const onItemHover = (id, index, targetArr) => {
		const items = [].concat([], state[targetArr]);

		items[index].isViewed = true;

		updateState({
			[targetArr]: items,
			indicator: state.indicator - 1
		});
		markAsViewed(updateState, [id], api);
	};
	const onPageChange = index => {
		const newOffset = NOTIFICATIONS_PER_PAGE * index;

		updateState({itemsLoading: true});
		getAllNotifications(newOffset, NOTIFICATIONS_PER_PAGE, updateState, api);
		updateState({currentOffset: newOffset});
	};
	const openDetailed = () => toggleView('all');
	const dismissPanel = () => toggleView('none');

	const pageCount = Math.ceil(state.totalItems / NOTIFICATIONS_PER_PAGE);

	const renderItem = ({
		id, jobId, jobTitle, isDryRun, processedFiles, totalFiles, processedSize, totalSize, migrationProgress, status, end, isViewed
	}, index) => {
		const fn = isViewed ? () => {} : () => onItemHover(id, index, 'latestItems');
		const title = (
			<Title
				{...{
					classes, jobId, jobTitle, isDryRun, status
				}}
			/>
		);
		const description = (
			<Description
				{...{
					isDryRun, processedFiles, totalFiles, processedSize, totalSize, end, status
				}}
			/>
		);

		return (
			<Link
				onMouseEnter={fn}
				className={`${classes.listItem} ${!isViewed && classes.listItemNew}`}
				href={`/archiving/job-runs/${jobId}/${id}`}
			>
				<ProgressIndicator
					label={title}
					description={description}
					{...getProgressIndicatorParams({status, migrationProgress})}
				/>
			</Link>
		);
	};

	const renderPagination = () => (
		pageCount > 1 && (
			<div className={classes.paginationContainer}>
				<Pagination
					format="buttons"
					firstPageAriaLabel="First page"
					lastPageAriaLabel="Last page"
					previousPageAriaLabel="Previous page"
					nextPageAriaLabel="Next page"
					pageAriaLabel="Page"
					pageCount={pageCount}
					selectedPageIndex={Math.ceil(state.currentOffset / NOTIFICATIONS_PER_PAGE)}
					onPageChange={onPageChange}
				/>
			</div>
		)
	);

	// on mount, should run once:
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => getNotificationsWithIntervalFetch(updateState, api), []);

	return (
		<>
			<div ref={buttonRef} style={{display: 'flex'}}>
				<PrimaryButton
					iconProps={{iconName: 'Ringer'}}
					className={classes.eventsButton}
					onClick={toggleView}
				/>
				{state.indicator > 0 && <Text className={classes.completedJobsCount} onClick={toggleView}>{state.indicator}</Text>}
			</div>
			{state.currentView === 'latest' && (
				<Callout
					target={buttonRef}
					isBeakVisible={false}
					coverTarget={false}
					gapSpace={0}
					onDismiss={toggleView}
					directionalHint={DirectionalHint.bottomRightEdge}
					setInitialFocus
					calloutWidth={300}
					role="dialog"
				>
					<div className={classes.calloutBody}>
						{state.latestItems.length < 1 ? <Text className={classes.recentDownloads}>No notifications</Text> : (
							<List
								items={state.latestItems}
								onRenderCell={renderItem}
								className={classes.listContainer}
							/>
						)}
					</div>
					<ActionButton
						text="View all notifications"
						iconProps={{iconName: 'FabricFolder'}}
						onClick={openDetailed}
					/>
				</Callout>
			)}
			<Panel
				isOpen={state.currentView === 'all'}
				onDismiss={dismissPanel}
				headerText="All notifications"
				closeButtonAriaLabel="Close"
				onRenderFooterContent={renderPagination}
				type={PanelType.custom}
				customWidth="400px"
				isFooterAtBottom
				className={classes.test}
			>
				{state.itemsLoading === true && (
					<div className={classes.emptyContainer}>
						<Spinner
							size={SpinnerSize.large}
							label="Loading..."
						/>
					</div>
				)}
				{state.itemsLoading === false && (
					<div className={classes.allNotificationsContainer}>
						{state.allItems.length < 1 && (
							<Text className={classes.noDownloads}>
								No notifications found
							</Text>
						)}
						{state.allItems.map(({
							id, jobId, jobTitle, isDryRun, processedFiles, totalFiles, processedSize, totalSize, migrationProgress, status, end, isViewed
						}, index) => (
							<Link
								key={id}
								className={`${classes.notificationItem} ${!isViewed && classes.notificationItemNew}`}
								href={`/archiving/job-runs/${jobId}/${id}`}
								onMouseEnter={isViewed ? () => {} : () => onItemHover(id, index, 'allItems')}
							>
								<Stack tokens={{childrenGap: 8}}>
									<ProgressIndicator
										styles={status === 'Error' ? {progressBar: {backgroundColor: 'rgba(255, 0, 0, 0.75)'}} : {}}
										// eslint-disable-next-line
										label={<Title {...{classes, jobId, jobTitle, isDryRun, status}} />}
										// eslint-disable-next-line
										description={<Description {...{isDryRun, processedFiles, totalFiles, processedSize, totalSize, end, status}} />}
										{...getProgressIndicatorParams({status, migrationProgress})}
									/>
								</Stack>
							</Link>
						))}
					</div>
				)}
			</Panel>
		</>
	);
};

Notifications.propTypes = {
	classes: PropTypes.object.isRequired,
	api: PropTypes.func.isRequired
};

export default withStyles(styles)(Notifications);
