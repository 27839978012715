export function findHighestUnitIndex(array, value) {
	return array.reduce((res, s, index) => {
		if (s.multiplier <= value) {
			const newVal = value / s.multiplier;

			// Unexpected use of '^'  no-bitwise - ?!??!?!?!! Linter get away
			// eslint-disable-next-line
			if ((newVal ^ 0) === newVal) {
				return index;
			}
		}
		return res;
	}, 0);
}

export function validateNumber(value) {
	return /^[0-9]+$/.test(value) ? '' : 'Invalid value';
}

export function validateRequired(value) {
	if (typeof value === 'boolean') {
		return '';
	}

	const isValid = (value && value.length);

	return isValid ? null : 'Required field';
}

export function validateFields(values) {
	if (typeof values !== 'object') {
		return {error: 'Invalid field'};
	}

	const countFields = Object.keys(values).length;

	if (countFields <= 0) {
		return {error: 'Please add at least one field'};
	}

	const errors = {};

	Object.keys(values).forEach(index => {
		const field = values[index];
		const id = validateRequired(field.id);
		const fieldOperationId = validateRequired(field.fieldOperationId);
		const value = validateRequired(field.value);

		if (id || fieldOperationId || value) {
			errors[index] = {id, fieldOperationId, value};
		}
	}, true);

	return Object.keys(errors).length === 0 ? null : errors;
}

function validateLogicField(expression) {
	const pattern = /\(\d\)|\d (?:AND|OR) \d|\d/g;

	// eslint-disable-next-line
	while (true) {
		const replaced = expression.replace(pattern, '1');

		if (replaced === '1') return true;

		if (replaced === expression) return false;

		expression = replaced;
	}
}

function getFieldsNumbers(value) {
	const fieldNumbers = [];

	for (let i = 0; i <= value.length; i += 1) {
		const char = value[i];

		if (parseInt(char, 10)) {
			let fieldNumber = char;

			for (let k = i + 1; k <= value.length; k += 1) {
				if (parseInt(value[k], 10)) {
					fieldNumber += value[k];
				} else {
					i = k;
					break;
				}
			}

			fieldNumbers.push(fieldNumber);
		}
	}

	return fieldNumbers;
}

export function validateFieldsLogic(value, fields) {
	const fieldKeys = fields ? Object.keys(fields) : [];
	const requiredValid = validateRequired(value);

	if (requiredValid !== null) {
		return requiredValid;
	}

	const errors = [];
	const fieldNumbers = getFieldsNumbers(value);
	const invalidItems = [];

	if (fieldNumbers.length <= 0) {
		return 'The field logic should contain at least one field number';
	}

	const inValidFieldNumers = fieldNumbers.filter(f => !fieldKeys.includes(f));

	if (inValidFieldNumers.length > 0) {
		errors.push(`Invalid field numbers: ${inValidFieldNumers.join(', ')}.`);
	}

	const isValid = validateLogicField(value);

	if (!isValid) {
		errors.push('Invalid expression');
	}

	if (invalidItems.length > 0) {
		errors.push(`Invalid expression: ${invalidItems.join(', ')}.`);
	}

	return errors.length ? errors.join('\n') : null;
}
