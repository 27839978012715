import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import {
	mergeStyleSets, Text, TextField, PrimaryButton, DefaultButton, Spinner
} from '@fluentui/react';

const classNames = mergeStyleSets({
	body: {
		flex: 3,
		maxWidth: '85%',
		padding: '2em 2em 3em',
		boxSizing: 'border-box',
		background: '#f3f2f1',
		position: 'relative',
		width: '100%',
		minHeight: '100%'
	},
	spinnerWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		position: 'fixed',
		top: 0,
		left: 0,
		background: 'rgba(256, 256, 256, 0.5)',
		zIndex: 1000
	},
	container: {
		padding: 32,
		background: '#fff',
		boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
		borderRadius: 2,
		marginBottom: 32,
		maxWidth: 1102,
		boxSizing: 'border-box'
	},
	title: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '28px',
		marginBottom: 24
	},
	inputsContainer: {
		display: 'flex',
		flexDirection: 'column',
		'& > *:not(:last-child)': {
			marginBottom: '16px'
		}
	},
	actionsContainer: {
		marginTop: 64,
		display: 'flex',
		alignItems: 'center',
		'& > *:first-child': {
			marginRight: 8
		}
	},
	groupContainer: {
		padding: 32,
		background: '#fff',
		boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
		borderRadius: 2,
		marginBottom: 32,
		maxWidth: 1102,
		boxSizing: 'border-box'
	},
	groupTitle: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '28px',
		marginBottom: 24
	}
});

class JobForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			values: {},
			errors: {},
			totched: {},
			isValidForm: false,
			isEdit: false,
			id: 'add',
			isLoading: true
		};

		this.onChangeField = this.onChangeField.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	async componentDidMount() {
		const {api, match: {params: {id}}} = this.props;

		let values = {};

		if (id !== 'add') {
			const dataForm = await api.get(`/api/sharepointSites/${id}`);

			values = dataForm.data;
		}

		this.setState({
			isEdit: id !== 'add',
			isValidForm: id !== 'add',
			values,
			id,
			isLoading: false
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const {values} = this.state;

		if (prevState.values !== values) {
			this.validateForm();
		}
	}

	render() {
		const {
			isEdit, values, isLoading, isValidForm, errors, totched
		} = this.state;

		if (isLoading === true) {
			return (
				<div className={classNames.body}>
					<div className={classNames.spinnerWrapper}><Spinner/></div>
				</div>
			);
		}

		return (
			<div className={classNames.body}>
				{isLoading && (
					<div className={classNames.spinnerWrapper}><Spinner/></div>
				)}

				<Text as="h1" block className={classNames.title}>
					{isEdit ? `Edit ${values.name} sharepoint sites` : 'Add new sharepoint sites'}
				</Text>

				<div className={classNames.container}>
					<Text as="h2" block className={classNames.title}>Sharepoint sites name</Text>
					<TextField
						value={values.name}
						name="name"
						onChange={this.onChangeField}
						errorMessage={totched.name !== undefined && errors.name}
					/>
				</div>

				<div className={classNames.actionsContainer}>
					<PrimaryButton
						text="Save"
						iconProps={{iconName: 'CheckMark'}}
						onClick={this.submitForm}
						disabled={isLoading || !isValidForm}
					/>
					<DefaultButton
						text="Cancel"
						onClick={this.goBack}
					/>
				</div>
			</div>
		);
	}

	onChangeField(e) {
		const {values, totched} = this.state;
		const newValues = {...values, [e.target.name]: e.target.value};

		this.setState({
			values: newValues,
			totched: {...totched, [e.target.name]: true}
		});
	}

	async submitForm() {
		const {api, history} = this.props;
		const {id, values, isEdit} = this.state;

		this.setState({isLoading: true});

		if (!isEdit) {
			await api.post('/api/sharepointSites/', values);
		} else {
			await api.put(`/api/sharepointSites/${id}`, values);
		}

		history.push('/archiving/blob-destination/');
	}

	validateForm() {
		const {values} = this.state;

		let errors = {};

		function checkRequired(value) {
			if (typeof value === 'boolean') {
				return null;
			}

			const isValid = (value && value.length);

			return isValid ? null : 'Required field';
		}

		const getErrorMessage = {
			name: checkRequired
		};

		const isValidForm = Object.keys(getErrorMessage).reduce((isValid, fieldName) => {
			const error = getErrorMessage[fieldName] && getErrorMessage[fieldName](values[fieldName]);

			if (error !== null) {
				errors = {
					...errors,
					[fieldName]: error
				};
			}

			return isValid && !error;
		}, true);

		this.setState({
			isValidForm,
			errors
		});
	}

	goBack() {
		const {history} = this.props;

		history.goBack();
	}
}

JobForm.propTypes = {
	api: PropTypes.func,
	history: PropTypes.object,
	match: PropTypes.object
};

export default withRouter(JobForm);
