import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Spinner, SpinnerSize} from '@fluentui/react';

export default function dataLoader(HocComponent) {
	const Loader = props => {
		const {api} = props;
		const [isLoading, setIsLoading] = useState(true);
		const [dataLoaded, setDataLoaded] = useState(false);
		const [hasError, setHasError] = useState(false);
		const [data, setData] = useState({});
		const [updatedDate, setUpdatedDate] = useState(0);
		const getData = useCallback(() => {
			api.get('/api/rules')
				.then(res => {
					setData(res.data);
					setUpdatedDate(new Date().getTime());
					setIsLoading(false);
					setDataLoaded(true);
					setHasError(false);
				})
				.catch(() => {
					setIsLoading(false);
					setDataLoaded(false);
					setHasError(true);
				});
		}, [api]);

		useEffect(() => {
			if (!dataLoaded && isLoading) {
				getData();
			}

			if (dataLoaded && isLoading) {
				setIsLoading(false);
			}
		}, [dataLoaded, isLoading, api, getData]);

		if (hasError) {
			return (
				<div>Error while loading</div>
			);
		}

		if (isLoading) {
			return (
				<Spinner style={{flex: '1 0 0'}} size={SpinnerSize.large} />
			);
		}

		if (dataLoaded) {
			return (
				<HocComponent
					items={data}
					updateData={getData}
					updatedDate={updatedDate}
					{...props}
				/>
			);
		}

		return (
			<div />
		);
	};

	Loader.propTypes = {
		api: PropTypes.func
	};

	return Loader;
}
