import React from 'react';
import injectSheet from 'react-jss';

const withStyles = styles => ChildComponent => props => {
	const Component = injectSheet(styles)(ChildComponent);

	return (
		<Component {...props}/>
	);
};

export default withStyles;
