/* eslint-disable */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import {
	mergeStyleSets, Text, TextField, Dropdown, VirtualizedComboBox, PrimaryButton,
	DefaultButton, Spinner, Label, getTheme, ContextualMenuItemType, ChoiceGroup,
	Toggle, Dialog, DialogFooter, Stack, StackItem
} from '@fluentui/react';

import RulesPicker from './rules-picker';
import IndexingSettings from './indexing-settings';
import GroupFields from './group-fields';
import Shedule from './shedule';
import {isValidCron} from '../../../utils/vaildate-cron';
import {
	getOptions, optionsOverwriteFile, archivingBehavior, forceDeleteFile, hasSchedule
} from './options';
import ModalBlobDestinationForm from './modal/addBlobDestination';

const STORAGE_KEY_USER = 'admin.module.auth.user';

const theme = getTheme();
const classNames = mergeStyleSets({
	body: {
		flex: 3,
		maxWidth: '85%',
		padding: '2em 2em 3em',
		boxSizing: 'border-box',
		background: '#f3f2f1',
		position: 'relative',
		width: '100%',
		minHeight: '100%'
	},
	spinnerWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		position: 'fixed',
		top: 0,
		left: 0,
		background: 'rgba(256, 256, 256, 0.5)',
		zIndex: 1000
	},
	container: {
		padding: 32,
		background: '#fff',
		boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
		borderRadius: 2,
		marginBottom: 32,
		maxWidth: 1102,
		boxSizing: 'border-box'
	},
	title: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '28px',
		marginBottom: 24
	},
	inputsContainer: {
		display: 'flex',
		flexDirection: 'column',
		'& > *:not(:last-child)': {
			marginBottom: '16px'
		}
	},
	actionsContainer: {
		marginTop: 64,
		display: 'flex',
		alignItems: 'center',
		'& > *:first-child': {
			marginRight: 8
		}
	},
	btnCommand: {
		width: '100%',
		padding: '0 4px'
	},
	btnCommandFlexContainer: {
		justifyContent: 'space-between'
	},
	btnCommandLabel: {
		textAlign: 'left',
		fontWeight: 400,
		display: 'block'
	},
	itemActive: {
		background: theme.semanticColors.bodyBackgroundChecked
	},
	contextualMenu: {
		width: '100%'
	},
	optionNotOwner: {
		color: '#999'
	},
	sharepointSource: {
		display: 'flex',
		width: '100%'
	},
	sharepointUrlWrapper: {
		width: '100%'
	},
	sharepointSourceButton: {
		marginLeft: '20px',
		minWidth: '160px'
	},
	sharepointSourceSuccessIcon: {
		color: 'green'
	}
});
const calloutProps = {calloutMaxHeight: 300};

function getMenuProps(options, value, onClickItem, onClickAdd, addText) {
	const items = options.map(option => ({
		...option,
		onClick: onClickItem,
		checked: option.key === value,
		className: option.key === value ? classNames.itemActive : classNames.item
	}));

	items.push({
		key: 'add',
		text: addText,
		onClick: onClickAdd,
		iconProps: {iconName: 'Add'}
	});

	items.push({
		key: 'divider',
		itemType: ContextualMenuItemType.Divider
	});

	return {
		shouldFocusOnMount: true,
		className: classNames.contextualMenu,
		items
	};
}

const iconSuccessProps = {iconName: 'SkypeCircleCheck', styles: {root: {color: theme.semanticColors.successIcon}}};
const iconErrorProps = {iconName: 'StatusCircleBlock', styles: {root: {color:  theme.semanticColors.errorIcon}}};

class JobForm extends Component {
	constructor(props) {
		super(props);

		const storageData = window.localStorage.getItem(STORAGE_KEY_USER);
		const {userName} = JSON.parse(storageData);
		const formType = props.match.params.id === 'add-blob' ? 'Blob' : 'SharePoint';

		this.state = {
			userName,
			userNameOnEdit: userName,
			options: {},
			values: {},
			errors: {},
			touched: {},
			isValidForm: false,
			isEdit: false,
			isBlobForm: formType === 'Blob',
			// id: props.match.params.id,
			isLoading: true,
			isPopupOpen: false,

			popupAfterSubmitOpened: false,
			hasErrorAfterSubmit: false,
			errorPopupText: '',

			siteUrl: '',
			sharepointAccessError: null
		};

		this.closePopup = this.closePopup.bind(this);
		this.onChangeFieldByKey = this.onChangeFieldByKey.bind(this);
		this.onChangeField = this.onChangeField.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.goBack = this.goBack.bind(this);
		this.addBlobDesitnation = this.addBlobDesitnation.bind(this);
		this.onChangeUser = this.onChangeUser.bind(this);
		this.popupAfterSubmitClose = this.popupAfterSubmitClose.bind(this);
		this.setSiteUrl = this.setSiteUrl.bind(this);
	}

	async componentDidMount() {
		const {api, match: {params: {id, isCopy}}} = this.props;
		const isAdd = id === 'add' || id === 'add-blob';
		const {isBlobForm: defaultIsBlobForm, userName} = this.state;
		const options = await this.fetchOptions();

		let values = {
			userName,
			indexingProperties: {disable: ['Image analytics', 'Generate preview', 'Video analytics'], enable: ['Text analytics']},
			overwriteStorageTier: 'Cool',
			overwriteFile: optionsOverwriteFile[0].key,
			archivingBehavior: archivingBehavior[0].key,
			forceDelete: true,
			schedule: '* * * * *',
			type: defaultIsBlobForm ? 'Blob' : 'SharePoint',
			isScheduleEnabled: false
		};

		let userNameOnEdit = userName;
		let siteIdOnEdit = null;
		let siteNameOnEdit = null;

		if (!isAdd) {
			const dataForm = await api.get(`/api/migrationJobs/${id}`);

			values = dataForm.data;

			// to be able to reset user to original value
			userNameOnEdit = values.userName;
			siteIdOnEdit = values.siteId;
			siteNameOnEdit = values.siteName;
		}

		this.setState({
			options,
			userNameOnEdit,
			siteIdOnEdit,
			siteNameOnEdit,
			isCopy: isCopy === 'copy',
			copiedTitle: values.title,
			isEdit: !isAdd && isCopy !== 'copy',
			isValidForm: !isAdd,
			isBlobForm: values.type === 'Blob',
			values,
			// id,
			isLoading: false
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const {values} = this.state;

		if (prevState.values !== values) {
			this.validateForm();
		}
	}

	render() {
		const {api} = this.props;
		const {
			isEdit, isCopy, isBlobForm, copiedTitle, values, options, isLoading,
			isValidForm, errors, touched, isPopupOpen, userName, userNameOnEdit,
			popupAfterSubmitOpened, hasErrorAfterSubmit, errorPopupText,
			siteNameOnEdit, siteUrl, sharepointAccessError
		} = this.state;

		if (isLoading === true && Object.keys(options).length === 0) {
			return (
				<div className={classNames.body}>
					<div className={classNames.spinnerWrapper}><Spinner/></div>
				</div>
			);
		}

		const blobDestination = options.blobAuths.find(k => k.key === values.blobAuthId);

		let title = 'Add new job';

		if (isEdit) {
			title = `Edit ${values.title} job`;
		} else if (isCopy) {
			title = `Copy ${copiedTitle} job`;
		} else if (isBlobForm) {
			title = 'Add new blob job';
		}

		return (
			<div className={classNames.body}>
				{isLoading && (
					<div className={classNames.spinnerWrapper}><Spinner/></div>
				)}

				<Text as="h1" block className={classNames.title}>
					{title}
				</Text>

				<GroupFields title="Job name">
					<TextField
						value={values.title}
						name="title"
						onChange={this.onChangeField}
						errorMessage={touched.title !== undefined && errors.title}
					/>
				</GroupFields>

				{!isBlobForm && (
					<GroupFields title="Sharepoint source">
						<div className={classNames.sharepointSource}>
							<div className={classNames.sharepointUrlWrapper}>
								<TextField
									placeholder={isEdit ? siteNameOnEdit : 'Sharepoint site'}
									name="SharepointSite"
									value={siteUrl}
									onChange={this.setSiteUrl}
									errorMessage={sharepointAccessError}
									iconProps={touched.siteId ? ( values.siteId ? iconSuccessProps : iconErrorProps) : null}
									width={1}
								/>
							</div>
							<div>
								<DefaultButton
									text="Check Access"
									className={classNames.sharepointSourceButton}
									iconProps={{iconName: 'Globe'}}
									onClick={() => {
										if (!siteUrl) {
											return;
										}
										api.post('/api/migrationJobs/check-access', {url: siteUrl})
											.then(({data}) => {

												const {values, touched} = this.state;
												const newValues = {...values, siteId: data.id};

												this.setState({
													values: newValues,
													touched: {...touched, siteId: true},
													sharepointAccessError: data.hasAccess ? null : 'No access'
												});


											});
									}}
								/>
							</div>
						</div>
					</GroupFields>
				)}

				<GroupFields title="Blob">
					<Label>Select blob destination</Label>
					<DefaultButton
						text={(blobDestination && blobDestination.text) || ' '}
						className={classNames.btnCommand}
						menuProps={getMenuProps(
							options.blobAuths,
							values.blobAuthId,
							(e, option) => this.onChangeFieldByKey('blobAuthId', option.key),
							this.addBlobDesitnation,
							'Add blob destination'
						)}
						styles={{
							flexContainer: classNames.btnCommandFlexContainer,
							label: classNames.btnCommandLabel
						}}
					/>

					<Dropdown
						label="Storage tier"
						options={options.storageTiers}
						selectedKey={values.overwriteStorageTier}
						onChange={(e, option) => this.onChangeFieldByKey('overwriteStorageTier', option.key)}
					/>

					{!isBlobForm && (
						<Dropdown
							label="Archiving blob behaviour"
							options={optionsOverwriteFile}
							selectedKey={values.overwriteFile}
							onChange={(e, option) => this.onChangeFieldByKey('overwriteFile', option.key)}
						/>
					)}

					{!isBlobForm && (
						<Dropdown
							label="Archiving Sharepoint behaviour"
							options={archivingBehavior}
							selectedKey={values.archivingBehavior}
							onChange={(e, option) => this.onChangeFieldMyltiple({
								archivingBehavior: option.key,
								forceDelete: false
							})}
						/>
					)}

					{!isBlobForm && forceDeleteFile.includes(values.archivingBehavior) && (
						<Toggle
							label="Force delete file"
							name="forceDelete"
							checked={values.forceDelete}
							onChange={(e, value) => this.onChangeFieldByKey('forceDelete', value)}
							onText="File will be permanently deleted"
							offText="File will remain in the recycle bin"
						/>
					)}
				</GroupFields>

				<GroupFields title="Select rules">
					<RulesPicker
						api={api}
						initialRules={values.rules}
						onChange={this.onChangeFieldByKey}
						jobType={!isBlobForm ? 'SharePoint' : 'Blob'}
						isValid
					/>
				</GroupFields>

				<GroupFields title="Indexing settings">
					<IndexingSettings
						values={values.indexingProperties}
						onChange={this.onChangeFieldByKey}
					/>
				</GroupFields>

				<GroupFields title="Schedule">
					<ChoiceGroup defaultSelectedKey selectedKey={values.isScheduleEnabled} options={hasSchedule} onChange={(e, option) => this.onChangeFieldByKey('isScheduleEnabled', option.key)} label="Job run type" required />
					{values.isScheduleEnabled && (
						<Shedule
							values={values.schedule}
							onChange={this.onChangeFieldByKey}
						/>
					)}
				</GroupFields>

				<div className={classNames.actionsContainer}>
					<PrimaryButton
						text="Save"
						iconProps={{iconName: 'CheckMark'}}
						onClick={this.submitForm}
						disabled={isLoading || !isValidForm}
					/>
					<DefaultButton
						text="Cancel"
						onClick={this.goBack}
					/>
				</div>

				<ModalBlobDestinationForm
					api={api}
					isPopupOpen={isPopupOpen}
					closePopup={this.closePopup}
				/>

				<Dialog
					hidden={!popupAfterSubmitOpened}
					onDismiss={this.popupAfterSubmitClose}
					dialogContentProps={{
						title: hasErrorAfterSubmit ? 'Error' : 'Success',
						closeButtonAriaLabel: 'Close',
						subText: errorPopupText
					}}
				>
					<DialogFooter>
						<DefaultButton text="Close" onClick={this.popupAfterSubmitClose}/>
					</DialogFooter>
				</Dialog>
			</div>
		);
	}

	onRenderOption = option => (
		<div className={option.data && option.data === 'not-owner' ? classNames.optionNotOwner : ''}>
			<span>{option.text}</span>
		</div>
	);

	onChangeFieldMyltiple(keysAndValues) {
		const {values, touched} = this.state;
		const newValues = {...values};
		const newTouched = {...touched};

		Object.keys(keysAndValues).forEach(key => {
			newValues[key] = keysAndValues[key];
			newTouched[key] = true;
		});

		this.setState({
			values: newValues,
			touched: newTouched
		});
	}

	onChangeFieldByKey(key, value) {
		const {values, touched} = this.state;
		const newValues = {...values, [key]: value};

		this.setState({
			values: newValues,
			touched: {...touched, [key]: true}
		});
	}

	onChangeField(e) {
		const {values, touched} = this.state;
		const newValues = {...values, [e.target.name]: e.target.value};

		this.setState({
			values: newValues,
			touched: {...touched, [e.target.name]: true}
		});
	}

	onChangeUser(newUser) {
		const {values, touched} = this.state;

		this.setState({
			values: {...values, userName: newUser},
			touched: {...touched, userName: true}
		});
	}

	addBlobDesitnation() {
		this.setState({isPopupOpen: true});
	}

	popupAfterSubmitClose() {
		const {history} = this.props;
		const {hasErrorAfterSubmit} = this.state;

		if (hasErrorAfterSubmit) {
			this.setState({
				popupAfterSubmitOpened: false
			});
		} else {
			history.push('/archiving/jobs/');
		}
	}

	setSiteUrl(e) {
		const value = e.target.value || '';

		this.setState({
			siteUrl: value.trim()
		});
	}

	async closePopup() {
		const options = await this.fetchOptions();

		this.setState({
			options,
			isPopupOpen: false
		});
	}

	async fetchOptions() {
		const {api} = this.props;
		const options = await api.get('/api/migrationJobs/options');
		const dropdownOptions = Object.keys(options.data).reduce((acc, key) => {
			acc[key] = getOptions(options.data[key]);
			return acc;
		}, {});

		dropdownOptions.storageTiers = [{key: '', text: 'Default'}, ...dropdownOptions.storageTiers];

		return dropdownOptions;
	}

	async submitForm() {
		const {api} = this.props;
		const {values, isEdit} = this.state;

		this.setState({isLoading: true});

		const {id, ...valuesWithoutId} = values;
		const payload = !isEdit ? valuesWithoutId : values;
		const url = !isEdit ? '/api/migrationJobs/' : `/api/migrationJobs/${id}`;
		const method = !isEdit ? 'post' : 'put';

		api[method](url, payload)
			.then(() => {
				this.setState({
					popupAfterSubmitOpened: true,
					isLoading: false,
					hasErrorAfterSubmit: false,
					errorPopupText: 'Job succesfuly saved'
				});
			}, respError => {
				const {error} = respError.response.data;

				if (!error) {
					// eslint-disable-next-line
					return Promise.reject(respError.response.statusText);
				}

				this.setState({
					hasErrorAfterSubmit: true,
					popupAfterSubmitOpened: true,
					isLoading: false,
					errorPopupText: error
				});
			})
			.catch(e => {
				this.setState({
					hasErrorAfterSubmit: true,
					popupAfterSubmitOpened: true,
					isLoading: false,
					errorPopupText: e
				});
			});
	}

	validateForm() {
		const {values, options, userName, isBlobForm, siteIdOnEdit, userNameOnEdit, isEdit} = this.state;

		let errors = {};

		function checkRequired(value) {
			if (typeof value === 'boolean') {
				return null;
			}

			const isValid = (value && value.length);

			return isValid ? null : 'Required field';
		}

		function validateSiteId(value) {
			const requiredError = checkRequired(value);

			if (requiredError !== null) {
				return requiredError;
			}

			return null;
		}

		function validateCron(value) {
			const requiredError = checkRequired(value);

			if (requiredError !== null) {
				return requiredError;
			}

			const isValid =	isValidCron(value);

			return isValid ? null : 'Invalid value';
		}

		const globalFields = {
			title: checkRequired,
			blobAuthId: checkRequired,
			// overwriteStorageTier: checkRequired,
			rules: checkRequired,
			schedule: validateCron
		};
		const sharepointUniqFields = {
			siteId: validateSiteId,
			overwriteFile: checkRequired,
			archivingBehavior: checkRequired
		};

		const getErrorMessage = isBlobForm ? globalFields : {...globalFields, ...sharepointUniqFields};

		const isValidForm = Object.keys(getErrorMessage).reduce((isValid, fieldName) => {
			const error = getErrorMessage[fieldName] && getErrorMessage[fieldName](values[fieldName]);

			if (error !== null) {
				errors = {
					...errors,
					[fieldName]: error
				};
			}

			return isValid && !error;
		}, true);

		this.setState({
			isValidForm,
			errors
		});
	}

	goBack() {
		const {history} = this.props;

		history.goBack();
	}
}

JobForm.propTypes = {
	api: PropTypes.func,
	match: PropTypes.object,
	history: PropTypes.object
};

export default withRouter(JobForm);
