import React, {useMemo, useState, useEffect} from 'react';
import {Pivot, PivotItem} from '@fluentui/react';
import './predefined-filters.scss';
import {useStore} from 'effector-react';
import _ from 'lodash';

import {
	setUserFilters, setStatuses, resetStatuses, statuses, setSkip, setCurrentPage
} from '../../../store';

const filterTypes = {
	Enqueued: 'Enqueued',
	Processing: 'Processing',
	Error: 'Error',
	Success: 'Success',
	AllJobs: 'All Jobs'
};

const PredefinedFilters = () => {
	const statusesArr = useStore(statuses);

	const [selectedKey, setSelectedKey] = useState(
		filterTypes.AllJobs
	);

	function setFilters(filterType) {
		setSelectedKey(filterType);
		setSkip(0);
		setCurrentPage(0);

		if (filterType === filterTypes.AllJobs) {
			resetStatuses();
		} else {
			setStatuses([{id: filterType, name: filterType}]);
		}

		setUserFilters(true);
	}

	function onLinkClick(item) {
		if (item && item.props && item.props.headerText) {
			setFilters(item.props.headerText);
		}
	}

	const filters = useMemo(() => {
		const litsOfFilters = _.values(filterTypes);

		return litsOfFilters;
	}, []);

	useEffect(() => {
		if (statusesArr.length === 1) {
			const res = filters.indexOf(statusesArr[0].id);

			if (res > -1) {
				setSelectedKey(filters[res]);
				return;
			}
		}
		setSelectedKey(filters[filters.length - 1]);
	}, [filters, statusesArr]);

	useEffect(() => {
		setFilters(filters[filters.length - 1]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Pivot
			aria-label="filters"
			styles={{root: 'predefined-filters__container'}}
			onLinkClick={onLinkClick}
			selectedKey={selectedKey}
			overflowBehavior="menu"
			overflowAriaLabel="more items"
		>
			{filters.map(el => (
				<PivotItem key={el} headerText={el} itemKey={el}/>
			))}
		</Pivot>
	);
};

export default PredefinedFilters;
