import {
	hasValidMinutes,
	hasValidHours,
	hasValidDays,
	hasValidMonths,
	hasValidWeekdays
} from '../../../../utils/vaildate-cron';

const fields = {
	minutes: {
		label: 'Minutes',
		values: [
			{
				value: '0-59',
				description: '0 is the top of the hour'
			},
			{
				value: '*',
				description: 'Every minute'
			},
			{
				value: ',',
				description: 'A list of minutes; ie. 0,30 would be the 0 AND 30th minutes'
			},
			{
				value: '-',
				description: 'A range of minutes; ie. 0-5 would be minutes 0, 1, 2, 3, 4, and 5 (you can also specify a list of ranges 0-5,30-35)'
			},
			{
				value: '/',
				description: 'Step values will skip the specified number within a range; ie */5 is every 5 minutes, and 0-30/2 is every 2 minutes between 0 and 30 minutes'
			}
		],
		validate: v => hasValidMinutes(v)
	},
	hours: {
		label: 'Hours',
		values: [
			{
				value: '0-23',
				description: '0 is midnight'
			},
			{
				value: '*',
				description: 'Every hour'
			},
			{
				value: ',',
				description: 'A list of hours; ie. 0,12 would be the 0 AND 12th hours'
			},
			{
				value: '-',
				description: 'A range of hours; ie. 19-23 would be hours 19, 20, 21, 22, and 23 (you can also specify a list of ranges 0-5,12-16)'
			},
			{
				value: '/',
				description: 'Step values will skip the specified number within a range; ie */4 is every 4 hours, and 0-20/2 is every 2 hours between 0 and the 20th hour'
			}
		],
		validate: v => hasValidHours(v)
	},
	dayOfMonth: {
		label: 'Day of month',
		values: [
			{
				value: '1-31',
				description: 'allowed values'
			},
			{
				value: '*',
				description: 'Every day of the month'
			},
			{
				value: ',',
				description: 'A list of days; ie. 1,15 would be the 1st AND 15th day of the month'
			},
			{
				value: '-',
				description: 'A range of days; ie. 1-5 would be days 1, 2, 3, 4, and 5 (you can also specify a list of ranges 1-5,14-30)'
			},
			{
				value: '/',
				description: 'Step values will skip the specified number within a range; ie */4 is every 4 days, and 1-20/2 is every 2 days between 1st and the 20th day of the month'
			}
		],
		validate: v => hasValidDays(v)
	},
	month: {
		label: 'Month',
		values: [
			{
				value: '1-12',
				description: 'Allowed values'
			},
			{
				value: '*',
				description: 'Every month'
			},
			{
				value: ',',
				description: 'A list of months; ie. 1,6 would be the jan AND jun'
			},
			{
				value: '-',
				description: 'A range of months; ie. 1-3 would be jan, feb, and mar (you can also specify a list of ranges 1-4,8-12)'
			},
			{
				value: '/',
				description: 'Step values will skip the specified number within a range; ie */4 is every 4 months, and 1-8/2 is every 2 months between january and august'
			}
		],
		validate: v => hasValidMonths(v)
	},
	dayOfWeek: {
		label: 'Day of week',
		values: [
			{
				value: '0-6',
				description: '0 is Sunday, 1 is Monday, etc.'
			},
			{
				value: '*',
				description: 'Every day of the week'
			},
			{
				value: ',',
				description: 'A list of days; ie. 1,5 would be the mon AND fri'
			},
			{
				value: '-',
				description: 'A range of days; ie. 1-5 would be mon, tue, wed, thu, and fri (you can also specify a list of ranges 0-2,4-6)'
			},
			{
				value: '/',
				description: 'Step values will skip the specified number within a range; ie */4 is every 4 days, and 1-5/2 is every 2 days between monday and friday'
			}
		],
		validate: v => hasValidWeekdays(v)
	}
};

export default fields;
