import React from 'react';
import PropTypes from 'prop-types';
import {mergeStyleSets, Text, Stack, StackItem} from '@fluentui/react';
import fields from '../fields';

const classNames = mergeStyleSets({
	title: {
		fontSize: '16px',
		fontWeight: 600,
		lineHeight: '18px',
		marginBottom: '16px'
	},
	content: {
		padding: '16px',
		maxWidth: '300px'
	},
	item: {
		display: 'flex',
		flexFlow: 'column',
		marginBottom: '16px'
	},
	itemTitle: {
		fontSize: '16px',
		fontWeight: 600,
		lineHeight: '18px',
		marginBottom: '4px'
	}
});

const Hint = ({name}) => {
	const hintMeta = fields[name] && fields[name].values;
	const label = fields[name] && fields[name].label;

	return (
		<div className={classNames.content}>
			<div className={classNames.title}>{label}: allowed values</div>
			<Stack tokens={{childrenGap: 16}}>
				{hintMeta.map(({value, description}) => (
					<StackItem className={classNames.hintItem} key={value}>
						<Text block className={classNames.itemTitle}>{value}</Text>
						<Text block className={classNames.itemDescription}>{description}</Text>
					</StackItem>
				))}
			</Stack>
		</div>
	);
};

Hint.propTypes = {
	name: PropTypes.string
};

export default Hint;
