import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {
	mergeStyleSets, DetailsList, DetailsListLayoutMode, SelectionMode, DefaultButton, Spinner, SpinnerSize
} from '@fluentui/react';

function getFirstZero(i) {
	return (i < 10 ? '0' : '') + i;
}

function renderDateTime(_date) {
	if (!_date) return '';

	const date = new Date(_date);
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const month = months[date.getMonth()];
	const day = getFirstZero(date.getDate());
	const hours = getFirstZero(date.getHours());
	const minutes = getFirstZero(date.getMinutes());
	const seconds = getFirstZero(date.getSeconds());

	return `${day} ${month} ${date.getFullYear()} - ${hours}:${minutes}:${seconds}`;
}

function copyAndSort(items, columnKey, isSortedDescending) {
	const key = columnKey;

	return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

const classNames = mergeStyleSets({
	body: {
		flex: 3,
		padding: '2em 2em 3em',
		boxSizing: 'border-box',
		background: '#f3f2f1'
	},
	container: {
		padding: 32,
		maxWidth: 1102,
		background: '#fff',
		boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
		borderRadius: 2,
		boxSizing: 'border-box'
	},
	title: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: '28px',
		marginBottom: 24
	},
	buttonWrapper: {
		marginBottom: 32,
		display: 'flex',
		justifyContent: 'flex-start',
		maxWidth: 1102,
		boxSizing: 'border-box'
	}
});
const cols = [
	{
		key: 'title',
		name: 'Job title',
		fieldName: 'title',
		isRowHeader: true,
		isResizable: true,
		isSorted: false,
		isSortedDescending: false,
		sortAscendingAriaLabel: 'Sorted A to Z',
		sortDescendingAriaLabel: 'Sorted Z to A',
		data: 'string',
		isPadded: true
	},
	{
		key: 'lastRun',
		name: 'Last run',
		fieldName: 'lastRun',
		isRowHeader: true,
		isResizable: true,
		isSorted: false,
		isSortedDescending: false,
		sortAscendingAriaLabel: 'Sorted A to Z',
		sortDescendingAriaLabel: 'Sorted Z to A',
		data: 'string',
		isPadded: true,
		minWidth: 150,
		maxWidth: 150,
		onRender: ({lastRun}) => renderDateTime(lastRun)
	},
	{
		key: 'nextRun',
		name: 'Next Run',
		fieldName: 'nextRun',
		isRowHeader: true,
		isResizable: true,
		isSorted: false,
		isSortedDescending: false,
		sortAscendingAriaLabel: 'Sorted A to Z',
		sortDescendingAriaLabel: 'Sorted Z to A',
		data: 'string',
		isPadded: true,
		minWidth: 150,
		maxWidth: 150,
		onRender: ({nextRun}) => renderDateTime(nextRun)
	},
	{
		key: 'status',
		name: 'Status',
		fieldName: 'status',
		isRowHeader: true,
		isResizable: true,
		isSorted: false,
		isSortedDescending: false,
		sortAscendingAriaLabel: 'Sorted A to Z',
		sortDescendingAriaLabel: 'Sorted Z to A',
		data: 'string',
		isPadded: true
	}
];

class JobsListOfBlobDestination extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [],
			blob: {},
			columns: cols,
			isLoading: true
		};
	}

	async componentDidMount() {
		const {api, match: {params: {blobDestinationId}}} = this.props;

		if (blobDestinationId) {
			try {
				const blob = await api.get(`/api/blobAuths/${blobDestinationId}`);
				const {data} = await api.get(`/api/blobAuths/${blobDestinationId}/jobs`);

				this.setState({
					items: data,
					blob: blob.data,
					isLoading: false
				});
			} catch (error) {
				this.state = {
					isLoading: true
				};
			}
		}
	}

	render() {
		const {items, blob, columns, isLoading} = this.state;
		const {history} = this.props;

		if (isLoading) {
			return (
				<Spinner style={{flex: '1 0 0'}} size={SpinnerSize.large} />
			);
		}

		return (
			<div className={classNames.body}>
				<h1 className={classNames.title}>Jobs list for blob destination {blob && blob.name}</h1>
				<div className={classNames.buttonWrapper}>
					<DefaultButton text="Back" onClick={() => history.goBack()}/>
				</div>
				<div className={classNames.container}>
					<DetailsList
						items={items}
						columns={columns}
						selectionMode={SelectionMode.none}
						getKey={v => v && v.id}
						setKey="none"
						layoutMode={DetailsListLayoutMode.justified}
						isHeaderVisible
						onColumnHeaderClick={this.onColumnHeaderClick}
					/>
				</div>
			</div>
		);
	}

	onColumnHeaderClick = (ev, column) => {
		const {columns, items} = this.state;
		const newColumns = columns.slice();
		const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

		newColumns.forEach(newCol => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});

		const newItems = copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);

		this.setState({
			columns: newColumns,
			items: newItems
		});
	};
}

JobsListOfBlobDestination.propTypes = {
	api: PropTypes.func,
	match: PropTypes.object,
	history: PropTypes.object
};

export default withRouter(JobsListOfBlobDestination);
