import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {Nav, initializeIcons} from '@fluentui/react';

initializeIcons();

const Navigation = ({list}) => {
	const history = useHistory();

	const [selectedKey, setSelectedKey] = useState();

	const updateLocation = useCallback(location => {
		const splitArr = location.pathname.split('/');

		if (splitArr.length > 2) {
			const link = list[0].links[0].links.find(lnk => lnk.url.indexOf(location.pathname.split('/')[2].substring(0, 3)) > -1);

			if (link && link.key) {
				setSelectedKey(link.key);
			}
		} else {
			setSelectedKey(list[0].links[0].links[0].key);
		}
	}, [list]);

	useEffect(() => {
		updateLocation(window.location);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const unlisten = history.listen(location => {
			updateLocation(location);
		});

		return () => {
			unlisten();
		};
	}, [history, list, updateLocation]);

	const onClick = (e, {url}) => {
		e.preventDefault();
		history.push(url);
	};

	return (
		<div style={{flex: 1, maxWidth: '15%', background: '#FFFFFF'}}>
			<Nav
				groups={list}
				selectedKey={selectedKey}
				onLinkClick={onClick}
			/>
		</div>
	);
};

Navigation.propTypes = {
	list: PropTypes.array
};

export default Navigation;
