import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Redirect} from 'react-router-dom';
import CommonAdmin from './CommonAdminModule';
import ArchivingModuleInit from './ArchivingModule';
import Navigation from './NavigationModule';

ReactDOM.render(
	<React.StrictMode>
		<CommonAdmin render={props => {
			const ArchivingModule = ArchivingModuleInit(props);
			const navigationList = [{
				links: [].concat(
					ArchivingModule.navigation
				)
			}];

			return (
				<>
					<Navigation {...props} list={navigationList} />
					<ArchivingModule.Component {...props} />

					<Route exact path="/">
						<Redirect to={navigationList[0].links[0].url} />
					</Route>
				</>
			);
		}}
		/>
	</React.StrictMode>,
	document.getElementById('root')
);
