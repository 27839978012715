import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {CommandBar} from '@fluentui/react';
import {useStore} from 'effector-react';
import {
	authTokens,
	blobAuths,
	statuses,
	sites
} from '../../../store';

import AddButton from '../add-button';

const refreshIcon = {iconName: 'refresh'};
const resetFilterIcon = {iconName: 'ClearFilter'};

const CommandBarComponent = ({
	id, methods: {
		deleteRow, runJob, runTest, onRefreshData, onResetAllFilters
	}, classNames
}) => {
	const history = useHistory();

	const authTokensArr = useStore(authTokens);
	const blobAuthsArr = useStore(blobAuths);
	const statusesArr = useStore(statuses);
	const sitesArr = useStore(sites);

	const farItems = useMemo(() => {
		const items = [
			{
				key: 'refresh',
				text: 'Refresh',
				onClick: onRefreshData,
				iconProps: refreshIcon,
				className: classNames.actionButton
			}
		];

		if (!!authTokensArr.length || !!blobAuthsArr.length || !!statusesArr.length || !!sitesArr.length) {
			items.push({
				key: 'resetAllFilters',
				text: 'Reset all filters',
				onClick: onResetAllFilters,
				iconProps: resetFilterIcon,
				className: classNames.actionButton
			});
		}

		return items;
	}, [authTokensArr, blobAuthsArr, classNames.actionButton, onRefreshData, onResetAllFilters, sitesArr, statusesArr]);

	const actions = useMemo(() => {
		let array = [{
			key: 'add',
			onRender: () => <AddButton classNames={classNames} />
		}];

		if (id) {
			array = [...array, ...[
				{
					key: 'start', text: 'Start', onClick: () => runJob(id), iconProps: {iconName: 'Play'}, className: classNames.actionButton
				},
				{
					key: 'test', text: 'Test', onClick: () => runTest(id), iconProps: {iconName: 'TestCase'}, className: classNames.actionButton
				},
				{
					key: 'runs', text: 'Job runs', onClick: () => history.push(`/archiving/job-runs/${id}`), iconProps: {iconName: 'TaskList', styles: {root: 'task-list-icon'}}, className: classNames.actionButton
				},
				{
					key: 'edit', text: 'Edit', onClick: () => history.push(`/archiving/jobs/${id}`), iconProps: {iconName: 'PageEdit'}, className: classNames.actionButton
				},
				{
					key: 'copy', text: 'Copy', onClick: () => history.push(`/archiving/jobs/${id}/copy`), iconProps: {iconName: 'Copy'}, className: classNames.actionButton
				},
				{
					key: 'delete', text: 'Delete', onClick: () => deleteRow(id), iconProps: {iconName: 'Delete'}, className: classNames.actionButton
				}

			]];
		}

		return array;

		// eslint-disable-next-line
	}, [id, deleteRow, history]);

	return (
		<CommandBar
			items={actions}
			farItems={farItems}
			ariaLabel="Job actions"
			overflowButtonProps={{styles: {root: classNames.commandBarOverflowButton}}}
			styles={{root: classNames.commandBar}}
		/>
	);
};

CommandBarComponent.propTypes = {
	id: PropTypes.string,
	methods: PropTypes.object,
	classNames: PropTypes.object
};

export default CommandBarComponent;
