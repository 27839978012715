import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {
	mergeStyleSets, FontIcon, DefaultButton, ColumnActionsMode, DetailsList, DetailsListLayoutMode, SelectionMode, getTheme
} from '@fluentui/react';
import RulesPickerModal from './modal';

const theme = getTheme();
const classNames = mergeStyleSets({
	container: {
		display: 'flex',
		flexDirection: 'column',
		'& > *:not(:last-child)': {
			marginBottom: '16px'
		}
	},
	button: {
		width: 'max-content'
	},
	dragItem: {
		backgroundColor: theme.palette.neutralLight
	}
});

const columns = [
	{
		key: 'column1',
		name: 'File Type',
		ariaLabel: 'Column operations for File type, Press to sort on File type',
		iconName: 'Dictionary',
		isIconOnly: true,
		fieldName: 'name',
		minWidth: 16,
		maxWidth: 16,
		columnActionsMode: ColumnActionsMode.disabled,
		onRender: () => <FontIcon iconName="Dictionary" />
	},
	{
		key: 'title',
		name: 'Rule title',
		fieldName: 'title',
		isRowHeader: true,
		isResizable: true,
		isSorted: false,
		isSortedDescending: false,
		sortAscendingAriaLabel: 'Sorted A to Z',
		sortDescendingAriaLabel: 'Sorted Z to A',
		data: 'string',
		columnActionsMode: ColumnActionsMode.disabled,
		isPadded: true
	}
];

function getSelectedRules(rulesList, initialRules) {
	if (initialRules === undefined) {
		return [];
	}
	return initialRules.map(ruleId => rulesList.find(r => r.id === ruleId));
}

const getKey = v => v && v.title;

class RulesPicker extends Component {
	constructor(props) {
		super(props);

		this.state = {
			initialRules: [],
			rulesList: [],
			isModalOpen: false
		};

		this.dragDropEvents = this.getDragDropEvents();
		this.draggedIndex = -1;

		this.updateRules = this.updateRules.bind(this);
		this.changeRules = this.changeRules.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	async componentDidMount() {
		const {api, initialRules, jobType} = this.props;
		const {data} = await api.get(`/api/rules?jobType=${jobType}`);
		const selectedRules = getSelectedRules(data, initialRules);

		this.setState({
			rulesList: data,
			initialRules: initialRules || [],
			selectedRules
		});
	}

	async componentDidUpdate(prevProps) {
		const {initialRules} = this.props;

		if (prevProps.initialRules !== initialRules) {
			this.updateRules();
		}
	}

	render() {
		const {rulesList, isModalOpen, initialRules, selectedRules} = this.state;

		if (rulesList.length === 0) {
			return null;
		}

		return (
			<div className={classNames.container}>
				<DetailsList
					items={selectedRules}
					columns={columns}
					getKey={getKey}
					setKey="none"
					layoutMode={DetailsListLayoutMode.justified}
					isHeaderVisible
					disableSelectionZone={false}
					selectionMode={SelectionMode.none}
					dragDropEvents={this.dragDropEvents}
				/>
				<DefaultButton
					text="Add rule from the list"
					iconProps={{iconName: 'CircleAddition'}}
					className={classNames.button}
					onClick={this.openModal}
				/>
				<RulesPickerModal
					open={isModalOpen}
					onClose={this.closeModal}
					onSubmit={this.changeRules}
					initialRules={initialRules}
					rules={rulesList}
					columns={columns}
					getKey={getKey}
				/>
			</div>
		);
	}

	getDragDropEvents() {
		return {
			canDrop: () => true,
			canDrag: () => true,
			onDragEnter: () => classNames.dragItem,
			onDragLeave: () => {},
			onDrop: item => {
				if (this.draggedItem) {
					this.insertBeforeItem(item);
				}
			},
			onDragStart: (item, itemIndex) => {
				this.draggedItem = item;
				this.draggedIndex = itemIndex;
			},
			onDragEnd: () => {
				this.draggedItem = undefined;
				this.draggedIndex = -1;
			}
		};
	}

	updateRules() {
		const {rulesList} = this.state;
		const {initialRules} = this.props;
		const selectedRules = getSelectedRules(rulesList, initialRules);

		this.setState({
			initialRules,
			selectedRules
		});
	}

	insertBeforeItem(item) {
		const {onChange} = this.props;
		const {selectedRules, initialRules} = this.state;
		const dragItem = this.draggedItem;
		const insertIndex = selectedRules.indexOf(item);
		const reorderdInitialRules = initialRules.filter(id => {
			const isDragged = id === dragItem.id;

			return !isDragged;
		});

		reorderdInitialRules.splice(insertIndex, 0, dragItem.id);

		onChange('rules', reorderdInitialRules);
	}

	changeRules(initialRules) {
		const {onChange} = this.props;

		onChange('rules', initialRules);
	}

	closeModal() {
		this.setState({
			isModalOpen: false
		});
	}

	openModal() {
		this.setState({
			isModalOpen: true
		});
	}
}

RulesPicker.propTypes = {
	api: PropTypes.func,
	initialRules: PropTypes.array,
	onChange: PropTypes.func,
	jobType: PropTypes.string
};

export default withRouter(RulesPicker);
