import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {mutate} from 'swr';
import {useStore} from 'effector-react';
import {setIconOptions} from '@fluentui/react';
import JobList from './list';
import {
	setRulesDefinitions,
	setJobList,
	setJobListTotalCount,
	statuses,
	sites,
	skip,
	itemsPerPage,
	search,
	orderBy,
	isAscending,
	authTokens,
	blobAuths,
	setUserFilters,
	resetAuthTokens,
	resetBlobAuths,
	resetSites,
	resetStatuses,
	lastRunDateRangeStore,
	isNeverRunStore
} from '../store';

// Suppress icon warnings.
setIconOptions({
	disableWarnings: true
});

const API_URL = '/api/migrationJobs';
const API_URL_AUTH_TOKENS = '/api/authTokens';
const API_URL_BLOB_AUTHS = '/api/blobAuths';

const Loader = props => {
	const {api} = props;

	const statusesArr = useStore(statuses);
	const sitesArr = useStore(sites);
	const skipNum = useStore(skip);
	const itemsPerPageNum = useStore(itemsPerPage);
	const searchStr = useStore(search);
	const orderByStr = useStore(orderBy);
	const authTokensArr = useStore(authTokens);
	const blobAuthsArr = useStore(blobAuths);
	const isAscendingBoolean = useStore(isAscending);
	const lastRunDateRange = useStore(lastRunDateRangeStore);
	const isNeverRun = useStore(isNeverRunStore);

	const getDefinitions = useCallback(async () => {
		try {
			const sitesArrDef = await api.get(API_URL + '/sites');

			const authTokensDef = await api.get(API_URL_AUTH_TOKENS);

			const blobAuthsDef = await api.get(API_URL_BLOB_AUTHS);

			const statusesArrDef = await api.get(API_URL + '/statuses');

			const rulesDefinitions = {
				sites: sitesArrDef.data,
				authTokens: authTokensDef.data,
				blobAuths: blobAuthsDef.data,
				statuses: statusesArrDef.data
			};

			setRulesDefinitions(rulesDefinitions);
		} catch (err) {
			// eslint-disable-next-line
			console.error(err);
		}
	}, [api]);

	const onDelete = async id => {
		// eslint-disable-next-line
		if (window.confirm("following action will delete the job")) {
			api.delete(`${API_URL}/${id}`)
				.then(() => {
					mutate(
						API_URL,
						d => d && d.filter(item => id !== item.id),
						true
					);
				})
				// eslint-disable-next-line
				.catch(() => window.alert(`error while deleting id: ${id}`));
		}
	};

	const onJobRun = async id => {
		try {
			await api.post(`${API_URL}/${id}/start`);
		} catch (err) {
			// eslint-disable-next-line
			console.error(err);
		}
	};

	const onTestRun = async id => {
		try {
			await api.post(`${API_URL}/${id}/dryrun`);
		} catch (err) {
			// eslint-disable-next-line
			console.error(err);
		}
	};

	async function onFilter() {
		try {
			setUserFilters(false);
			const filterString = searchStr || '';

			let url = '/api/migrationJobs?';

			url += `search=${encodeURIComponent(filterString)}`;
			url += `&skip=${skipNum}`;
			url += `&count=${itemsPerPageNum}`;
			if (orderByStr) {
				url += `&orderBy=${orderByStr}`;
				url += `&isAscending=${isAscendingBoolean} `;
			}
			if (statusesArr.length) {
				url += `&${statusesArr
					.map(status => `statuses=${status.id}`)
					.join('&')}`;
			}
			if (sitesArr.length) {
				url += `&${sitesArr
					.map(site => `siteIds=${site.id}`)
					.join('&')}`;
			}
			if (blobAuthsArr.length) {
				url += `&${blobAuthsArr
					.map(blobAuth => `blobAuths=${blobAuth.id}`)
					.join('&')}`;
			}
			if (authTokensArr.length) {
				url += `&${authTokensArr
					.map(authToken => `userNames=${authToken.name}`)
					.join('&')}`;
			}

			if (lastRunDateRange !== null) {
				if (
					lastRunDateRange.start !== undefined
					&& lastRunDateRange.start.length > 0
				) {
					url += `&lastRunDateStart=${lastRunDateRange.start}`;
				}

				if (
					lastRunDateRange.end !== undefined
					&& lastRunDateRange.end.length > 0
				) {
					url += `&lastRunDateEnd=${lastRunDateRange.end}`;
				}
			}

			url += `&neverRun=${isNeverRun}`;

			url = url.replaceAll('\t', '').replaceAll('\n', '');

			const results = await api.get(url);

			setJobList([...results.data.migrationJobs]);
			setJobListTotalCount(results.data.totalCount);
		} catch (errorEx) {
			// eslint-disable-next-line
			console.log(`Can't getting the migrations - ${errorEx}`);
		}
	}

	const onRefresh = () => {
		onFilter();
	};

	const onResetAllFilters = () => {
		resetAuthTokens();
		resetBlobAuths();
		resetSites();
		resetStatuses();
		setUserFilters(true);
	};

	useEffect(() => {
		getDefinitions();
	}, [getDefinitions]);

	useEffect(() => {
		if (lastRunDateRange !== null) {
			onFilter();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastRunDateRange]);

	return (
		<JobList
			onDelete={onDelete}
			onJobRun={onJobRun}
			onTestRun={onTestRun}
			onRefreshData={onRefresh}
			onFilter={onFilter}
			onResetAllFilters={onResetAllFilters}
			{...props}
		/>
	);
};

Loader.propTypes = {
	api: PropTypes.func
};

export default Loader;
