export const optionsOverwriteFile = [
	{key: true, text: 'Overwrite existing blob'},
	{key: false, text: 'Create new blob'}
];

export const archivingBehavior = [
	{key: 'ReplaceWithStub', text: 'Archive and replace source with stub'},
	{key: 'Keep', text: 'Archive a copy and keep in place'},
	{key: 'Remove', text: 'Archive and delete source content (no Stub)'}
];

export const forceDeleteFile = ['ReplaceWithStub', 'Remove'];

export const hasSchedule = [
	{key: false, text: 'Manual run only'},
	{key: true, text: 'Run by schedule'}
];

export const getOptions = options => Object.keys(options)
	.map(key => ({
		key,
		text: options[key]
	}));
