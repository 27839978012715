import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, TextField} from '@fluentui/react';
import {findHighestUnitIndex, validateNumber} from './utils';

function FieldSize({onChange, value, placeholder, errorMessage}) {
	const UNITS = [
		{key: 'b', text: 'Byte', multiplier: 1},
		{key: 'kb', text: 'KB', multiplier: 1e+3},
		{key: 'mb', text: 'MB', multiplier: 1e+6},
		{key: 'gb', text: 'GB', multiplier: 1e+9}
	];

	let parsed = parseInt(value, 10);

	const highestUnitIndex = findHighestUnitIndex(UNITS, parsed);
	const target = UNITS[highestUnitIndex];
	const highestUnit = target.key;

	parsed /= target.multiplier;

	const [unit, setUnit] = useState(highestUnit);
	const [num, setNum] = useState(Number.isNaN(parsed) ? 0 : parsed);

	const comboChange = (field, v) => {
		if (field === 'unit') {
			onChange((num * v.multiplier).toString());
			setUnit(v.key);
		} else {
			onChange((v * UNITS.find(({key}) => key === unit).multiplier).toString());
			setNum(v);
		}
	};

	return (
		<>
			<TextField
				placeholder={placeholder}
				onChange={(e, newValue) => comboChange('num', newValue)}
				value={num}
				style={{width: 106}}
				errorMessage={errorMessage}
				onGetErrorMessage={v => validateNumber(v)}
			/>
			<Dropdown
				styles={{dropdown: {width: 84}}}
				placeholder="Select a unit"
				onChange={(e, newValue) => comboChange('unit', newValue)}
				selectedKey={unit}
				options={UNITS}
			/>
		</>
	);
}

FieldSize.propTypes = {
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	errorMessage: PropTypes.string,
	value: PropTypes.string // number as a string
};

export default FieldSize;
