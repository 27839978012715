const NOTIFICATION_FETCH_INTERVAL = 5000;

const jobStatus = {
	Enqueued: 'Enqueued',
	PreProcessing: 'Pending',
	InProgress: 'Processing',
	Completed: 'Success',
	Error: 'Error'
};

const getData = async (updateState, api) => {
	try {
		const {data = {}} = await api.get('/api/migrationJobRuns/latest');
		const {countNewCompletedJobs = 0, results: latestItems = []} = data;

		updateState({
			latestItems,
			indicator: countNewCompletedJobs
		});
	} catch (error) {
		// eslint-disable-next-line
		console.log('notification get data error', error);
	}
};

export const getAllNotifications = async (offset = 0, limit = 5, updateState, api) => {
	try {
		const {data = {}} = await api.get(`/api/migrationJobRuns?limit=${limit}&offset=${offset}`);
		const {results: allItems = [], total: totalItems = 0} = data;

		updateState({
			allItems,
			totalItems,
			itemsLoading: false
		});
	} catch (error) {
		// eslint-disable-next-line
		console.log('notification get all data error', error);
	}
};

export const markAsViewed = async (updateState, ids, api) => {
	try {
		const {data = {}} = await api.post('/api/migrationJobRuns/viewed', {ids});

		if (typeof data.countNewCompletedJobs !== 'undefined') {
			updateState({
				indicator: data.countNewCompletedJobs
			});
		}
	} catch (error) {
		// eslint-disable-next-line
		console.log('notification post viewed error', error);
	}

	return 'error';
};

export const getNotificationsWithIntervalFetch = (updateState, api, initialized) => {
	if (!initialized) {
		getData(updateState, api);
	}

	setTimeout(() => {
		getData(updateState, api);
		getNotificationsWithIntervalFetch(updateState, api, true);
	}, NOTIFICATION_FETCH_INTERVAL);
};

export const formatSize = size => {
	const thresh = 1024;

	if (!size) {
		return 0;
	}

	let bytes = size;

	if (Math.abs(bytes) < thresh) {
		return bytes + ' B';
	}
	const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	let u = -1;

	do {
		bytes /= thresh;
		u += 1;
	} while (Math.abs(bytes) >= thresh && u < units.length - 1);

	return bytes.toFixed(1) + ' ' + units[u];
};

export const formatDate = date => {
	if (date === null) {
		return '';
	}

	date = new Date(date);

	const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()];
	const addZero = num => (num < 10 ? '0' + num : num);

	return `${month} ${addZero(date.getDate())} ${date.getFullYear()} ${addZero(date.getHours())}:${addZero(date.getMinutes())}:${addZero(date.getSeconds())}`;
};

export const getJobStatusIcon = status => {
	switch (status) {
		case jobStatus.Enqueued:
			return 'Clock';
		case jobStatus.PreProcessing:
		case jobStatus.InProgress:
			return 'Sync';
		case jobStatus.Completed:
			return 'Completed';
		case jobStatus.Error:
			return 'ErrorBadge';
		default: return '';
	}
};
