export const Status = {
	default: 'default',
	enabled: 'enable',
	disabled: 'disable'
};

export const StatusHint = {
	[Status.default]: 'Keep current status',
	[Status.enabled]: 'Enabled',
	[Status.disabled]: 'Disabled'
};

export const StatusOptions = [
	{key: Status.default, text: StatusHint[Status.default]},
	{key: Status.enabled, text: StatusHint[Status.enabled]},
	{key: Status.disabled, text: StatusHint[Status.disabled]}
];

export const getvaluesKey = (indexingOptions, value) => {
	if (indexingOptions === undefined) {
		return Status.default;
	}

	if (indexingOptions[Status.enabled] && indexingOptions[Status.enabled].includes(value)) {
		return Status.enabled;
	}

	if (indexingOptions[Status.disabled] && indexingOptions[Status.disabled].includes(value)) {
		return Status.disabled;
	}

	return Status.default;
};
