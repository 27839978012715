import {mergeStyleSets} from '@fluentui/react';

const classNames = mergeStyleSets({
	fileIconHeaderIcon: {
		padding: 0,
		fontSize: '16px'
	},
	fileIconHeaderIconAlt: {
		padding: 0,
		fontSize: '16px',
		color: '#0078D4'
	},
	fileIconCell: {
		textAlign: 'center',
		selectors: {
			'&:before': {
				content: '.',
				display: 'inline-block',
				verticalAlign: 'middle',
				height: '100%',
				width: '0px',
				visibility: 'hidden'
			}
		}
	},
	fileIconImg: {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px'
	},
	controlWrapper: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	exampleToggle: {
		display: 'inline-block',
		marginBottom: '10px',
		marginRight: '30px'
	},
	selectionDetails: {
		marginBottom: '20px'
	},
	body: {
		flex: 3,
		boxSizing: 'border-box',
		background: '#F3F2F1',
		borderLeft: '1px solid #edebe9',
		width: '77vw'
	},
	wrapper: {
		margin: 32,
		paddingTop: 32,
		background: '#fff',
		boxShadow: 'rgb(0 0 0 / 10%) 0px 0.3px 0.9px, rgb(0 0 0 / 13%) 0px 1.6px 3.6px',
		borderRadius: 2,
		maxWidth: 1570
	},
	container: {
		padding: '0px 14px 32px 32px',
		background: '#fff',
		// boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
		borderRadius: 2,
		boxSizing: 'border-box'
	},
	searchBox: {
		flex: '1 0 0',
		maxWidth: 540
	},
	buttonWrapper: {
		marginBottom: 32,
		maxWidth: 1102,
		display: 'flex',
		justifyContent: 'flex-end',
		boxSizing: 'border-box'
	},
	dropdown: {
		display: 'flex',
		marginLeft: 24,
		marginRight: 56
	},
	actionColumns: {
		display: 'flex',
		alignItems: 'center',
		padding: 0
	},
	title: {
		fontSize: 18,
		fontWeight: '600',
		lineHeight: 25,
		marginBottom: 24,
		marginTop: 0,
		marginLeft: 32
	},
	titleLink: {
		cursor: 'pointer',
		color: '#323130',
		textDecoration: 'none',

		':hover': {
			textDecoration: 'underline'
		}
	},
	addButton: {
		marginLeft: '1em'
	},
	commandBar: {
		width: '100wh',
		alignItems: 'center',
		borderBottom: '1px solid #e1dfdd',
		background: '#F3F2F1',
		paddingRight: 32
	},
	commandBarOverflowButton: {
		background: '#F3F2F1',
		':hover': {
			background: '#e1dfdd'
		}
	},
	actionButton: {
		background: '#F3F2F1',
		minHeight: 32,

		':hover': {
			background: '#e1dfdd'
		}
	}
});

export default classNames;
