import React from 'react';
import {withRouter} from 'react-router-dom';
import JobRuns from './runs';
import JobRunDetails from './run-details';

export const JobRunsOrDetails = withRouter(params => {
	const {match: {params: {jobRunId}}} = params;

	return (
		typeof jobRunId !== 'undefined' ? <JobRunDetails {...params} /> : <JobRuns {...params} />
	);
});

export {default as JobList} from './list';
export {default as JobAddOrEdit} from './add-or-edit';
