import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {mergeStyleSets, Text, MessageBar, MessageBarType} from '@fluentui/react';
import cronstrue from 'cronstrue';
import SheduleInput from './input';
import fields from './fields';

const cronToText = cron => {
	try {
		return cronstrue.toString(cron, {verbose: false, use24HourTimeFormat: true});
	} catch (err) {
		return (
			<MessageBar
				messageBarType={MessageBarType.error}
				isMultiline={false}
			>
				Please fix the shedule
			</MessageBar>
		);
	}
};

const classNames = mergeStyleSets({
	shedule: {
		display: 'flex',
		marginTop: '16px',
		flexFlow: 'column nowrap',
		'& > *:not(:last-child)': {
			marginBottom: '16px'
		}
	},
	inputs: {
		display: 'flex',
		flexFlow: 'row wrap',
		marginBottom: '0 !important',
		'& > *:not(:last-child)': {
			marginRight: '8px'
		},
		'& > *': {
			maxWidth: '150px'
		}
	},
	labelWithHint: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer'
	},
	hintIcon: {
		height: '19px',
		marginLeft: '8px'
	},
	humanShedule: {
		fontSize: '16px',
		fontWeight: 600,
		minHeight: '24px',
		marginTop: 8
	},
	sheduleInput: {
		minHeight: '55px',
		'& input': {
			fontWeight: 600,
			fontSize: '16px'
		}
	},
	sheduleDescription: {
		fontSize: '16px',
		'& > strong': {
			fontWeight: 600
		}
	}
});

class Shedule extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			callout: '',
			minutes: '*',
			hours: '*',
			dayOfMonth: '*',
			month: '*',
			dayOfWeek: '*'
		};

		this.onChange = this.onChange.bind(this);
		this.setCallout = this.setCallout.bind(this);
		this.setShedule = this.setShedule.bind(this);
	}

	componentDidMount() {
		this.setShedule();
	}

	componentDidUpdate(prevProps) {
		const {values} = this.state;

		if (prevProps.values !== values) {
			this.setShedule();
		}
	}

	render() {
		const {callout} = this.state;
		const {values} = this.props;

		return (
			<div className={classNames.shedule}>
				<Text block className={classNames.sheduleDescription}>
					Job scheduler uses <strong>cron</strong> format.
					You can find hints for every value setting by clicking at the label
				</Text>
				<div className={classNames.inputs}>
					{Object.keys(fields).map(name => (
						<SheduleInput
							key={name}
							name={name}
							// eslint-disable-next-line
							value={this.state[name]}
							onChange={this.onChange}
							setCallout={this.setCallout}
							callout={callout}
						/>
					))}
				</div>
				<Text block variant="medium">
					This job will run with following schedule:
					<div className={classNames.humanShedule}>
						{cronToText(values)}
					</div>
				</Text>
			</div>
		);
	}

	onChange(e) {
		const {onChange} = this.props;
		const {
			minutes, hours, dayOfMonth, month, dayOfWeek
		} = this.state;
		const shedule = {
			minutes, hours, dayOfMonth, month, dayOfWeek
		};

		shedule[e.target.name] = e.target.value;

		onChange('schedule', `${shedule.minutes} ${shedule.hours} ${shedule.dayOfMonth} ${shedule.month} ${shedule.dayOfWeek}`);
	}

	setShedule() {
		const {values} = this.props;

		if (values !== undefined) {
			const [minutes, hours, dayOfMonth, month, dayOfWeek] = values.split(' ');

			this.setState({
				minutes, hours, dayOfMonth, month, dayOfWeek
			});
		}
	}

	setCallout(callout) {
		this.setState({
			callout
		});
	}
}

Shedule.propTypes = {
	values: PropTypes.string,
	onChange: PropTypes.func
};

export default Shedule;
