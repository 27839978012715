import React from 'react';
import PropTypes from 'prop-types';
import {
	mergeStyleSets, Label, TextField, Callout, FontIcon
} from '@fluentui/react';
import Hint from '../hint';
import fields from '../fields';

const classNames = mergeStyleSets({
	shedule: {
		display: 'flex',
		flexFlow: 'column nowrap',
		'& > *:not(:last-child)': {
			marginBottom: '16px'
		}
	},
	inputs: {
		display: 'flex',
		flexFlow: 'row wrap',
		marginBottom: '0 !important',
		'& > *:not(:last-child)': {
			marginRight: '8px'
		},
		'& > *': {
			maxWidth: '150px'
		}
	},
	labelWithHint: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer'
	},
	hintIcon: {
		height: '19px',
		marginLeft: '8px'
	},
	humanShedule: {
		fontSize: '16px',
		fontWeight: 600,
		minHeight: '24px'
	},
	sheduleInput: {
		minHeight: '55px',
		'& input': {
			fontWeight: 600,
			fontSize: '16px'
		}
	},
	sheduleDescription: {
		fontSize: '16px',
		'& > strong': {
			fontWeight: 600
		}
	}
});

const SheduleInput = ({
	name,
	value,
	onChange,
	callout,
	setCallout
}) => (
	<div>
		<Label
			htmlFor={name}
			className={classNames.labelWithHint}
			onClick={() => setCallout(name)}
		>
			{fields[name].label} <FontIcon className={classNames.hintIcon} iconName="info" />
		</Label>
		<TextField
			id={name}
			name={name}
			defaultValue="*"
			value={value}
			onChange={onChange}
			onGetErrorMessage={v => (fields[name].validate(v) ? '' : 'Invalid value')}
			className={`shedule-${name} ${classNames.sheduleInput}`}
		/>
		{callout === name && (
			<Callout
				target={`.shedule-${name}`}
				onDismiss={() => setCallout('')}
			>
				<Hint name={name} />
			</Callout>
		)}
	</div>
);

SheduleInput.propTypes = {
	name: PropTypes.string,
	onChange: PropTypes.func,
	setCallout: PropTypes.func,
	callout: PropTypes.string,
	value: PropTypes.string
};

export default SheduleInput;
