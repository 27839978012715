import React from 'react';
import ReactPaginate from 'react-paginate';
import {useStore} from 'effector-react';
import {
	setCurrentPage, itemsPerPage, jobListTotalCount, setSkip, setUserFilters, currentPage
} from '../../../store';
import './pagination.scss';

const Pagination = () => {
	const totalCountNum = useStore(jobListTotalCount);
	const itemsPerPageNum = useStore(itemsPerPage);
	const currentPageNum = useStore(currentPage);

	const handleClick = event => {
		const newOffset = (event.selected * itemsPerPageNum) % totalCountNum;

		setSkip(newOffset);
		setCurrentPage(event.selected);
		setUserFilters(true);
	};

	return (
		<>
			{totalCountNum > itemsPerPageNum && (
				<ReactPaginate
					breakLabel="..."
					nextLabel=">"
					onPageChange={handleClick}
					pageRangeDisplayed={2}
					forcePage={currentPageNum}
					pageCount={Math.ceil(totalCountNum / itemsPerPageNum)}
					previousLabel="<"
					renderOnZeroPageCount={null}
					pageClassName="page-item"
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakClassName="page-item"
					breakLinkClassName="page-link"
					containerClassName="pagination"
					activeClassName="active"
				/>
			)}
		</>
	);
};

export default Pagination;
