import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {
	mergeStyleSets, Separator, Text, Dropdown, Stack, TextField, IconButton, DefaultButton, Dialog, DialogFooter, PrimaryButton, MessageBar, MessageBarType
} from '@fluentui/react';

import FieldRelatedDate from './relative-date';
import FieldSize from './size';
import FieldDatePicker from './date-picker';

const classNames = mergeStyleSets({
	body: {
		marginTop: 16
	}
});

const OPERATIONS = [
	{key: 'equals', text: 'Equals'},
	{key: 'not equals', text: 'Not equals'},
	{key: 'greater than', text: 'Greater than'},
	{key: 'less than', text: 'Less than'},
	{key: 'older than', text: 'Older than'},
	{key: 'within', text: 'Within'}
];

const ALLOWED_OPERATIONS = {
	String: ['equals', 'not equals'],
	Size: ['equals', 'not equals', 'greater than', 'less than'],
	Date: ['equals', 'not equals', 'greater than', 'less than'],
	RelativeDate: ['older than', 'within'],
	Dropdown: ['equals', 'not equals']
};

class FieldsRules extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dialog: null
		};

		this.onChangeField = this.onChangeField.bind(this);
		this.renderValue = this.renderValue.bind(this);
		this.onRemove = this.onRemove.bind(this);
		this.onAdd = this.onAdd.bind(this);
		this.onCloseDialog = this.onCloseDialog.bind(this);
		this.onOpenDialog = this.onOpenDialog.bind(this);
	}

	render() {
		const {dialog} = this.state;
		const {errors, touched, values, options} = this.props;

		return (
			<div className={classNames.body}>
				<Stack tokens={{childrenGap: 16}}>
					{values && Object.keys(values).map(index => {
						const {id, fieldOperationId, value, placeholder} = values[index];
						const field = options.find(i => i.key === id);
						const type = field && field.type;
						const fieldOptions = (field && field.options) || [];
						const allowedOperationsByKey = ALLOWED_OPERATIONS[type];
						const allowedOperation = allowedOperationsByKey
							? allowedOperationsByKey.map(k => OPERATIONS.find(({key}) => key === k))
							: OPERATIONS;

						function getErrorMessage(key) {
							return touched && touched[index] && touched[index][key] !== undefined && errors && errors[index] && errors[index][key];
						}

						return (
							<Stack key={index} horizontal tokens={{childrenGap: 16}} horizontalAlign="stretch">
								<Text variant="large">{index}</Text>

								<Dropdown
									styles={{dropdown: {width: 268}}}
									placeholder="Select a field"
									selectedKey={id}
									options={options}
									onChange={(e, newValue) => this.onChangeField(index, 'id', newValue)}
									errorMessage={getErrorMessage('id')}
								/>

								<Dropdown
									styles={{dropdown: {width: 128}}}
									placeholder="Operation"
									options={allowedOperation}
									selectedKey={fieldOperationId}
									onChange={(e, newValue) => this.onChangeField(index, 'fieldOperationId', newValue)}
									errorMessage={getErrorMessage('fieldOperationId')}
								/>

								{this.renderValue(index, value, type, placeholder, fieldOptions)}

								<IconButton
									iconProps={{iconName: 'BoxMultiplySolid'}}
									title="remove"
									ariaLabel="remove"
									onClick={() => this.onOpenDialog(index)}
								/>
							</Stack>
						);
					})}
				</Stack>

				{touched && errors && errors.error && (
					<MessageBar
						messageBarType={MessageBarType.error}
						isMultiline={false}
					>
						{errors.error}
					</MessageBar>
				)}

				<Separator/>

				<DefaultButton
					text="Add new condition"
					iconProps={{iconName: 'CirclePlus'}}
					style={{marginBottom: 32}}
					onClick={this.onAdd}
				/>

				<Dialog
					hidden={dialog === null}
					onDismiss={this.onCloseDialog}
					dialogContentProps={{
						title: dialog && dialog.title,
						closeButtonAriaLabel: 'Close',
						subText: dialog && dialog.text
					}}
				>
					<DialogFooter>
						<DefaultButton text="Close" onClick={this.onCloseDialog}/>
						<PrimaryButton text="Remove" onClick={this.onRemove}/>
					</DialogFooter>
				</Dialog>
			</div>
		);
	}

	renderValue(index, value, type, placeholder, options) {
		const {errors, touched} = this.props;

		function renderPlaceHolder(defaultValue) {
			return placeholder !== '0' ? value : defaultValue;
		}

		const errorMessage = (touched && touched[index] && touched[index].value !== undefined) && (errors && errors[index] && errors[index].value);

		switch (type) {
			case 'RelativeDate':
				return (
					<FieldRelatedDate
						placeholder={renderPlaceHolder('Value')}
						onChange={newValue => this.onChangeField(index, 'value', newValue)}
						value={value}
						errorMessage={errorMessage}
					/>
				);
			case 'Date':
				return (
					<FieldDatePicker
						onChange={newValue => this.onChangeField(index, 'value', newValue)}
						value={value}
						placeholder={renderPlaceHolder('Select date')}
						errorMessage={errorMessage}
					/>
				);
			case 'Size':
				return (
					<FieldSize
						placeholder={renderPlaceHolder('Select unit')}
						value={value}
						onChange={newValue => this.onChangeField(index, 'value', newValue)}
						errorMessage={errorMessage}
					/>
				);
			case 'Dropdown':
				return (
					<Dropdown
						styles={{dropdown: {width: 208}}}
						placeholder={placeholder}
						onChange={(e, newValue) => this.onChangeField(index, 'value', newValue)}
						selectedKey={value}
						options={(options && options.map(key => ({key, text: key}))) || []}
						errorMessage={errorMessage}
					/>
				);
			default:
				return (
					<TextField
						style={{width: 206}}
						placeholder={renderPlaceHolder('Value')}
						onChange={(e, newValue) => this.onChangeField(index, 'value', newValue)}
						value={value}
						errorMessage={errorMessage}
					/>
				);
		}
	}

	onRemove() {
		const {onChange, touched, values} = this.props;
		const {dialog: {index}} = this.state;
		const newValues = {...values};
		const newTouched = {...touched};

		delete newValues[index];
		delete newTouched[index];

		const newValuesUpdateIndex = Object.keys(newValues)
			.reduce((obj, key, i) => ({...obj, [i + 1]: newValues[key]}), {});
		const newTouchedUpdateIndex = Object.keys(newTouched)
			.reduce((obj, key, i) => ({...obj, [i + 1]: newTouched[key]}), {});

		this.setState({
			dialog: null
		});

		onChange('fields', newValuesUpdateIndex, newTouchedUpdateIndex);
	}

	onOpenDialog(index) {
		const {values} = this.props;

		if (values[index].id !== null) {
			this.setState({
				dialog: {
					isOpen: true,
					text: `Are you shure you want to remove field number ${index}?`,
					title: 'Delete field',
					index
				}
			});
		}
	}

	onCloseDialog() {
		this.setState({dialog: null});
	}

	onChangeField(index, key, newValue) {
		const {onChange, touched, values} = this.props;
		const newValues = {...values};
		const newTouched = {...touched};

		newValues[index][key] = newValue.key || newValue;

		if (newTouched[index] === undefined) {
			newTouched[index] = {};
		}

		newTouched[index][key] = true;

		if (key === 'id') {
			newValues[index].value = '';
			newValues[index].fieldOperationId = '';

			newTouched[index] = {id: true};
		}

		onChange('fields', newValues, newTouched);
	}

	onAdd() {
		const {onChange, touched, values} = this.props;
		const newValues = {...values};
		const newTouched = {...touched};

		Object.keys(values).forEach(index => {
			newTouched[index] = {
				id: true,
				fieldOperationId: true,
				value: true
			};
		});

		newValues[Object.keys(values).length + 1] = {
			id: '',
			fieldOperationId: '',
			value: ''
		};

		onChange('fields', newValues, newTouched);
	}
}

FieldsRules.propTypes = {
	touched: PropTypes.object,
	errors: PropTypes.object,
	values: PropTypes.object,
	options: PropTypes.array,
	onChange: PropTypes.func.isRequired
};

export default withRouter(FieldsRules);
